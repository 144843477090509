import React, { useState, useRef, useEffect, useContext, Fragment } from 'react'
import { useHistory, Link, useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import Tooltip from 'rc-tooltip'
import MainContext from '@/context/MainContext'
import Upload from '@/components/Upload'
import Toast from '@/components/Toast'
import Loading from '@/components/Loading'
import ThreeModel from '@/components/ThreeModel'
import Modal from '@/components/Modal'

import searchApi from '@/api/searchApi'
import accountApi from '@/api/account'
import catalogApi from '@/api/catalogApi'
import vinApi from '@/api/vinApi'
import { getUrlParams, cdnsPath } from '@/utils/utils'
import css from './main.module.scss'
import logoImg from '@/assets/images/logo.png'
import threeGif from '@/assets/images/three_icon.gif'

interface SelectIn {
  type: string, 
  value: string,
  brand?: string,
  code?: string,
  img?: string,
  name: string,
  show_name?: string
}

let refused = false
let update = 1

const Search: React.FC<{update?: Function}> = (props) => {
  const intl = useIntl()
  const { pathname } = useLocation()
  const { store, setStore } = useContext(MainContext)
  const { crumb, userInfo, watchTotal } = store
  const { vin, pid, brand, model, year, code, displacements, mcid, brandcode } = getUrlParams()
  const history = useHistory()
  const [ searchData, setSearchData ] = useState<any>([])

  const [ vinHistory, setVinHistory ] = useState<any>([])
  const [ historyVisible, sethistoryVisible ] = useState<boolean>(false)

  const [ data, setData ] = useState<any>({})
  const [ tips, setTips ] = useState<string>('')
  const [ searchKey, setSearchKey ] = useState<string>('')
  const searchRef = useRef<HTMLInputElement>(null)

  const [ ocrVisible, setOcrVisible ] = useState<boolean>(false)
  const [ uploadLoading, setUploadLoading ] = useState<boolean>(false)
  const [ ocrImg, setOcrImg ] = useState<string>('')
  const [ ocrKey, setOcrKey ] = useState<string>('')

  const [ catalogLoading, setCatalogLoading ] = useState<boolean>(false)
  
  const [ catalogTitle, setCatalogTitle ] = useState<any>({
    path: '',
    title: []
  })
  const [ catalogData, setCatalogData ] = useState<any>([])
  const [ tipsVisible, setTipsVisible ] = useState<boolean>(localStorage.getItem('threeTips') ? false : true)

  const [ tmVisible, setTmVisible ] = useState<boolean>(false)

  const [ loading, setLoading ] = useState<boolean>(false)
  const [ watchLoading, setWatchLoading ] = useState<boolean>(false)
  const [ watchVisible, setWatchVisible ] = useState<boolean>(false)
  const [ watch, setWatch ] = useState<any>([])

  const [ selectConfigVisible, setSelectConfigVisible ] = useState<boolean>(false)
  const [ selectConfigData, setSelectConfigData ] = useState<any>({})
  
  useEffect(()=>{
    getbrand()
    if(pathname.indexOf('/partsprice') !== -1){
      getFavorite(false)
    }
    console.info(crumb)
  },[])
  useEffect(()=>{
    if(pid){
      setSearchKey(pid)
    }else {
      setSearchKey('')
    }
    if(pathname.indexOf('/vin') === -1 && pathname.indexOf('/group') === -1 && pathname.indexOf('/search') === -1){
      setStore({value: [], type: 'crumb'})
    }
    // if(vin){
    //   setSearchKey(vin)
    // }
    // if(brand && model && year && code && displacements){
    //   setSearchData([
    //     {
    //       type: 'brand',
    //       value: brand
    //     },
    //     {
    //       type: 'model',
    //       value: model
    //     },
    //     {
    //       type: 'year',
    //       value: year
    //     },
    //     {
    //       type: 'code',
    //       value: code
    //     },
    //     {
    //       type: 'displacements',
    //       value: displacements
    //     }
    //   ])
    // }else {
    //   setSearchData([])
    // }
  }, [pathname])

  useEffect(()=>{
    // setTips('')
    // if(searchData.length && searchData.length < 5){
    //   cars('')
    // }
    if(searchData.length && searchData[0].type !== 'logo'){
      cars()
    }
  },[searchData])

  useEffect(()=>{
    setTips('')
  },[searchKey])

  useEffect(()=>{
    if(!selectConfigVisible && selectConfigData.key){
      if(!crumb.length){
        search(selectConfigData.key)
      }else {
        const url = `/search/detail${history.location.search}&keyword=${selectConfigData.key}`
        const param = {
          name: selectConfigData.key,
          type: 'keyword'
        }
        if(crumb[crumb.length - 1].type === 'keyword'){
          crumb[crumb.length - 1] = param
        }else {
          crumb.push(param)
        }
        window.sessionStorage.setItem('crumb', JSON.stringify(crumb))
        setSearchKey('')
        if(searchRef.current){
          searchRef.current.blur()
        }
  
        history.push(url)
        if(props.update){
          props.update()
        }
      }
    }
  }, [selectConfigVisible])


  const beforeUpload = (file: File, FileList: File[]) => {
    return new Promise((resolve, reject) => {
      resolve(file)
    })
  }
  const start = (result: any) => {
    setUploadLoading(true)
  }
  const success = (result: any, file: File) => {
    if(result.code === 1){
      const reader: any = new FileReader()
      reader.readAsDataURL(file)
      reader.addEventListener('load', () => {
        setOcrImg(reader.result)
      })
      setOcrKey(result.data)
    }
    setUploadLoading(false)
  }
  const error = (result: any) => {
    Toast('error', 'warning')
    setUploadLoading(false)
  }

  // const setSelect = (index?: number | null, item?: SelectIn) => {
  //   let param = JSON.parse(JSON.stringify(searchData))

  //   console.info(param)
  //   if(index !== null && typeof index === 'number'){
      
  //     if(param[index].type === 'displacements' ){
  //       param.splice(index - 1, 2)
  //     }else {
  //       param.splice(index, 1)
  //     }
      
  //   }else if(item) {
  //     if(item.brand){
  //       param = [
  //         {
  //           type: 'brand',
  //           value: item.brand
  //         }
  //       ]
  //     }
  //     if(item.code){
  //       param.push({
  //         type: 'code',
  //         value: item.code
  //       })
  //     }
  //     param.push(item)
  //   }
  //   setSearchData(param)
  //   setData({})
  //   setSearchKey('')
    
  //   if(param.length > 4){
  //     let url: string[] = []
  //     param.forEach((item: {type: string, value: string})=>{
  //       url.push(item.type + '=' + item.value)
  //     })
  //     history.push(`/car?${url.join('&')}`)
  //     if(props.update){
  //       props.update()
  //     }
  //   }else if(searchRef.current){
  //     searchRef.current.focus()
  //   }
  // }
  const cars = () => {
    setSearchKey('')
    if(refused){
      return
    }
    let activeItem = searchData[searchData.length - 1]
    let param: any = {
      brandCode: searchData[0].brandcode
    }
    if(activeItem.has_child || searchData.length === 1){
      if(searchData.length === 1){
        param.level = 0
      }else {
        param.level = activeItem.level
        param.name = activeItem.name
        param.full_condition_path = activeItem.full_condition_path
      }
      setCatalogLoading(true)
      catalogApi.models(param).then((result: any)=>{
        setCatalogLoading(false)
        if(result.code === 1){
          let temp = { ...catalogTitle }
          let tempcl = [...catalogData]

          temp.title.push(result.title)
          tempcl.push(result.data)

          setCatalogData(tempcl)
          setCatalogTitle(temp)
        }
      })
    }else {
      let tempCrumb: any = [...searchData]
      tempCrumb.unshift({
        type: 'logo',
        img: catalogTitle.path + tempCrumb[0].img
      })
      delete tempCrumb[1].type
      setStore({value: tempCrumb, type: 'crumb'})
      window.sessionStorage.setItem('crumb', JSON.stringify(tempCrumb))
      history.push(`/group?mcid=${activeItem.mcid.replace(/\=/g, '%3D')}&brandcode=${activeItem.brandCode}&num=0`)
      setSelect(0)
      if(props.update){
        props.update()
      }
    }
    // setData({})
    // if(val || (searchData.length && searchData.length < 4)){
    //   let param: any = {
    //     kw: val
    //   }
    //   searchData.forEach((item: SelectIn)=>{
    //     param[item.type] = item.value
    //   })
    //   searchApi.cars(param).then((result: {code: number, data: object})=>{
    //     if(result.code === 1 && result.data){
    //       setData(result.data)
    //     }else {
    //       setData({})
    //     }
    //   })
    // }
  }
  const search = (vin?: string, validation?: boolean) => {
    let key = vin || searchKey
    let url
    setOcrVisible(false)
    setOcrImg('')
    setOcrKey('')
    if(key){
      if(!crumb.length){
        // if(/^[0-9a-zA-Z]*$/g.test(key) && key){
          // if(key.length === 17){
          //   url = `/vin?vin=${key}`
          //   setSearchKey('')
          // }else {
          //   url = `/partsprice?pid=${key}`
          //   setSearchKey(key)
          // }
        // }
        if(validation){
          if(key.length > 17){
            setLoading(true)
            vinApi.findVin({vin: key}).then((result: any)=>{
              setLoading(false)
              if(result.code === 1){
                // setSelectConfigData({
                //   config: result,
                //   key
                // })
                // setSelectConfigVisible(true)
                reset()
                setSearchKey('')
                url = `/vin?vin=${result.data[0].contentData[0]}`
                history.push(url)
                if(props.update){
                  props.update()
                }
              }else {
                update = update + 1
                process(key, update)
              }
            })
          }else {
            update = update + 1
            process(key, update)
          }
          return
        }else {
          if(key.length === 17){
            url = `/vin?vin=${key}`
            setSearchKey('')
          }else {
            url = `/partsprice?pid=${key}`
            setSearchKey(key)
          }
          if(searchRef.current){
            searchRef.current.blur()
          }
          history.push(url)
          if(props.update){
            props.update()
          }
        }
      }else {
        const fn = () => {
          url = `/search/detail${history.location.search}&keyword=${key}`
          const param = {
            name: key,
            type: 'keyword'
          }
          console.log(crumb)
          if(crumb[crumb.length - 1].type === 'keyword'){
            crumb[crumb.length - 1] = param
          }else {
            crumb.push(param)
          }
          window.sessionStorage.setItem('crumb', JSON.stringify(crumb))
          setSearchKey('')
          
          if(searchRef.current){
            searchRef.current.blur()
          }
          history.push(url)
          if(props.update){
            props.update()
          }
          return
        }
        if(key.length > 16){
          setLoading(true)
          vinApi.findVin({vin: key}).then((result: any)=>{
            setLoading(false)
            if(result.code === 1){
              // setSelectConfigData({
              //   config: result,
              //   key
              // })
              // setSelectConfigVisible(true)
              reset()
              setSearchKey('')
              url = `/vin?vin=${result.data[0].contentData[0]}`
              history.push(url)
              if(props.update){
                props.update()
              }
            }else {
              fn()
            }
          })
        }else {
          fn()
        }
      }
    }
    // if(url){
    //   if(searchRef.current){
    //     searchRef.current.blur()
    //   }

    //   history.push(url)

      
    // }
    // if(props.update && !validation){
    //   props.update()
    // }
    // if(!searchKey){
    //   if(!searchData.length){
    //     setTips('Please input OE/Catalog')
    //   }else if(searchData.length > 4) {
    //     let url: string[] = []
    //     searchData.forEach((item: {type: string, value: string})=>{
    //       url.push(item.type + '=' + item.value)
    //     })
    //     history.push(`/car?${url.join('&')}`)
    //     if(props.update){
    //       props.update()
    //     }
    //   }else {
    //     const tip = ['brand', 'model', 'year', 'displacements']
    //     let ti = ''
    //     tip.forEach((t)=>{
    //       let is = true
    //       searchData.forEach((item: {type: string})=>{
    //         if(item.type === t && !ti){
    //           is = false
    //         }
    //       })
    //       if(is && !ti){
    //         ti = t
    //       }
    //     })
    //     setTips(ti)
    //     for(let i in searchData){
    //       let item = searchData[i]
    //       if(item.type !== 'code' && tip.indexOf(item.type) === -1){
    //         setTips('Please input' + item.type)
    //       }
    //     }
    //   }
    // }else {
    //   let key = searchKey.replace(/\s/g, '')
    //   let url
    //   if(/^[0-9a-zA-Z]*$/g.test(key) && key){
    //     if(key.length === 17){
    //       url = `/vin?vin=${key}`
    //     }else {
    //       url = `/partsprice?pid=${key}`
    //     }
    //   }
    //   if(url){
    //     setSearchKey(key)
    //     history.push(url)
    //     if(props.update){
    //       props.update()
    //     }
    //   }
    // }
  }
  // const delSelectData = (e: any) => {
  //   if(e.keyCode === 13){
  //     search()
  //   }
  //   if(e.keyCode === 8 && !e.target.value && searchData.length)(
  //     setSelect(searchData.length - 1)
  //   )
  // }
  const getHistory = () => {

    accountApi.history({brandCode: 'all'}).then((result: any)=>{
      if(result.code === 1){
        setVinHistory(result.data)
      }
    })

  }
  const showHisory = () => {
    if(!crumb.length){
      getHistory()
      sethistoryVisible(true)
    }
  }
  const reSelected = (item: any, index: number) => {
    let url = ''
    //catalog

    if(vin){
      if(item.type === 'group'){
        url = `/vin?vin=${vin}&samecrumb=true`
      }
      if(item.type === 'subGroup'){
        url = `/vin?vin=${vin}&num=${item.num}&samecrumb=true`
      }
      if(item.type === 'detail'){
        if(item.index || item.index === 0){
          url = `/vin?vin=${vin}&num=${item.num}&index=${item.index}&samecrumb=true`
        }else {
          url = `/vin?vin=${vin}&num=${item.num}&samecrumb=true`
        }
      }
      if(item.type === 'keyword'){
        url = `/vin`
        if(crumb[index - 1] && crumb[index - 1].type === 'detail'){
          url += '/detail'
        }
        url += history.location.search.replace(/&keyword=.*/, '&samecrumb=true')
      }
    }else {
      if(item.type === 'group'){
        url = `/catalog?brandcode=${brandcode}`
        // reset()
        // return
      }
      if(item.type === 'subGroup'){
        url = `/group?mcid=${mcid.replace(/\=/g, '%3D')}&brandcode=${brandcode}&num=${item.num}&samecrumb=true`
      }
      if(item.type === 'detail'){
        if(item.index || item.index === 0){
          url = `/group?mcid=${mcid.replace(/\=/g, '%3D')}&brandcode=${brandcode}&num=${item.num}&index=${item.index}&samecrumb=true`
        }else {
          url = `/group?mcid=${mcid.replace(/\=/g, '%3D')}&brandcode=${brandcode}&num=${item.num}&samecrumb=true`
        }
      }
      if(item.type === 'keyword'){
        url = `/group`
        if(crumb[index - 1] && crumb[index - 1].type === 'detail'){
          url += '/detail'
        }
        url += history.location.search.replace(/&keyword=.*/, '&samecrumb=true')
      }
    }

    let groupCrumb = window.sessionStorage.getItem('groupCrumb') || '[]'
    window.sessionStorage.setItem('crumb', groupCrumb)

    crumb.splice(index, crumb.length - index)
    setStore({value: crumb, type: 'crumb'})
    history.push(url)
    if(props.update){
      props.update()
    }
  }
  const reset = () => {
    let url = ''
    //catalog
    if(vin){
      url = `/vin`
    }else {
      url = `/catalog`
    }
    setStore({value: [], type: 'crumb'})
    window.sessionStorage.removeItem('groupCrumb')
    window.sessionStorage.removeItem('crumb')
    history.push(url)
    if(selectConfigVisible){
      setSelectConfigVisible(false)
    }
  }
  // const inputWidth = () => {
  //   let width = '50%'
  //   let i = 0
  //   crumb.forEach((item: any)=>{
  //     if(item.type !== 'config' && item.type !== 'logo'){
  //       i++
  //     }
  //   })
  //   if(i > 1){
  //     width = '75%'
  //   }
  //   if(i > 3){
  //     width = '100%'
  //   }
  //   return width
  // }
  const configText = (
    <div className="pad">
      {
        crumb.map((item: any, index: number)=>{
          return (
            <Fragment key={index}>
              { item.type === 'config' || index === 1 ? <span>{item.show_name || item.name} {crumb[index+1]?.type === 'config' ? <>&gt;</> : ''} </span> : null }
            </Fragment>
          )
        })
      }
    </div>
  )

  const getbrand = () => {
    catalogApi.brands().then((result: any)=>{
      if(result.code === 1){
        setCatalogTitle({
          path: result.IMG_DNS,
          title: ['Brand']
        })
        setCatalogData([result.data])
      }
    })
  }
  const setSelect = (index?: number | null, item?: SelectIn) => {
    let param = JSON.parse(JSON.stringify(searchData))
    let temp = { ...catalogTitle }
    let tempcl = [...catalogData]
    if(index !== null && typeof index === 'number'){
      const i = index + 1
      refused = true
      param.splice(index, param.length - index)
      temp.title.splice(i, temp.title.length - i)
      tempcl.splice(i, tempcl.length - i)
      setCatalogData(tempcl)
      setCatalogTitle(temp)
      
    }else if(item) {
      refused = false
      item.type = 'config'
      param.push(item)
    }
    setSearchData(param)
    // setData({})
    
    if(searchRef.current){
      searchRef.current.focus()
    }

    // if(param.length > 4){
    //   let url: string[] = []
    //   param.forEach((item: {type: string, value: string})=>{
    //     url.push(item.type + '=' + item.value)
    //   })
    //   history.push(`/car?${url.join('&')}`)
    // }
  }
  const hideTipsVisible = () => {
    setTipsVisible(false)
    window.localStorage.setItem('threeTips', 'true')
  }
  const process = (vin: string, updateOld?: number, count?: number) => {
    if(updateOld !== update){
      return
    }
    let param: any = {
      search_key: vin
    }
    setLoading(true)
    
    searchApi.process(param).then((result: {code: number, data: {}, msg?: string})=>{
      const urlParamsTemp = JSON.stringify(getUrlParams()) || null
      if(updateOld !== update){
        return
      }
      if(!result.data && result.code !== 4001){
        setTips(result.msg || '')
        setLoading(false)
        return
      }
      if(result.code === 1 || result.code === 4005) {
        setLoading(false)
        let url = vin.length === 17 ? `/vin?vin=${vin}` : `/partsprice?pid=${vin}`
        if(vin.length === 17){
          setSearchKey('')
        }
        history.push(url)
        // if(props.update && !pathname.includes('partsprice')){
        //   props.update()
        // }
        if(props.update){
          props.update()
        }
      }else if(result.code !== 4001) {
        setTips(result.msg || '')
        setLoading(false)
      }
      
      if(result.code === 4001) {
        if(count){
          if(count < 3){
            setTimeout(()=>{
              if(updateOld !== update){
                return
              }
              process(vin, updateOld, count + 1)
            }, 3000)
          }else {
            // setProgressVisible(false)
            setLoading(false)
            setTips(result.msg || '')
            // Toast(result.msg, 'warning')
          }
        }else {
          setTimeout(()=>{
            process(vin, update, 1)
          }, 3000)
        }
        
      }
    })
  }

  const getFavorite = (val: boolean) => {
    setWatchVisible(val)
    setWatchLoading(true)
    accountApi.getFavorite().then((res: { code : number, data: any[] }) => {
      setWatchLoading(false)
      if(res.code === 1) {
        setWatch(res.data)
        setStore({value: res.data.length, type: 'watchTotal'})
      }
    })
  }
  
  return (
    <>
      <div className={css['search']}>
        {/* <Link to='/'><img src={logoImg} alt="logo" className={css['logo']} /></Link> */}
        <div className={css['box']}>
          
          <div className={css['input']}>
            {
              // searchData.map((item: SelectIn, index: number)=>{
              //   return (
              //     item.type !== 'code' ? (
              //       <span className={css['selected']} key={index}>{item.value}</span>
              //     ): null
              //   )
              // })
            }
            {
              // crumb.map((item: any, index: number)=>{
              //   return (
              //     item.type !== 'logo' && item.type !== 'config' ? (
              //       <Fragment key={index}>
              //         {
              //           crumb[0].type === 'logo' && index === 1 ? (
              //             <span className={css['selected']} style={{paddingLeft: 0}} onClick={()=>reset()}>
              //               <Tooltip mouseLeaveDelay={0} placement="bottom" overlay={configText} overlayStyle={{width: 222}} overlayClassName="white" >
              //                 <span className={css['img']}>
              //                   <img src={crumb[0].img} /> 
              //                   {/* {item.show_name || item.name}  */}
              //                   {item.show_name? item.show_name.substring(0, 6) : item.name.substring(0, 6)}
              //                   {item.name?.length > 6 ? '...' : ''}
              //                   <i className="iconfont iconclose-circle-fill" />
              //                 </span>
              //               </Tooltip>
              //             </span>
              //           ): (
              //             <span className={css['selected']} onClick={()=>reSelected(item, index)}>
              //               <Tooltip mouseLeaveDelay={0} placement="bottom" overlay={(<div className="pad">{item.show_name || item.name}</div>)} overlayStyle={{width: 222}} overlayClassName="white" >
              //                 <span>
              //                   {item.show_name? item.show_name.substring(0, 6) : item.name.substring(0, 6)}
              //                   {item.name?.length > 6 ? '...' : ''}
              //                   <i className="iconfont iconclose-circle-fill" />
              //                 </span>
                              
              //               </Tooltip>
              //             </span>
              //           )
              //         }
              //       </Fragment>
              //     ): null
              //   )
              // })
            }
            {/* {
              crumb.map((item: any, index: number)=>{
                return (
                  item.type === 'keyword' ? (
                    <Fragment key={index}>
                      <span className={css['selected']} onClick={()=>reSelected(item, index)}>
                        <span>
                          {item.show_name || item.name} 
                        </span>
                      </span>
                    </Fragment>
                  ): null
                )
              })
            } */}
            {
              crumb.map((item: any, index: number)=>{
                let lastIndex: number = crumb.length - 1
                return (
                  (index === 1 && !item.brandcode) || (item.type === 'keyword' && index === lastIndex) ? (
                    <Fragment key={index}>
                      <span className={css['selected']} >
                        <span>
                          {item.show_name || item.name} 
                        </span>
                        <i className="iconfont iconclose-circle" onClick={()=>reSelected(item, index)} />
                      </span>
                    </Fragment>
                  ): null
                )
              })
            }
            <div className={css['in']}>
              {
                tips ? (
                  <div className={css['tips']}>
                    <span className={css['down']}></span>
                    {tips}
                  </div>
                ): null
              }
              <input ref={searchRef} onBlur={()=>setTimeout(()=>{sethistoryVisible(false)}, 300)} onFocus={()=>showHisory()} placeholder={crumb.length ? intl.formatMessage({id: 'inputPNMR'}) : intl.formatMessage({id: 'inputVinBrand'})} value={searchKey} onChange={(e)=>setSearchKey(e.target.value)} 
                onKeyDown={
                  (e)=>{
                    if(e.keyCode === 13) {
                      search('', true)
                    }else if(e.keyCode === 8) {
                      if(!searchKey && searchRef.current && searchRef.current === document.activeElement) {
                        let keywordIndex = crumb.findIndex((it: any) => it.type === 'keyword')
                        if(keywordIndex !== -1) {
                          reSelected(crumb[keywordIndex], keywordIndex)
                        }else if(crumb[1]) {
                          reSelected(crumb[1], 1)
                        }
                      }
                    }
                  }
                } 
              />
              
              {
                loading ? <span style={{width: 60, height: 20, position: 'absolute', right: vin ? 76 : 36}}><Loading visible={true} type='mini' /></span> : null
              }
              <i className="iconfont iconshibie" onClick={()=>setOcrVisible(!ocrVisible)} />
            </div>
          </div>
          
          <button onClick={()=>search('', true)}>
            <i className="iconfont iconsousuo" />
          </button>

          {
            vin ? (
              <Tooltip visible={tipsVisible} overlayClassName="red" mouseLeaveDelay={0} placement="bottom" overlay={(<div className='tips'><p>{intl.formatMessage({id: 'view3D'})}</p><button onClick={()=>hideTipsVisible()}>Understood</button></div>)} overlayStyle={{width: 170}} >
                {/* <i className="iconfont icona-3D" style={{right: 50, fontSize: 24, top: -2}} onClick={()=>setTmVisible(true)}></i> */}
                <img src={threeGif} style={{marginLeft: 20, width: 40, cursor: 'pointer'}} onClick={()=>setTmVisible(true)} />
              </Tooltip>
            ) : null
          }

          {
            vinHistory.length && historyVisible && !searchKey && !searchData.length ? (
              <div className={css.vinHistory}>
                {
                  vinHistory.map((item: any, index: number)=>{
                    return (
                      <div key={index} className={css.item} onClick={()=>search(item.vin || item.pid)}>
                        <span>{item.vin || item.pid}</span>
                        <span>{item.brand_name}</span>
                      </div>
                    )
                  })
                }
                <div className={css.item} onClick={()=>history.push(`/my/history`)}>
                  <span style={{color: '#1890FF'}}>{intl.formatMessage({id: 'history'})} &gt;</span>
                </div>
              </div>
            ): null
          }
          {
            (searchKey || searchData.length) && userInfo.userid && !crumb.length && (( !searchKey || catalogData[searchData.length]?.filter((item: SelectIn) => item.name.toUpperCase().indexOf(searchKey.toUpperCase()) !== -1).length) || searchData.length) ? (
              <div className={css['search-content']}>
                <div className={css['h']}>
                  {
                    searchData.length && searchData[0].img ? <img src={catalogTitle.path + searchData[0].img} /> : <h6>Catalog</h6>
                  }
                  <ul>
                    {
                      searchData.map((item: SelectIn, index: number)=>{
                        return (
                          <Fragment key={index}>
                            {
                              item.name.length > 36 ? (
                                <Tooltip mouseLeaveDelay={0} placement="top" overlay={item.name} overlayStyle={{width: 222}} >
                                  <li className={css['selected']} onClick={()=>setSelect(index)}>{item.show_name? item.show_name.substring(0, 36) : item.name.substring(0, 36)}...</li>
                                </Tooltip>
                              ): (
                                <li onClick={()=>setSelect(index)}>{item.show_name || item.name}</li>
                              )
                            }
                            {searchData.length !== index + 1 ? <li>&gt;</li> : null}
                          </Fragment>
                        )
                      })
                    }
                  </ul>
                  <span className={css['cancel']} onClick={()=>setSelect(0)}><span className="iconfont icondanchuangguanbi"></span></span>
                </div>
                <Loading visible={catalogLoading}>
                  <div className={css['b']}>
                    <div className={css['group']}>
                      
                        <h5>{catalogTitle.title[searchData.length]}</h5>
                        <div className={css['item']}>

                          {
                            catalogData[searchData.length]?.map((item: SelectIn, index: number)=>{
                              return (
                                !searchKey || item.name.toUpperCase().indexOf(searchKey.toUpperCase()) !== -1 ? (
                                  <span key={index} onClick={()=>setSelect(null, item)} className={searchData.length ? css['special'] : ''}>{item.show_name || item.name}</span>
                                ): null
                              )
                            })
                          }
                        </div>
                      
                    </div>
                  </div>
                </Loading>
              </div>
            ): null
          }
          {
            ocrVisible && !historyVisible ? (
              <div className={css.ocr}>
                {
                  ocrImg ? (
                    <Loading visible={uploadLoading}>
                        <Upload
                          parse={vinApi.ocr}
                          setLoading={setUploadLoading}
                          action={vinApi.ocrPath}
                          name="img"
                          beforeUpload={beforeUpload}
                          start={start}
                          success={success}
                          error={error}
                        >
                          <div className={css.ocrImg}>
                            <img src={ocrImg} />
                          </div>
                        </Upload>
                        <div className={css.uploaded}>
                          <input type="text" value={ocrKey} onChange={(e)=>setOcrKey(e.target.value)} />
                          <button onClick={()=>search(ocrKey, true)}>{intl.formatMessage({id: 'search'})}</button>
                          <Upload
                            action={vinApi.ocrPath}
                            name="img"
                            beforeUpload={beforeUpload}
                            start={start}
                            success={success}
                            error={error}
                          >
                            <p>{intl.formatMessage({id: 'again'})}</p>
                          </Upload>
                        </div>
                    </Loading>
                  ): (
                    <Upload
                      parse={vinApi.ocr}
                      setLoading={setUploadLoading}
                      loading={uploadLoading}
                      action={vinApi.ocrPath}
                      name="img"
                      beforeUpload={beforeUpload}
                      start={start}
                      success={success}
                      error={error}
                    >
                      <div className={css.upload}>
                        <span className="iconfont icontop"></span>
                        <p>{intl.formatMessage({id: 'dragFile'})}</p>
                        <button>{intl.formatMessage({id: 'selectImage'})}</button>
                      </div>
                    </Upload>
                  )
                }
              </div>
            ): null
          }
          {/* {
            JSON.stringify(data) !== '{}' && (searchData.length || searchKey) ? (
              <div className={css['search-content']} style={{height: Object.keys(data).length === 1 ? '180px' : '360px'}}>
                {
                  Object.keys(data).map((key: string)=>{
                    return (
                      data[key] && data[key].length ? (
                        <div key={key} className={css['group']} style={{height: Object.keys(data).length === 1 ? '100%' : '50%'}}>
                          <h5>{key}</h5>
                          <div className={css['item']}>
                            {
                              data[key].map((item: SelectIn, index: number)=>{
                                return (
                                  <span key={index} onClick={()=>setSelect(null, item)}>{item.value}</span>
                                )
                              })
                            }
                          </div>
                        </div>
                      ): null
                    )
                  })
                }
              </div>
            ): null
          } */}
        </div>
      </div>
      {
        crumb?.length ? (
          <div className={css.crumbNew}>
            {
              crumb[0].type === 'logo' ? <img src={crumb[0].img} />: null
            }
            {
              crumb.map((item: any, index: number)=>{
                return (
                  item.type !== 'logo' ? (
                    <Fragment key={index}>
                      {
                        item.type === 'config' || index === 1 ? (
                          <>{item.show_name || item.name}</>
                        ): (
                          <span onClick={()=>reSelected(item, index)}>
                            {item.show_name || item.name} 
                          </span>
                        )
                      }
                      {/* { 
                        item.type === 'config' || index === 1 ? (
                          <span>{item.show_name || item.name} </span>
                        ): null 
                      } */}
                      {
                        index !== crumb.length - 1 ? <i>&gt;</i> : null
                      }
                    </Fragment>
                  ): null
                )
              })
            }
          </div>
        ): null
      }
      
      {
        pathname.indexOf('/partsprice') !== -1 ? <button className={css.watchlist} onClick={()=>getFavorite(true)}>{intl.formatMessage({id: 'watch'})}({watchTotal})</button> : null
      }
      

      {
        vin && tmVisible ?  <ThreeModel vin={vin} setTmVisible={setTmVisible} /> : null
      }
      
      <Modal
        title={intl.formatMessage({id: 'watch'})}
        visible={watchVisible}
        cancel={()=>setWatchVisible(false)}
        width={900}
      >
        <div className={css.watch}>
          <table className={css['table-header']}>
            <thead>
              <tr>
                <th style={{width: "12%"}}>{intl.formatMessage({id: 'pictures'})}</th>
                <th style={{width: "20%"}}>{intl.formatMessage({id: 'partsNumber'})}</th>
                <th style={{width: "30%"}}>{intl.formatMessage({id: 'partsName'})}</th>
                <th style={{width: "20%"}}>{intl.formatMessage({id: 'priceRange'})}</th>
                <th style={{width: "18%"}}>{intl.formatMessage({id: 'operation'})}</th>
              </tr>
            </thead>
          </table>
          <div className={css.scroll}>  
            <Loading visible={watchLoading}>
              <table>
                <tbody>
                  {
                    watch.map((item: any, index: number)=>{
                      return (
                        <tr key={index}>
                          <td style={{width: "12%"}}>{item.img ? <img src={item.img} alt="" /> : null}</td>
                          <td style={{width: "20%"}}>{item.pid}</td>
                          <td style={{width: "30%"}}>{item.name}</td>
                          <td style={{width: "20%"}}>{item.old_price}</td>
                          <td style={{width: "18%"}}><button onClick={()=>{search(item.pid); setWatchVisible(false)}}><i className='iconfont iconeye' /></button></td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </Loading>
          </div>
          
        </div>
      </Modal>

      <Modal
        title={selectConfigData.config?.desc || intl.formatMessage({id: 'selectConfigurationInformation'})}
        visible={selectConfigVisible}
        cancel={setSelectConfigVisible}
      >
        <div className={css['select-config']}>
          <div className={css['select-brand']}>
            <span>{selectConfigData.config?.res_desc}</span>
            <img src={cdnsPath + selectConfigData.config?.img} />
          </div>
          <table>
            <thead>
              <tr>
                {
                  selectConfigData.config?.title && selectConfigData.config.title.map((item: string)=>{
                    return (
                      <th>{item}</th>
                    )
                  })
                }
              </tr>
            </thead>
            <tbody>
              {
                selectConfigData.config?.data && selectConfigData.config.data.map((item: any)=>{
                  return (
                    <tr onClick={()=>reset()}>
                      {
                        item.contentData.map((content: string)=>{
                          return (
                            <td>{content}</td>
                          )
                        })
                      }
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  )
}

export default Search;