import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { VinDetail } from '@/page/Vin'

import Loading from '@/components/Loading'
import { getUrlParams } from '@/utils/utils'
import vinApi from '@/api/vinApi'
import css from './vin.module.scss'

const nav = [
  {
    name: 'Part number',
    icon: 'iconfont iconoe',
    id: 'number'
  },
  {
    name: 'Part name',
    icon: 'iconfont iconmingcheng',
    id: 'name'
  }
]

const PartSearchPage: React.FC = () => {
  const intl = useIntl()
  const { vin, mcid, brandcode, keyword, vinmcid, vincode, onlyType } = getUrlParams()

  const [ partVisible, setPartVisible ] = useState<boolean>(true)
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ active, setActive ] = useState<any>({})
  const [ data, setData ] = useState<any>([])
  const [ type, setType ] = useState<string>('number')
  
  useEffect(()=>{
    if(keyword){
      // partSearch('number', true)
      if(/\d/.test(keyword)) {
        setType('number')
        partSearch('number', true)
      }else {
        setType('name')
        partSearch('name', true)
      }
    }
  }, [])
  const partSearch = (apiType: string, typeName?: boolean) => {
    let api: any = {}
    const param = {
      mcid: mcid || vinmcid,
      vin: vin || '',
      brandCode: brandcode || vincode,
      search_key: keyword,
      search_type: 'pid'
    }
    if(apiType === 'number'){
      api = vinApi.partSearch(param)
    }else {
      api = vinApi.partNameSearch(param)
    }
    setLoading(true)
    api.then((result: {code: number, data: object[]})=>{
      setLoading(false)
      if(result.code === 1){
        setData(result.data)
        if(result.data.length === 1 && onlyType === 'partsprice'){
          showList(result.data[0])
        }else {}
        // if(!result.data.length && typeName){
        //   partSearch('name')
        // }
        if(result.data.length && apiType === 'name'){
          setType('name')
        }
      }else {
        setData([])
      }
      
    })
  }
  const showList = (item: object) => {
    setActive(item)
    setPartVisible(false)
  }
  const backFn = () => {
    setPartVisible(true)
  }
  const changeType = (val: string) => {
    setType(val)
    partSearch(val)
  }
  return (
    <>
      {
        partVisible ? (
          <div className={css['part-search']}>
            <ul className={css['nav']}>
              {
                nav.map((item, index)=>{
                  return <li key={index} onClick={()=>changeType(item.id)} className={item.id === type ? css['active'] : ''}><span><i className={item.icon} />{item.name}</span></li>
                })
              }
            </ul>
            <Loading visible={loading}>
              <>
                <h6>{intl.formatMessage({id: 'findResults'}, {num: data.length})}</h6>
                <div className={css['content']}>
                  {
                    data.map((item: any, index: number)=>{
                      return (
                        <div className={css['item']} key={index} onClick={()=>showList(item)}>
                          <div className={css['l']}>
                            <img src={item.url} alt={item.pid} />
                          </div>
                          <div className={css['r']}>
                            <h5 dangerouslySetInnerHTML={{__html: item.label.replace(new RegExp(keyword,'gi'),"<em>$&</em>")}}></h5>
                            <p>
                              <label>{intl.formatMessage({id: 'partOe'})}</label>
                              <span dangerouslySetInnerHTML={{__html: item.pid.replace(new RegExp(keyword,'gi'),"<em>$&</em>")}}></span>
                            </p>
                            <p>
                              <label>{intl.formatMessage({id: 'mainGroup'})}</label>
                              {item.maingroupname}
                            </p>
                            <p>
                              <label>{intl.formatMessage({id: 'subGroup'})}</label>
                              {item.mid} {item.subgroupname}
                            </p>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </>
            </Loading>
          </div>
        ): (
          <div className={css['part-search']}>
            <VinDetail
              type='partSearch'
              vin={vin}
              brandcode={active.brandCode}
              mcid={active.mcid}
              num={active.num}
              mid={active.mid}
              subgroup={active.subgroup}
              pid={active.pid}
              backFn={backFn}
            />
          </div>
        )
      }
    </>
  )
}

export default PartSearchPage