import React, { useEffect, useState, useContext, useRef } from 'react'
import { useIntl } from 'react-intl'
import MainContext from '@/context/MainContext'
import Menu from './Mymenu'
import styles from './my.module.scss'
import acountApi from '@/api/account'
import Button from '@/components/Button'
import Input from '@/components/Input'
import Spin from '@/components/Spin'

import Tooltip from 'rc-tooltip'

import paypalPng from '@/assets/images/paypal.png'

import logopro from '@/assets/images/logopro.png'
import logovin from '@/assets/images/logovin.png'


const tips = (
  <div>
    <h6>Coupon Code Source:</h6>
    <p>1. Person Center-Coupon code.</p>
    <p>2. Obtain from Autobond staff or key user.</p>
  </div>
)

const tabs = [
  {
    title: 'Unlimited Search',
    img: logopro,
    id: 1
  },
  {
    title: 'Unlimited Search',
    img: logovin,
    id: 2
  }
]

const NewPurchase: React.FC<any> = (props) => {
  const { type } = props
  const intl = useIntl()
  const { store, setStore } = useContext(MainContext)
  const { userInfo } = store

  const [list, setList] = useState<any>([])
  const [vinList, setVinList] = useState<any[]>([])
  const [crumb, setCrumb] = useState<string[]>([])
  
  const [activePg, setActivePg] = useState<any>({})
  const [vinInfo, setVinInfo] = useState<{l: number, r: number}>({l: 0, r: 0})
  const [coupon_num, setCouponNum] = useState<string>("")
  const [codeInfo, setCodeInfo] = useState<string>("")
  const [codeMoney, setCodeMoney] = useState<number>(-1)
  const [tab, setTab] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [buyLoading, setBuyLoading] = useState<boolean>(false)
  const [canFree, setCanFree] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const buycode = window.sessionStorage.getItem('code')

  useEffect(() => {
    getUserType()
    getCommodity()
    getBuymore()
    hasFree()
    getCommodityVin()
    if(buycode || userInfo.data?.code){
      checkCode()
    }
  },[])

  useEffect(() => {
    if(codeMoney !== -1) {
      setCodeMoney(-1)
      setCodeInfo("")
      setCouponNum("")
    }
    if(tab === 2) {
      setCrumb([])
      if(activePg?.id === 101) {
        setVinInfo({l: 0, r: 1})
      }else if(activePg?.id === 102) {
        setVinInfo({l: 0, r: 5})
      }
    }
  },[activePg])

  useEffect(() => {
    if(!coupon_num) {
      setCodeInfo("")
    }
  },[coupon_num])

  useEffect(() => {
    if(tab === 2) {
      setActivePg(vinList[0])
    }
    if(tab === 1 && list[1]) {
      setActivePg(list[1][0])
    }
  },[tab])

  useEffect(() => {
    if(activePg?.id === 101) {
      setVinInfo({l: crumb.length, r: 1 - crumb.length})
    }else if(activePg?.id === 102) {
      setVinInfo({l: crumb.length, r: 5 - crumb.length})
    }
  },[crumb])

  function getUserType(): any {
    setLoading(true)
    acountApi.getUserInfo().then((result: {code: number, data: any}) => {
      setLoading(false)
      if(result.code === 1) {
        let user_type = result.data.data.user_type
        setTab(user_type === 'pro' ? 1 : user_type === 'vin' ? 2 : 0)
      }
    })
  }

  const getCommodity = () => {
    // setLoading(true)
    acountApi.getCommodity().then((res: { code: number, data: any[] }) => {
      // setLoading(false)
      if(res.code === 1) {
        setList(res.data)
        setActivePg(res.data[1][0])
      }
    })
  }
  const getCommodityVin = () => {
    acountApi.getCommodityVin().then((res: { code : number, data: any[] }) => {
      if(res.code === 1) {
        let result = res.data[2].reverse()
        setVinList(result)
        if(tab === 2) {
          setActivePg(result[0])
        }
      }
    })
  }
  const checkCode = () => {
    acountApi.checkCode({commodity_id: activePg.id,coupon_num: coupon_num || buycode || userInfo.data?.code}).then((res: any) => {
      if(res.code === 1) {
        if(res.data.status){
          setCodeMoney(res.data.final_amount)
          
          if(!coupon_num && (buycode || userInfo.data?.code)){
            setCouponNum(buycode || userInfo.data?.code)
          }
        }else {
          setCodeMoney(-1)
        }
        setCodeInfo(res.data.title)
      }else {
        setCodeMoney(-1)
        setCodeInfo(intl.formatMessage({id: 'invalidCoupon'}))
      }
    })
  }
  const buy = () => {
    let param = {
      type: activePg.id + '' || '',
      payType: 'paypal',
      coupon_num: codeMoney !== -1 ? coupon_num : '',
      vins: tab === 2 ? crumb.join(",") : ''
    }
    setBuyLoading(true)
    acountApi.usePayPal(param).then((res: { code : number, data: any }) => {
      setBuyLoading(false)
      if(res.code === 1) {
        window.location.href = res.data.pay_url
      }
    })
  }
  const getBuymore = () => {
    acountApi.buymore().then((result: any)=>{
      if(result.code === 1 && result.data){
        setStore({value: true, type: 'purchaseVisible'})
      }
    })
  }
  const hasFree = () => {
    acountApi.hasFree().then((res: any) => {
      if(res.code === 1) {
        setCanFree(res.data)
      }
    })
  }
  const setInput = (val: string) => {
    let value = val.replace(/\s*/g,"").toLocaleUpperCase()
    if(inputRef.current) {
      inputRef.current.value = value
    }
    if(value.length === 17) {
      crumb.push(value)
      setTimeout(() => {
        if(inputRef.current) {
          inputRef.current.value = ''
          setCrumb([...crumb])
        }
      },100)
    }
  }
  const delc = (index: number) => {
    crumb.splice(index, 1)
    setCrumb([...crumb])
  }
  const inputFocus = () => {
    if(inputRef.current) {
      inputRef.current.focus()
    }
  }
  const commpontDom = (
    <div className={tab === 2 ? styles['new-purchase-red'] : styles['new-purchase']}>
      <Spin loading={loading}></Spin>
      <div className={styles['tabs']}>
        {
          tabs.map((item, index) => {
            return <span onClick={() => setTab(item.id)} className={tab === item.id ? styles['active'] : ''} key={item.id}><img src={item.img} /> {item.title}</span>
          })
        }
      </div>

      {
        tab === 2 && (
          <div className={styles['vin-con']}>
            <div className={styles['vin-list']}>
              <div className={styles['vin-list-l']}>
                <span>&nbsp;</span>
                <span>{intl.formatMessage({id: 'bindVin'}, {num: 1})}</span>
                <span>{intl.formatMessage({id: 'bindVin'}, {num: 5})}</span>
              </div>
              <div className={styles['vin-list-r']}>
                <span>{intl.formatMessage({id: 'annualSubscribe'})}</span>
                {
                  vinList.map((item, index) => {
                    return <span onClick={() => setActivePg(item)} className={styles['vin-check'] + " " + (activePg?.id === item.id ? styles['active'] : '')} key={item.id}>{intl.formatMessage({id: 'currencySymbol'})}{item.amount}</span>
                  })
                }
              </div>
            </div>
            <div className={styles['input']} onClick={() => inputFocus()}>
              {
                crumb.map((item, index) => {
                  return <span key={index}>{item} <i onClick={() => delc(index)} className="iconfont iconclose-circle-fill" /></span>
                })
              }
              {
                activePg?.id === 101 && !crumb.length || activePg?.id === 102 && crumb.length < 5 ? <input  placeholder={intl.formatMessage({id: 'enterNumber'})} ref={inputRef} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInput(e.target.value)} /> : null
              }
            </div>
            <h4>{intl.formatMessage({id: 'boundRemaining'}, {hasBound: vinInfo.l, remainingBound: activePg?.id === 101 ? '1' : '5', has: vinInfo.r, remaining: activePg?.id === 101 ? '1' : '5'})}</h4>
            <p><span></span>&nbsp;{intl.formatMessage({id: 'boundDescribe1'})}</p>
            <p><span></span>&nbsp;{intl.formatMessage({id: 'boundDescribe2'})}</p>
            <p><span></span>&nbsp;{intl.formatMessage({id: 'boundDescribe3'})}</p>
            


            {/* <h4>{vinInfo.l} have been bound, {vinInfo.r} can be bound, or you can bind later.</h4>
            <p><span></span>&nbsp;It can be purchased repeatedly, and the validity period is one year immediately after purchase.</p>
            <p><span></span>&nbsp;No modification can be made after binding the frame numbe.</p>
            <p><span></span>&nbsp;If the corresponding number is not bound after purchase, you can add the binding in the personal center.</p> */}
          </div>
        )
      }

      {
        tab === 1 && (
          <>
            <div className={styles['table-list']}>
              {/* <Spin loading={loading}></Spin> */}
              <div className={styles['col']}>
                <span>&nbsp;</span>
                <span>{intl.formatMessage({id: 'allInOne'})}</span>
                {/* <span>{intl.formatMessage({id: 'asiaPack'})}</span> */}
                <span>{intl.formatMessage({id: 'CN PACK'})}</span>
                <span>{intl.formatMessage({id: 'euroPack'})}</span>
                <span>{intl.formatMessage({id: 'usPack'})}</span>
                <span>{intl.formatMessage({id: 'JPN-KOR PACK'})}</span>
              </div>
              {/* <div  className={styles['col']}>
                <span>FREE TRAIL</span>
                {canFree ? <span onClick={() => setActivePg({id:100,amount: 0})} className={`${styles['hasfree']} + ${activePg?.id === 100 ? styles['active'] : ''}`}>$0</span> : <span className={styles['nofree']}>$0</span>}
              </div> */}
              <div className={`${styles['col']}`}>
                <span>{intl.formatMessage({id: 'daySubscription'})}</span>
                {
                  list.length ? (
                    <>
                      {
                        list[0].map((item: any, index: number) => {
                          return (
                            index ? (
                              <Tooltip mouseLeaveDelay={0} placement="top" overlay={item.show_msg.split('/').join('、')} overlayStyle={{width: 222}}>
                                <span onClick={() => setActivePg(item)} className={`${styles['item']} ${activePg?.id === item.id ? styles['active'] : ''}`} key={index}>
                                  <span>{intl.formatMessage({id: 'currencySymbol'})}{item.amount}</span>
                                  { item.save ? <span className={styles['save']}>{item.save}</span> : '' }
                                </span>
                              </Tooltip>
                            ): (
                              <span onClick={() => setActivePg(item)} className={`${styles['item']} ${activePg?.id === item.id ? styles['active'] : ''}`} key={index}>
                                <span>{intl.formatMessage({id: 'currencySymbol'})}{item.amount}</span>
                                { item.save ? <span className={styles['save']}>{item.save}</span> : '' }
                              </span>
                            )
                            
                          )
                        })
                      }
                    </>
                  ): null
                }
                {/* {
                  list.length ? <span onClick={() => setActivePg(list[0][0])} className={`${styles['day']} + ${activePg?.id === list[0][0].id ? styles['active'] : ''}`}>${list[0][0].amount}</span> : <span>&nbsp;</span>
                } */}
              </div>
              <div className={styles['col']}>
                <span>{intl.formatMessage({id: 'monthSubscription'})}</span>
                {
                  list.length ? (
                    <>
                      {
                        list[1].map((item: any, index: number) => {
                          return (
                            index ? (
                              <Tooltip mouseLeaveDelay={0} placement="top" overlay={item.show_msg.split('/').join('、')} overlayStyle={{width: 222}}>
                                <span onClick={() => setActivePg(item)} className={`${styles['item']} ${activePg?.id === item.id ? styles['active'] : ''}`} key={index}>
                                  <span>{intl.formatMessage({id: 'currencySymbol'})}{item.amount}</span>
                                  { item.save ? <span className={styles['save']}>{item.save}</span> : '' }
                                </span>
                              </Tooltip>
                            ): (
                              <span onClick={() => setActivePg(item)} className={`${styles['item']} ${activePg?.id === item.id ? styles['active'] : ''}`} key={index}>
                                <span>{intl.formatMessage({id: 'currencySymbol'})}{item.amount}</span>
                                { item.save ? <span className={styles['save']}>{item.save}</span> : '' }
                              </span>
                            )
                            
                          )
                        })
                      }
                    </>
                  ): null
                }
              </div>
              <div className={styles['col']}>
                <span>{intl.formatMessage({id: 'annualSubscription'})}</span>
                {
                  list.length ? (
                    <>
                      {
                        list[2].map((item: any, index: number) => {
                          return (
                            index ? (
                              <Tooltip mouseLeaveDelay={0} placement="top" overlay={item.show_msg.split('/').join('、')} overlayStyle={{width: 222}}>
                                <span onClick={() => setActivePg(item)} className={`${styles['item']} ${activePg?.id === item.id ? styles['active'] : ''}`} key={index}>
                                  <span>{intl.formatMessage({id: 'currencySymbol'})}{item.amount}</span>
                                  { item.save ? <span className={styles['save']}>{item.save}</span> : '' }
                                </span>
                              </Tooltip>
                            ): (
                              <span onClick={() => setActivePg(item)} className={`${styles['item']} ${activePg?.id === item.id ? styles['active'] : ''}`} key={index}>
                                <span>{intl.formatMessage({id: 'currencySymbol'})}{item.amount}</span>
                                { item.save ? <span className={styles['save']}>{item.save}</span> : '' }
                              </span>
                            )
                          )
                        })
                      }
                    </>
                  ): null
                }
              </div>
            </div>
          </>
        )
      }
      
      <div className={styles['code']}>
        <div className={styles['code-main']}>
          <Input disabled={!activePg?.amount} type="text" size="large" value={coupon_num} onChange={(val: any) => setCouponNum(val)} placeholder="Enter discount code"  />
          <Button  type="primary" size="large" onClick={() => checkCode()} disabled={!(coupon_num && coupon_num.length >= 6) }>{intl.formatMessage({id: 'use'})}</Button>
        </div>
        <div className={styles['code-info']}>
          <span className={styles['code-res']}>{''}</span>
          <Tooltip mouseLeaveDelay={0} placement="top" overlayStyle={{ zIndex: 999999 }} overlay={tips}>
            <span className={styles['how']}>
              {intl.formatMessage({id: 'howGetCode'})}?
            </span>
          </Tooltip>
        </div>
      </div>
      <p>
        {codeInfo || ''}
      </p>
      <div className={styles['pay']}>
        <div className={styles['pay-l']}>
          <p>
            {/* <label htmlFor="">Payment method: </label> */}
            <img src={paypalPng} alt="" />
          </p>
          <p>
            {/* <label htmlFor="">Amounts payable: </label> */}
            <span style={{color:'#FF5502'}}>${codeMoney !== -1 ? codeMoney : activePg?.amount}</span>
          </p>
        </div>
        <div className={styles['pay-r']} onClick={() => buy()}>
          <div className={styles.paybtn}>
            <Button type="primary" loading={buyLoading}>{intl.formatMessage({id: 'payPalWith'})}</Button>
            <span className={styles.payspan}></span>
            <div className={styles.payimg}><img src={paypalPng} alt="" /></div>
            
          </div>
        </div>
      </div>
    </div>
  )
  if(type) {
    return commpontDom
  }
  return (
    <Menu
      tab={2}
    >
      {commpontDom}
    </Menu>
  )
}

export default NewPurchase