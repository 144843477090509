const id = {
  locale: 'id-ID',
  messages: {
    "inputVinBrand": "Silakan masukkan VIN atau Nomor Bagian atau Tanda",
    "history": "Catatan sejarah",
    "home": "Rumah",
    "catalog": "Katalog",
    "search": "Cari",
    "dragFile": "Tampal atau seret berkas ke daerah ini untuk pengakuan",
    "selectImage": "Pilih Gambar",
    "howWorks": "Lihat bagaimana ini bekerja",
    "more": "Lebih",
    "helpSection": "Bagian Bantuan",
    "freeTrialDay": "Percobaan gratis 3 hari",
    "account": "Akaun",
    "purchase": "Pembelian",
    "message": "Pesan",
    "favorites": "Kegemaran",
    "feedbackMessage": "Pesan balas",
    "watch": "Daftar Perhatian",
    "coupon": "Coupon",
    "signIn": "Daftar masuk",
    "signOut": "Tandatangan keluar",
    "register": "Daftar",
    "subscribe": "Tanggal",
    "freeSearch": "Pencarian bebas tersedia",
    "inviteTips": "Klik tombol Invite 10 friends to get a free year pass",
    "invite": "Undang",
    "freeTrialTips": "Oh tidak! Sepertinya kau kehabisan pencarian gratis! Untuk terus menggunakan Partsbond, mulai percobaan bebas Anda",
    "freeTrial": "Percobaan bebas",
    "helpVideos": "Bantu Video",
    "copyright": "Copyright@2020 AutoBond Inc.All Rights Reserved",
    "api": "API",
    "app": "Aplikasi",
    "about": "Tentang Kami",
    "contact": "Hubungi Kami",
    "and": "dan",
    "privacy": "Privasi & Terma",
    "feedback": "Beritahu kami apa yang Anda pikirkan",
    "writeOpinion": "Tulis pendapat Anda ...",
    "sentUsEmail": "dikirim kepada kami : aaronw@autobondinc.com",
    "sentUs": "Dikirim kepada kami",
    "iosDownload": "Download IOS",
    "androidDownload": "Download Android",
    "inputPNMR": "Silakan masukkan Pid/Name/Model/Remark",
    "view3D": "Klik untuk Melihat Model 3D",
    "again": "Lagi",
    "pictures": "Gambar",
    "partsNumber": "Nomor Bagian",
    "partsName": "Nama Bagian",
    "priceRange": "Jarak Harga",
    "operation": "Operasi",
    "email": "Email",
    "vCode": "Kode verifikasi",
    "nPassword": "Kata sandi baru",
    "cNPassword": "Konfirmasi kata sandi baru",
    "submit": "Kirim",
    "getCode": "Dapatkan kode",
    "countdown": "{num}s - lagi",
    "inputEmail": "Silakan masukkan email Anda",
    "emailCorrectly": "Silakan isi email dengan benar",
    "inputPassword": "Silakan masukkan sandi anda",
    "passwordsInconsistent": "Dua kata sandi yang anda masukkan tidak konsisten",
    "sendCodeSuccess": "Kirim kode dengan sukses",
    "agreeUserAgreement": "Silakan setuju Perjanjian Pengguna Partsbond",
    "tryFree": "Coba partsbond gratis",
    "tryFreeDay": "Setelah mendaftar, klik percobaan bebas untuk menambah percobaan 7 hari lagi",
    "registrationCodeSentSuccess": "Kode daftar dikirim dengan sukses! Jika Anda tidak menerima kode dengan 1-2 menit, silakan coba menyegarkan kotak masuk Anda dan memeriksa folder spam Anda.",
    "findUs": "Bagaimana kau menemukan kami",
    "iAgree": "Aku setuju.",
    "userAgreement": "Perjanjian Pengguna Partsbond",
    "brand": "Tanda",
    "sampleSearch": "Carian Sampel",
    "passwordChangedSuccess": "Kata sandi berubah dengan sukses",
    "passwordChangedFailed": "Gagal mengubah sandi",
    "enterNewName": "Silakan masukkan nama pengguna baru",
    "userChangedSuccess": "Pengguna berubah dengan sukses",
    "userChangedFailed": "Gagal mengubah Pengguna",
    "fillName": "Silakan isi nama pengguna dulu",
    "vinBindingSuccee": "Binding Vin berhasil",
    "success": "berhasil",
    "brandPackage": "Paket Pertanyaan Tanda Otomatis",
    "combo": "Kombo",
    "validTime": "Waktu yang sah",
    "cancelRenewal": "Batalkan pembaruan",
    "packageContent": "Konten paket",
    "bindFrame": "Ikat 1 angka bingkai lagi",
    "remainingQueries": "Jumlah pertanyaan yang tersisa",
    "personalInformation": "Informasi pribadi",
    "loginAccount": "Akaun daftar masuk",
    "nickName": "Nama Nick",
    "modify": "Ubah",
    "bindingEmail": "Email mengikat",
    "loginPassword": "Kata sandi daftar masuk",
    "enterContent": "Masukkan isi",
    "noCoupons": "Tidak ada kupon",
    "discountOperation": "Operasi diskon",
    "preferentialContent": "Konten preferensi",
    "serviceLife": "Hidup layanan",
    "user": "Pengguna",
    "remarks": "Catatan",
    "noPartsCollection": "Tidak ada bagian dalam koleksi",
    "selectAll": "Pilih Semua",
    "delete": "Hapus",
    "vin": "VIN",
    "oe": "OE",
    "detail": "Perincian",
    "noNews": "Tidak ada berita",
    "feedbackDetails": "Rincian balas",
    "feedbackStatus": "Status balas",
    "feedbackTime": "Waktu balas balik",
    "questionType": "Jenis pertanyaan",
    "problemDescription": "Deskripsi Masalah",
    "solved": "selesai",
    "pending": "Menunggu",
    "supplementaryQuestion": "Pertanyaan tambahan",
    "endFeedback": "Balas akhir",
    "cancel": "Batalkan",
    "invalidCoupon": "Aku tidak menemukan kode diskon ini",
    "bindVin": "Ikat {num} VIN",
    "annualSubscribe": "SUBSkripsi ANNUAL",
    "currencySymbol": "$",
    "enterNumber": "Silakan masukkan nomor bingkai tunggal berturut-turut",
    "boundRemaining": "{hasBound}/{remainingBound} VIN BOUND [{has}/{remaining} VIN BINDS REMAINING]",
    "boundDescribe1": "Banyak subskripsi dapat diaplikasikan ke satu akun",
    "boundDescribe2": "Tanggalan baik untuk 1 tahun kalender (dari tanggal pembelian)",
    "boundDescribe3": "Mengikat VIN ke subskripsi adalah permanen; jika Anda ingin mengikat VIN Anda nanti, Anda dapat melakukannya dalam rincian rekening Anda",
    "allInOne": "ALL-IN-ONE",
    "asiaPack": "PAK ASIA",
    "euroPack": "EURO PACK",
    "usPack": "US PACK",
    "daySubscription": "DAY SUBSCRIPTION",
    "monthSubscription": "SUBSkripsi bulan",
    "annualSubscription": "SUBSkripsi ANNUAL",
    "use": "Gunakan",
    "howGetCode": "Bagaimana mendapatkan kode diskon",
    "payPalWith": "PayPal dengan",
    "openingPermission": "Membuka izin",
    "availablePackages": "Pakej Tersedia",
    "bestMatch": "Pertandingan Terbaik",
    "priceLowHigh": "harga rendah hingga tinggi",
    "priceHighLow": "harga tinggi hingga rendah",
    "pleaseLoginFirst": "Silakan masuk dulu",
    "here3DArea": "Ini adalah area 3D",
    "applicableVehicles": "Vehicle(s) Applicable",
    "applicableVehicle": "kendaraan yang dapat diterapkan",
    "interchangeableParts": "Bagian Berganti",
    "condition": "Kondisi",
    "all": "Semua",
    "channel": "Kanal",
    "location": "Lokasi",
    "partsFound": "Bagian Ditemukan",
    "noData": "TIDAK data",
    "carsModels": "Model Mobil",
    "year": "Tahun",
    "market": "Pasar",
    "oeNumber": "Nomor OE",
    "partName": "Nama Bagian",
    "count": "Kiraan",
    "partsPrice": "PartsPrice",
    "directReplacement": "Penggantian langsung",
    "indirectReplacement": "Penggantian langsung",
    "priceTrend": "Trend harga",
    "partPicture": "Gambar bagian",
    "brands": "Tanda",
    "replacement": "Ganti",
    "applicableVeh": "Veh Teraplikasi",
    "supplier": "Pembekal",
    "getIt": "Dapatkan itu",
    "go": "Pergi",
    "findResults": "Cari hasil berkaitan dengan {num} untuk Anda",
    "partOe": "Bagian OE",
    "mainGroup": "Kelompok utama",
    "subGroup": "Subgrup",
    "group": "Kelompok",
    "configDetails": "Rincian konfigurasi",
    "selectGroup": "Pilih kelompok",
    "conflg": "Conflg",
    "picture": "Gambar",
    "mg": "MG",
    "sg": "SG",
    "illNo": "ILL-NO",
    "name": "Nama",
    "remark": "Perhatian",
    "model": "Model",
    "selectConfigurationInformation": "Pilih informasi konfigurasi",
    "groupOne": "Kita dalam kelompok satu!",
    "lastGroup": "Ini kelompok terakhir!",
    "list": "Daftar",
    "back": "kembali",
    "switchGroup": "Tukar kelompok",
    "notFilter": "Bukan Penapis",
    "filter": "Penapis",
    "config": "Konfigurasi",
    "pos": "POS",
    "qty": "QTY",
    "addWatch": "tambah ke daftar pengawasan",
    "brandPart": "Bagian Tanda",
    "component": "Komponen",
    "disclaimer": "Abaikan: hasil pertanyaan hanya untuk referensi.",
    "partDetail": "Detail Bagian",
    "vinNotAvailable": "Nomor VIN tidak tersedia",
    "partNotAvailable": "Nomor bagian tidak tersedia",
    "lackModels": "Kekurangan model",
    "dataNotComplete": "Data belum selesai",
    "dataError": "Galat data",
    "dataNotNew": "Data tidak cukup baru",
    "slowSpeed": "Kecepatan lambat",
    "noUniqueness": "Tidak ada yang unik",
    "other": "Lain",
    "selectQuestion": "Pilih pertanyaan",
    "close": "Tutup",
    "howVin": "Bagaimana memeriksa nomor bingkai?",
    "howVin1": "Cari nomor bingkai dan catat nomor VIN di jendela depan kaca volum kendaraan",
    "howVin2": "Masukkan nomor bingkai atau klik untuk mengunggah gambar untuk mengidentifikasi nomor bingkai",
    "howVin3": "Klik ikon pertanyaan untuk bertanya nomor bingkai",
    "howVin4": "Masukkan nama tanda dan klik ikon pertanyaan untuk bertanya",
    "findParts": "Bagaimana menemukan bagian?",
    "findParts1": "Serahkan tetikus pada nomor parkir untuk melihat informasi konfigurasi dasar",
    "findParts2": "Klik ikon ini untuk menggunakan model 3D untuk menanyai bagian tubuh, bagian chassis dan bagian mesin",
    "findParts3": "Pilih kelompok utama dan kelompok untuk memasukkan daftar bagian",
    "findParts4": "Klik untuk memilih ulang grup utama dan pertanyaan nomor bingkai",
    "findParts5": "Klik pada nomor bagian dari paparan yang meledak untuk memilih bagian",
    "howPart": "Bagaimana menggunakan pertanyaan nomor bagian?",
    "howPart1": "Masukkan atau mengunggah gambar untuk mengidentifikasi nomor bagian, klik pencarian untuk bertanya",
    "howPart2": "Klik pada ikon untuk mengumpulkan bagian ke koleksi saya, dan Anda akan diberitahu melalui email ketika harga bagian berubah",
    "howPart3": "Klik pada ikon untuk melihat trend harga baru-baru ini bagian",
    "noHelp": "Tidak perlu bantuan",
    "understood": "Mengerti",
    "nextStep": "Langkah berikutnya",
    "clickCheckInvitation": "klik di sini untuk memeriksa status undangan",
    "inviteFriendsGetPackage": "Panggil 10 teman untuk mendapatkan SUBSCRIPSI ANNUAL, pada kami! Bernilai $540!",
    "emailAddress": "Alamat surel",
    "status": "status",
    "redeem": "menebus",
    "inviteRewardTips": "<p>Silakan konfirmasi untuk menerapkan hadiah Anda dari <br /><em>All-in-One annual subscription</em>.</ p><p>Jika Anda memiliki subskripsi yang valid saat ini, hadiah Anda akan disembunyikan secara otomatis setelah habis.</ p>",
    "currentSubscriptionExpires": "Subskripsi Anda saat ini habis",
    "confirm": "Konfirmasi",
    "putAway": "Jauhkan",
    "seeAll": "Lihat Semua",
    "pageCannotEmpty": "Jumlah halaman tidak dapat kosong",
    "pleaseRegisterSignin": "Silakan mendaftar/tandatangani masuk untuk mencari lebih banyak data kendaraan",
    "price": "Harga",
    "quantity": "Kuantitas",
    "pleaseRL": "Silakan mendaftar/daftar masuk",
    "sign": "tanda masuk",
    "stay": "Tetap mendaftar masuk",
    "forget": "Lupakan Sandi",
    "password": "Kata sandi",
    "RFFVS": "Daftar Pencarian VIN Bebas",
    "cName": "Nama Perusahaan",
    "basic": "Basis",
    "updateTime": "Waktu Kemaskini",
    'OfficialStore': 'Store resmi',
    "english": "English",
    "russian": "русск",
    'indonesian': 'Indonesia',
    'vietnamese': 'Tiếng Việt',
    'thai': 'คนไทย',
    'malaysia': 'Malaysia',
    'arabia': 'بالعربية',
    'spain': 'Espagne',
    'portugal': 'Portugal',
    'france': 'France',
    'Turkey': 'Türkçe',
    'korean': '한국어',
    'japanese': '日本語'
  }
}

export default id