import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import MainContext from '@/context/MainContext'
import { Breadcrumb } from '@/page/Main'
import Modal from '@/components/Modal'
import Guide from '@/components/Guide'
import Loading from '@/components/Loading'
import Toast from '@/components/Toast'
import { PartSearch } from '@/page/Vin'
import vinApi from '@/api/vinApi'
import accountApi from '@/api/account'
import { getUrlParams, cdnsPath } from '@/utils/utils'
import mainCss from '@/page/Main/main.module.scss'
import css from './vin.module.scss'


const Vin: React.FC = () => {
  const intl = useIntl()
  const guidecache = JSON.parse(localStorage.getItem('guidecache') || '{}')
  const urlParams = JSON.stringify(getUrlParams())
  const { store, setStore } = useContext(MainContext)
  const { crumb, userInfo } = store
  const history = useHistory()
  const { vin, num, index, mcid, brandcode, samecrumb, vinmcid } = getUrlParams()
  const [ data, setData ] = useState<any>({})
  const [ group, setGroup ] = useState<any>([])
  const [ structure, setStructure ] = useState<any>([])
  const [ subGroup, setSubGroup ] = useState<any>([])
  const [ title, setTitle ] = useState<string[]>([])
  const [ key, setKey ] = useState<string>('')
  const [ groupKey, setGroupKey ] = useState<string>('')

  const [ groupActive, setGroupActive ] = useState<number>(-1)
  const [ structureActive, setStructureActive ] = useState<number>(-1)
  const [ filter, setFilter ] = useState<boolean>(localStorage.getItem('filter') ? true : false)
  const [ structureShow, setStructureShow ] = useState<boolean>(localStorage.getItem('structureShow') ? true : false)
  const [ partSearchkey, setPartSearchkey ] = useState<number>(0)
  const [ partKey, setPartKey ] = useState<string>('')
  const [ groupTotal, setGroupTotal ] = useState<any>({structure: 0, sub: 0})
  const [ subGroupShow, setSubGroupShow ] = useState<any>({menu: 'main', content: false})
  
  const [ configVisible, setConfigVisible ] = useState<boolean>(false)
  const [ config, setConfig ] = useState<any>([])

  const [ selectConfig, setSelectConfig ] = useState<any>({})
  const [ selectConfigVisible, setSelectConfigVisible ] = useState<boolean>(false)

  const [ loading, setLoading ] = useState<boolean>(false)
  const [ progress, setProgress ] = useState<number>(0)
  const [ progressVisible, setProgressVisible ] = useState<boolean>(false)
  
  let activeIndex = 0

  useEffect(()=>{
    if(vin){
      getVin(true, urlParams, vin)
    }

    // catalog
    if(mcid && brandcode){
      getGroup({
        brandCode: brandcode,
        mcid: mcid
      })
    }
  }, [])

  useEffect(()=>{
    if(num && group.length){
      group.forEach((item: any, index: number)=>{
        if((num === '0' && !index) || item.num === num){
          if(!samecrumb){
            let tempCrumb = JSON.parse(window.sessionStorage.getItem('crumb') || '[]')
            item.name = item.label
            item.type = 'group'
            tempCrumb.push(item)
            setStore({value: tempCrumb, type: 'crumb'})
          }
          getStructure(index, true)
        }
      })
    }
  }, [group])

  useEffect(()=>{
    if(index && structure.length){
      getSubGroup(parseFloat(index))
    }
  }, [structure])

  useEffect(()=>{
    if(progress && vin){
      getVin(false, urlParams, vin)
    }
  }, [progress])

  const getVin = (loading: boolean, urlParamsOld: string, vin: string, brandcode?: string, mcid?: string) => {
    const urlParamsTemp = JSON.stringify(getUrlParams()) || null
    if(urlParamsOld !== urlParamsTemp){
      return
    }
    let param: any = {
      vin
    }
    if(brandcode){
      param.brandCode = brandcode
    }
    if(mcid){
      param.mcid = mcid
    }
    setSelectConfig({})
    setSelectConfigVisible(false)
    if(loading){
      setLoading(true)
    }
    
    vinApi.vin(param).then((result: {code: number, title: string[], brandCode: string, data: {mcid: string}, msg?: string})=>{
      const urlParamsTemp = JSON.stringify(getUrlParams()) || null
      if(urlParamsOld !== urlParamsTemp){
        return
      }
      if(result.code === 1 || result.code === 10) {
        let tempCrumb: any = [
          {
            type: 'logo',
            img: `${cdnsPath}/img/${result.brandCode}.png`
          },
          {
            name: vin
          }
        ]
        
        result.title?.map((name: string)=>{
          tempCrumb.push({
            name,
            type: 'config'
          })
        })
        window.sessionStorage.setItem('crumb', JSON.stringify(tempCrumb))
        if(!samecrumb){
          setStore({value: tempCrumb, type: 'crumb'})
        }
        setData(result)
        getGroup({
          vin,
          brandCode: result.brandCode,
          mcid: result.data.mcid
        })
        if(!vinmcid){
          const url = history.location.pathname + history.location.search + `&vinmcid=${result.data.mcid}&vincode=${result.brandCode}`
          history.push(url)
        }
      }else if(result.code !== 4001) {
        setLoading(false)
      }
      if(result.code === 4005) {
        setSelectConfig(result)
        setSelectConfigVisible(true)
      }
      
      if(result.code === 4001) {
        if(progress){
          if(progress < 3){
            setTimeout(()=>{
              setProgress(progress + 1)
            }, 3000)
          }else {
            // setProgressVisible(false)
            setLoading(false)
            setProgress(0)
            Toast(result.msg, 'warning')
          }
        }else {
          // setProgressVisible(true)
          setTimeout(()=>{
            setProgress(1)
          }, 3000)
        }
      }
      queryCount()
    })
  }
  const getGroup = (param: object) => {
    vinApi.group(param).then((result: {code: number, data: any})=>{
      setLoading(false)
      if(result.code === 1) {
        if(result.data[num || 0]?.click === 0){
          result.data.forEach((item: any)=>{
            if(item.click === 1){
              const url = history.location.pathname + history.location.search.replace(/&num=\d/, '&num=' + item.num)
              history.push(url)
            }
          })
        }
        setGroup(result.data)
      }
    })
  }
  const getStructure = (index: number, jump?: boolean) => {
    if(groupActive === index){
      return
    }
    if(group[index].click === 0){
      setStore({value: true, type: 'purchaseVisible'})
      return
    }
    activeIndex = index
    setGroupActive(index)
    setSubGroupShow({
      menu: 'main',
      content: false
    })
    setStructureActive(-1)
    const item = group[index]
    const param = {
      vin,
      mcid: item.mcid,
      brandCode: item.brandCode,
      num: item.num
    }
    setStructure([])
    vinApi.structure(param).then((result: {code: number, data: object[], imgs: object[]})=>{
      if(result.code === 1 && activeIndex === index) {
        let total = 0
        result.data.forEach((d: any)=>{
          d.img = d.structure_illustration ? result.imgs[d.structure_illustration] || '' : result.imgs[d.imageLarge] || ''
          
          // catalog
          if(mcid && brandcode){
            d.colorvalue = 1
          }

          if(d.colorvalue){
            total++
          }
        })
        groupTotal.structure = total
        setGroupTotal(groupTotal)
        setStructure(result.data)
      }else {
        setGroupTotal({...groupTotal, structure: 0})
      }
    })
    if(!jump){
      let url = `/vin?vin=${vin}&num=${item.num}`
      //catalog
      if(mcid && brandcode){
        url = `/group?mcid=${mcid.replace(/\=/g, '%3D')}&brandcode=${brandcode}&num=${item.num}`
      }
      
      item.name = item.label
      item.type = 'group'
      let tempCrumb = JSON.parse(window.sessionStorage.getItem('crumb') || '[]')
      tempCrumb.push(item)
      setStore({value: tempCrumb, type: 'crumb'})
      
      history.push(url)
    }else if(samecrumb){
      const url = history.location.pathname + history.location.search.replace('&samecrumb=true', '')
      history.push(url)
    }
  }
  const getSubGroup = (index: number) => {
    if(structureActive === index){
      return
    }
    if(structure[index].click === 0){
      setStore({value: true, type: 'purchaseVisible'})
      return
    }
    activeIndex = index
    setStructureActive(index)
    setSubGroupShow({
      menu: 'structure',
      content: true
    })
    const item = structure[index]
    const param = {
      vin,
      mcid: item.mcid,
      brandCode: item.brandCode,
      num: item.num,
      subgroup: item.subgroup,
      maingroup: item.maingroup,
      level: 1
    }
    setSubGroup([])
    vinApi.structure(param).then((result: {code: number, data: object[], imgs: object[]})=>{
      if(result.code === 1 && activeIndex === index) {
        let total = 0
        result.data.forEach((d: any)=>{
          d.img = d.structure_illustration ? result.imgs[d.structure_illustration] || '' : result.imgs[d.imageLarge] || ''
          // catalog
          if(mcid && brandcode){
            d.colorvalue = 1
          }
          if(d.colorvalue){
            total++
          }
        })
        groupTotal.sub = total
        setGroupTotal(groupTotal)
        setSubGroup(result.data)
      }else {
        setGroupTotal({...groupTotal, sub: 0})
      }
    })
    let url = `/vin?vin=${vin}&num=${group[groupActive].num}&index=${index}`
    //catalog
    if(mcid && brandcode){
      url = `/group?mcid=${mcid.replace(/\=/g, '%3D')}&brandcode=${brandcode}&num=${item.num}&index=${index}`
    }
    if(crumb[crumb.length - 1].level === 1){
      crumb.pop()
    }
    
    item.type = 'subGroup'
    crumb.push(item)
    setStore({value: crumb, type: 'crumb'})
    
    history.push(url)
  }
  const goDetail = (index: number, subIndex?: number) => {
    const item = structure[index]
    if(item.click === 0){
      setStore({value: true, type: 'purchaseVisible'})
      return
    }
    let param = `vin=${vin || ''}&brandcode=${item.brandCode}&mcid=${item.mcid}&num=${item.num}&index=${index}`
    sessionStorage.setItem('structure', JSON.stringify(structure))
    
    if(subIndex || subIndex === 0){
      const subItem = subGroup[subIndex]
      param = `vin=${vin || ''}&brandcode=${subItem.brandCode}&mcid=${subItem.mcid}&num=${subItem.num}&index=${index}&subindex=${subIndex}`
      sessionStorage.setItem('subGroup', JSON.stringify(subGroup))
      subItem.type = 'detail'
      subItem.index = index
      crumb.push(subItem)
      setStore({value: crumb, type: 'crumb'})
    }else {
      item.type = 'detail'
      crumb.push(item)
      setStore({value: crumb, type: 'crumb'})
      sessionStorage.removeItem('subGroup')
    }

    let url = `/vin/detail?${param}`
    //catalog
    if(mcid && brandcode){
      url = `/group/detail?${param}`
    }

    let tempCrumb = window.sessionStorage.getItem('crumb') || '[]'
    window.sessionStorage.setItem('groupCrumb', tempCrumb)
    window.sessionStorage.setItem('crumb', JSON.stringify(crumb))

    history.push(url)
  }
  const setFilterLS = () => {
    let val = filter ? false : true
    setFilter(val)
    if(val){
      localStorage.setItem('filter', '1')
    }else {
      localStorage.removeItem('filter')
    }
  }
  const setStructureShowLS = () => {
    let val = structureShow ? false : true
    setStructureShow(val)
    if(val){
      localStorage.setItem('structureShow', '1')
    }else {
      localStorage.removeItem('structureShow')
    }
  }
  const setSubGroupShowFn = (type: string) => {
    let temp = JSON.parse(JSON.stringify(subGroupShow))
    temp.menu = type
    setSubGroupShow(temp)
  }

  const searchConfig = (key_words: string) => {
    if(key_words){
      vinApi.config({vin, key_words}).then((result: {code: number, data: any})=>{
        if(result.code === 1){
          setConfig(result.data.confs)
        }else {
          setConfig([])
        }
      })
    }else {
      setConfig([])
    }
  }
  const queryCount = () => {
    accountApi.queryCount().then((result: any)=>{
      if(result.code === 1 && result.data?.freecount) {
        setStore({value: result.data.freecount, type: 'freecount'})
      }
    })
  }
  return (
    <>
      {/* <div className={mainCss['main-box']} >
        <Breadcrumb />
      </div> */}
      {
        vin || (mcid && brandcode) ? (
          <Loading visible={loading}>
            <div className={css['vin']}>
              <div className={css['l']}>
                <div className={css['box']}>
                  <div className={css['box-top']} onClick={()=>setSubGroupShowFn('main')}>
                    <h2>{intl.formatMessage({id: 'mainGroup'})}({group.length})</h2>
                    <div className={css['box-top-tools']}>
                      <span>
                        {/* <input value={key} type="text" placeholder="Maingroup Search" onChange={(e)=>setKey(e.target.value)} /> */}
                      </span>
                    </div>
                  </div>
                  {
                    subGroupShow.menu === 'main' ? (
                      <div className={subGroupShow.content ? css['box-content-h'] : css['box-content']}>
                        <ul className={css['groupList']}>
                          {
                            group.map((item: {label: string, img: string, num: string, click?: number}, index: number) => {
                              return (
                                !key || item.label.indexOf(key) !== -1 || item.num.indexOf(key) !== -1 ? (
                                  // <li key={index} className={groupActive === index ? css['active'] +' '+ css['small'] : css['small']} onClick={()=>getStructure(index)}>
                                  //   <img src={item.img} />
                                  //   <p>{item.num} {item.label}</p>
                                  //   {
                                  //     item.click === 0 ? <div></div> : null
                                  //   }
                                  // </li>
                                  <li key={index} className={groupActive === index ? css['active'] +' '+ css['small'] : css['small']} onClick={()=>getStructure(index)}>
                                    <span>{item.num}</span>
                                    <p>{item.label}</p>
                                    <img src={item.img} />
                                    {
                                      item.click === 0 ? <div></div> : null
                                    }
                                  </li>
                                ): null
                              )
                            })
                          }
                        </ul>
                      </div>
                    ): null
                  }
                  {
                    subGroupShow.content ? (
                      <>
                        <div className={css['box-top']} onClick={()=>setSubGroupShowFn('structure')}>
                          <h2>{intl.formatMessage({id: 'group'})}({structure.length})</h2>
                          <div className={css['box-top-tools']}>
                            <span>
                              {/* <input value={groupKey} type="text" placeholder="Group Search" onChange={(e)=>setGroupKey(e.target.value)} /> */}
                              {/* <i className="iconfont iconsousuo" /> */}
                            </span>
                          </div>
                        </div>
                        {
                          subGroupShow.menu === 'structure' ? (
                            <div className={subGroupShow.content ? css['box-content-h'] : css['box-content']}>
                              <ul className={css['groupList']}>
                                {
                                  structure.map((item: {name: string, img: string, mid: string, click?: number}, index: number) => {
                                    return (
                                      !groupKey || item.name.indexOf(groupKey) !== -1 || item.mid.indexOf(groupKey) !== -1 ? (
                                        // <li key={index} className={structureActive === index ? css['active'] : ''} onClick={()=>getSubGroup(index)}>
                                        //   <img src={item.img} />
                                        //   <p>{item.mid} {item.name}</p>
                                        //   {
                                        //     item.click === 0 ? <div></div> : null
                                        //   }
                                        // </li>
                                        <li key={index} className={structureActive === index ? css['active'] : ''} onClick={()=>getSubGroup(index)}>
                                          <span>{item.mid}</span>
                                          <p>{item.name}</p>
                                          <img src={item.img} />
                                          {
                                            item.click === 0 ? <div></div> : null
                                          }
                                        </li>
                                      ): null
                                    )
                                  })
                                }
                              </ul>
                            </div>
                          ): null
                        }
                      </>
                    ): null
                  }
                </div>
              </div>

              {
                groupActive === -1 && !index ? (
                  <div className={css['r']}>
                    <div className={css['box']}>
                      <div className={css['box-top']}>
                        <h2>{intl.formatMessage({id: 'configDetails'})}</h2>
                        <div className={css['box-top-tools']}>

                          {/* <span>
                            <input type="text" placeholder="OE Number" onChange={(e)=>setPartKey(e.target.value)} />
                            <i className="iconfont iconsousuo" onClick={()=>setPartSearchkey(partSearchkey + 1)} />
                          </span> */}
                        </div>
                      </div>
                      <div className={css['box-content']}>
                        <div className={css['detail']}>
                          {
                            data.data && data.data.config_list && data.data.config_list.map((item: {title: string, value: string}, index: number)=>{
                              return (
                                <p key={index}>
                                  <label>{item.title}：</label>
                                  <span>{item.value}</span>
                                </p>
                              )
                            })
                          }
                          
                        </div>
                      </div>
                    </div>
                    {
                      data.data && data.data.full_imglogo ? (
                        <img className={css['model-img']} alt="icon" src={data.data.full_imglogo} />
                      ): null
                    }
                  </div>
                ): (
                  <div className={css['r']}>
                    <div className={css['box']}>
                      <div className={css['box-top']}>
                        {
                          subGroupShow.content ? (
                            <h2>{intl.formatMessage({id: 'selectGroup'})}({ filter ? subGroup.length : groupTotal.sub })</h2>
                          ): (
                            <h2>{intl.formatMessage({id: 'selectGroup'})}({ filter ? structure.length : groupTotal.structure })</h2>
                          )
                        }
                        
                        <div className={css['box-top-tools']}>
                          {
                            vin ? (
                              <>
                                <button className={css['other']} onClick={()=>setConfigVisible(true)}><i className="iconfont iconpeizhi" style={{color: '#1890FF'}} />{intl.formatMessage({id: 'conflg'})}</button>
                                <button className={filter ? css['other'] + ' ' + css['active'] : css['other']} onClick={()=>setFilterLS()}><i className="iconfont iconyiguolv" style={{color: '#616BF1'}} />{filter ? 'Not Filter' : 'Filter'}</button>
                              </>
                            ): null
                          }
                          
                          <button className={css['other']} onClick={()=>setStructureShowLS()}><i className={structureShow ? "iconfont iconqiehuantupian" : "iconfont iconliebiao1"} style={{color: '#EBBC00'}} />{structureShow ? 'image' : 'List'}</button>
                          {/* <span>
                            <input type="text" placeholder="OE Number" onChange={(e)=>setPartKey(e.target.value)} />
                            <i className="iconfont iconsousuo" onClick={()=>setPartSearchkey(partSearchkey + 1)} />
                          </span> */}
                        </div>
                      </div>
                      <div className={css['box-content']}>
                        <div className={css['detail']}>
                          {
                            structureShow ? (
                              <>
                                <table className={css['table-header']}>
                                  <thead>
                                    <tr>
                                      <th style={{width: '10%'}}>{intl.formatMessage({id: 'picture'})}</th>
                                      <th style={{width: '5%'}}>{intl.formatMessage({id: 'mg'})}</th>
                                      <th style={{width: '5%'}}>{intl.formatMessage({id: 'sg'})}</th>
                                      <th style={{width: '10%'}}>{intl.formatMessage({id: 'illNo'})}</th>
                                      <th style={{width: '15%'}}>{intl.formatMessage({id: 'name'})}</th>
                                      <th style={{width: '15%'}}>{intl.formatMessage({id: 'remark'})}</th>
                                      <th style={{width: '40%'}}>{intl.formatMessage({id: 'model'})}</th>
                                    </tr>
                                  </thead>
                                </table>
                                <div className={css['list']}>
                                  <table>
                                    <tbody>
                                    {
                                      subGroupShow.content ? (
                                        subGroup.map((item: any, index: number)=>{
                                          return (
                                            filter || item.colorvalue ? (
                                              <tr key={index} className={item.colorvalue ? (item.click === 0 ? css.grey : '') : (item.click === 0 ? (css.grey + ' ' + css['filter']) : css['filter'])} onClick={()=>goDetail(structureActive, index)}>
                                                <td style={{width: '10%'}}><img src={item.img} /></td>
                                                <td style={{width: '5%'}}>{item.maingroup}</td>
                                                <td style={{width: '5%'}}>{item.subgroup}</td>
                                                <td style={{width: '10%'}}>{item.mid}</td>
                                                <td style={{width: '15%'}}>{item.name}</td>
                                                <td style={{width: '15%'}}>{item.description}</td>
                                                <td style={{width: '40%'}}>{item.model}</td>
                                              </tr>
                                            ): null
                                          )
                                        })
                                      ): (
                                        structure.map((item: any, index: number)=>{
                                          return (
                                            filter || item.colorvalue ? (
                                              <tr key={index} className={item.colorvalue ? (item.click === 0 ? css.grey : '') : (item.click === 0 ? css.grey : css['filter'])} onClick={()=>{if(item.has_child){getSubGroup(index)}else {goDetail(index)}}}>
                                                <td style={{width: '10%'}}><img src={item.img} /></td>
                                                <td style={{width: '5%'}}>{item.maingroup}</td>
                                                <td style={{width: '5%'}}>{item.subgroup}</td>
                                                <td style={{width: '10%'}}>{item.mid}</td>
                                                <td style={{width: '15%'}}>{item.name}</td>
                                                <td style={{width: '15%'}}>{item.description}</td>
                                                <td style={{width: '40%'}}>{item.model}</td>
                                              </tr>
                                            ): null
                                          )
                                        })
                                      )
                                    }
                                    </tbody>
                                  </table>
                                </div>
                              </>
                            ): (
                              <ul className={css['group']}>
                                {
                                  subGroupShow.content ? (
                                    subGroup.map((item: {label: string, img: string, mid: string, colorvalue: number, click?: number}, index: number)=>{
                                      return (
                                        filter || item.colorvalue ? (
                                          <li key={index} onClick={()=>goDetail(structureActive, index)} className={item.colorvalue ? '' : css['filter']}>
                                            <img src={item.img} />
                                            <p>{item.mid}</p>
                                            {
                                              item.click === 0 ? <div></div> : null
                                            }
                                          </li>
                                        ): null
                                      )
                                    })
                                  ): (
                                    structure.map((item: {label: string, img: string, mid: string, colorvalue: number, has_child: number, click?: number}, index: number)=>{
                                      return (
                                        filter || item.colorvalue ? (
                                          <li key={index}  onClick={()=>{if(item.has_child){getSubGroup(index)}else {goDetail(index)}}} className={item.colorvalue ? '' : css['filter']}>
                                            <img src={item.img} />
                                            <p>{item.mid}</p>
                                            {
                                              item.click === 0 ? <div></div> : null
                                            }
                                          </li>
                                        ): null
                                      )
                                    })
                                  )
                                }
                              </ul>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </Loading>
        ): null
      }
      
      {
        partSearchkey ? (
          <PartSearch
            key={partSearchkey}
            keyword={partKey}
            mcid={mcid || data.data.mcid }
            brandcode={brandcode || data.brandCode}
            vin={vin || ''}
          />
        ): null
      }
      <Modal
        title={intl.formatMessage({id: 'config'})}
        visible={configVisible}
        cancel={setConfigVisible}
      >
        <div className={css['config']}>
          <div className={css['search']}>
            <input placeholder="Please input key" onChange={(e)=>searchConfig(e.target.value)} />
          </div>
          <div className={css['content']}>
            {
              config.map((item: {title: string, value: string}, index: number)=>{
                return (
                  <div className={css['item']} key={index}>
                    <label>{item.title}</label>
                    <span>{item.value}</span>
                  </div>
                )
              })
            }
          </div>
        </div>
      </Modal>
      <Modal
        title={selectConfig.desc || intl.formatMessage({id: 'selectConfigurationInformation'})}
        visible={selectConfigVisible}
        cancel={setSelectConfigVisible}
        width={1000}
      >
        <div className={css['select-config']}>
          <div className={css['select-brand']}>
            <span>{selectConfig.res_desc}</span>
            <img src={cdnsPath + selectConfig.brandurl} />
          </div>
          <table>
            <thead>
              <tr>
                {
                  selectConfig.title && selectConfig.title.map((item: string)=>{
                    return (
                      <th>{item}</th>
                    )
                  })
                }
              </tr>
            </thead>
          </table>
          <div className={css['select-scroll']}>
            <table>
              <tbody>
                {
                  selectConfig.select_data && selectConfig.select_data.map((item: any)=>{
                    return (
                      <tr onClick={()=>getVin(true, urlParams, vin, selectConfig.brandCode, item.mcid)}>
                        {
                          item.contentData.map((content: string)=>{
                            return (
                              <td>{content}</td>
                            )
                          })
                        }
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
      {
        userInfo.userid && !guidecache.group ? <Guide type='group' /> : null
      }
      {/* {
        progressVisible ? (
          <div className={css.pollingVin}>
            <div>
              <span style={{width: progress * 50 + '%'}}></span>
            </div>
          </div>
        ): null
      } */}
      
    </>
  );
}

export default Vin
