const pt = {
  locale: 'pt-PT',
  messages: {
    "inputVinBrand": "Por favor insira o número do quadro/número da peça/marca",
    "history": "História",
    "home": "Página inicial",
    "catalog": "Modelo",
    "search": "pesquisa",
    "dragFile": "Colar ou arrastar arquivos para esta área para reconhecimento",
    "selectImage": "Seleccionar a Imagem",
    "howWorks": "Entender como funciona",
    "more": "mais",
    "helpSection": "ajuda",
    "freeTrialDay": "Teste gratuito por 3 dias",
    "account": "conta",
    "purchase": "comprar",
    "message": "notícias",
    "favorites": "recolha",
    "feedbackMessage": "Mensagem de feedback",
    "watch": "Lista de Favoritos",
    "coupon": "cupão",
    "signOut": "Sair",
    "register": "registo",
    "subscribe": "assinatura",
    "freeSearch": "Pesquisa gratuita",
    "inviteTips": "Clique no botão para convidar 10 amigos para receber cartões anuais gratuitos",
    "invite": "convidar",
    "freeTrialTips": "Para continuar usando o Partsbond, inicie uma avaliação gratuita",
    "freeTrial": "Avaliação gratuita",
    "helpVideos": "Vídeo de ajuda",
    "copyright": "Copyright@2020 AutoBond Inc. Todos os direitos reservados",
    "api": "API",
    "app": "Aplicação",
    "about": "Sobre nós",
    "contact": "entre em contato conosco",
    "and": "e",
    "privacy": "Privacidade e Termos",
    "feedback": "feedback",
    "writeOpinion": "Escreva a sua opinião",
    "sentUsEmail": "Endereço de e- mail: aaronw@autobondinc.com",
    "sentUs": "entre em contato conosco",
    "iosDownload": "Transferência do IOS",
    "androidDownload": "Baixar Android",
    "inputPNMR": "Indicar o número da peça/nome/modelo/observações",
    "view3D": "Clique para ver o modelo 3D",
    "again": "Mais uma vez",
    "pictures": "imagem",
    "partsNumber": "Número da peça",
    "partsName": "Nome da Parte",
    "priceRange": "Faixa de preços",
    "operation": "operação",
    "email": "caixa de correio",
    "vCode": "Código de verificação",
    "nPassword": "Nova senha",
    "cNPassword": "Confirmar a senha nova",
    "submit": "Enviar",
    "getCode": "Obter código de verificação",
    "countdown": "{num}s",
    "inputEmail": "Introduza o seu endereço de e-mail",
    "emailCorrectly": "Por favor, preencha o e-mail correctamente",
    "inputPassword": "Introduza a senha",
    "passwordsInconsistent": "As duas senhas inseridas não correspondem",
    "sendCodeSuccess": "O envio foi bem sucedido",
    "agreeUserAgreement": "Por favor, concorde com o Contrato do Usuário",
    "tryFree": "Peças de teste gratuitas",
    "tryFreeDay": "Depois de fazer login, clique na avaliação gratuita para obter uma avaliação de 7 dias",
    "registrationCodeSentSuccess": "Código de registo enviado com sucesso! Se você não receber o código dentro de 1-2 minutos, tente atualizar sua caixa de entrada e verificar sua pasta de spam.",
    "findUs": "Como nos encontraste?",
    "iAgree": "Concordo",
    "userAgreement": "Acordo do Utilizador",
    "brand": "marca",
    "sampleSearch": "Pesquisa de exemplo",
    "passwordChangedSuccess": "A senha foi alterada com sucesso",
    "passwordChangedFailed": "A modificação da senha falhou",
    "enterNewName": "Indique um novo nome de utilizador",
    "userChangedSuccess": "A modificação do utilizador foi bem sucedida",
    "userChangedFailed": "Não foi possível modificar a informação do utilizador",
    "fillName": "Por favor, preencha primeiro o seu nome de utilizador",
    "vinBindingSuccee": "A ligação do número da moldura foi bem sucedida",
    "success": "sucesso",
    "brandPackage": "Pacote de inquérito da marca do carro",
    "combo": "combinação",
    "validTime": "Tempo efectivo",
    "cancelRenewal": "Cancelar a actualização",
    "packageContent": "Pacote",
    "bindFrame": "Ligar mais um número",
    "remainingQueries": "Consultas restantes",
    "personalInformation": "informações pessoais",
    "loginAccount": "Conta de autenticação",
    "nickName": "alias",
    "modify": "modificar",
    "bindingEmail": "Ligar o e- mail",
    "loginPassword": "Senha de autenticação",
    "enterContent": "Conteúdo de entrada",
    "noCoupons": "Não existem cupões disponíveis",
    "discountOperation": "Operação de desconto",
    "preferentialContent": "Conteúdo de desconto",
    "serviceLife": "vida útil",
    "user": "utilizador",
    "remarks": "Observações",
    "noPartsCollection": "Sem peças disponíveis",
    "selectAll": "Seleccionar Tudo",
    "delete": "apagar",
    "vin": "Número do quadro",
    "oe": "OE",
    "detail": "detalhes",
    "noNews": "Sem notícias",
    "feedbackDetails": "informações de feedback",
    "feedbackStatus": "Estado das reacções",
    "feedbackTime": "Tempo de resposta",
    "questionType": "Tipo de problema",
    "problemDescription": "Descrição do problema",
    "solved": "resolvido",
    "pending": "esperar por",
    "supplementaryQuestion": "Perguntas adicionais",
    "endFeedback": "Feedback final",
    "cancel": "cancelar",
    "invalidCoupon": "Não foi encontrado nenhum código de desconto",
    "bindVin": "Número do quadro da ligação {num}",
    "annualSubscribe": "Assinatura anual",
    "currencySymbol": "$",
    "enterNumber": "Introduza números individuais em sequência",
    "boundRemaining": "{hasBound}/{remaningBound} Ligação de número de quadro [{tem}/{remanescente} Ligação de número de quadro]",
    "boundDescribe1": "Várias assinaturas podem ser aplicadas a uma conta",
    "boundDescribe2": "A assinatura é válida por 1 ano civil (a partir da data de compra)",
    "boundDescribe3": "A vinculação entre utilizadores registados e utilizadores registados é permanente; Se você deseja vincular seu(s) VIN(s) mais tarde, você pode fazê-lo em seus detalhes de conta",
    "allInOne": "All-in-One",
    "asiaPack": "PACOTE ÁSIA",
    "euroPack": "EURO PACK",
    "usPack": "EMBALAGEM DOS EUA",
    "daySubscription": "Assinatura diária",
    "monthSubscription": "Assinatura mensal",
    "annualSubscription": "Assinatura anual",
    "use": "aplicar",
    "howGetCode": "Como obter um código de desconto",
    "payPalWith": "PayPal com",
    "openingPermission": "Abrir permissões",
    "availablePackages": "Pacotes disponíveis",
    "bestMatch": "Melhor Jogo",
    "priceLowHigh": "Aumentos de preços de baixo para alto",
    "priceHighLow": "Preço do alto ao baixo",
    "pleaseLoginFirst": "Por favor, faça o login primeiro",
    "here3DArea": "Esta é a área 3D",
    "applicableVehicles": "Modelos de veículos aplicáveis",
    "applicableVehicle": "Adequado para automóveis",
    "interchangeableParts": "Peças de substituição",
    "condition": "condição",
    "all": "inteiro",
    "channel": "canal",
    "location": "posição",
    "partsFound": "Peças",
    "noData": "Não existem dados disponíveis",
    "carsModels": "Modelo (Ano)",
    "year": "ano",
    "market": "mercado",
    "oeNumber": "Número OE",
    "partName": "Nome da Parte",
    "count": "número",
    "partsPrice": "Comparação dos preços das peças",
    "directReplacement": "Substituição directa",
    "indirectReplacement": "Substituição indirecta",
    "priceTrend": "Evolução dos preços",
    "partPicture": "Imagens de peças",
    "brands": "marca",
    "replacement": "substituir",
    "applicableVeh": "Veh aplicável",
    "supplier": "fornecedor",
    "getIt": "obter",
    "go": "Vai",
    "findResults": "Encontrar resultados sobre {num} para si",
    "partOe": "OE",
    "mainGroup": "Grupo principal",
    "subGroup": "Dividir o corpo principal",
    "group": "grupo",
    "configDetails": "Detalhes da configuração",
    "selectGroup": "Seleccionar o Mestre",
    "conflg": "atribuição",
    "picture": "imagem",
    "mg": "MG",
    "sg": "SG",
    "illNo": "ILL-NO",
    "name": "nome",
    "remark": "Observações",
    "model": "Modelo do veículo",
    "selectConfigurationInformation": "Seleccionar a informação da configuração",
    "groupOne": "Primeiro grupo!",
    "lastGroup": "Último grupo!",
    "list": "lista",
    "back": "retorno",
    "switchGroup": "interruptor",
    "notFilter": "Não filtrado",
    "filter": "filtro",
    "config": "atribuição",
    "pos": "POS",
    "qty": "QTY",
    "addWatch": "Adicionar à lista de favoritos",
    "brandPart": "Peças de marca",
    "component": "montagem",
    "disclaimer": "Aviso Legal: Os resultados da consulta são apenas para referência.",
    "partDetail": "Detalhes da peça",
    "vinNotAvailable": "Código de identificação do veículo não disponível",
    "partNotAvailable": "Número da peça não disponível",
    "lackModels": "Modelo em falta",
    "dataNotComplete": "Dados incompletos",
    "dataError": "erro de dados",
    "dataNotNew": "Os dados não são novos o suficiente",
    "slowSpeed": "Velocidade lenta",
    "noUniqueness": "Não é único",
    "other": "outros",
    "selectQuestion": "Questão de selecção",
    "close": "fechar",
    "howVin": "Como visualizar os números do rack?",
    "howVin1": "Encontre o número do chassi e registe o código de identificação do veículo no vidro da janela dianteira da carroçaria do veículo",
    "howVin2": "Digite o número da caixa ou clique para carregar uma imagem para identificar o número da caixa",
    "howVin3": "Clique no ícone de consulta para consultar o número da caixa",
    "howVin4": "Digite o nome da marca e clique no ícone de pesquisa para pesquisá-lo",
    "findParts": "Como encontrar as peças?",
    "findParts1": "Passe o mouse sobre o número do rack de estacionamento para ver as informações básicas de configuração",
    "findParts2": "Clique neste ícone para usar um modelo 3D para consultar componentes do corpo, componentes do chassi e componentes do motor",
    "findParts3": "Selecione o grupo principal e o grupo para entrar na lista de peças",
    "findParts4": "Clique para selecionar novamente o grupo principal e o número da caixa para consulta",
    "findParts5": "Clique no número da peça da vista explodida para selecionar a peça",
    "howPart": "Como usar a consulta de número de peça?",
    "howPart1": "Digite ou carregue o número de peça de reconhecimento de imagem, clique em pesquisa para consultar",
    "howPart2": "Clique no ícone para coletar as peças em minha coleção. Quando o preço das peças mudar, você receberá uma notificação por e-mail",
    "howPart3": "Clique no ícone para ver a tendência recente de preços da peça",
    "noHelp": "Não há necessidade de ajuda",
    "understood": "Entendido",
    "nextStep": "Passo seguinte",
    "clickCheckInvitation": "Carregue aqui para ver o estado do convite",
    "inviteFriendsGetPackage": "Convide 10 amigos para receber o pacote anual no valor de US $ 540!",
    "emailAddress": "Endereço de e- mail",
    "status": "estado",
    "redeem": "troca",
    "inviteRewardTips": "<p>Por favor, confirme sua inscrição para<br/><em>All in One Annual Purchase</em>Recompensas</p><p>Se você tiver um pacote válido atualmente, sua recompensa será automaticamente resgatada após a expiração",
    "currentSubscriptionExpires": "A data de validade do seu pacote actual é",
    "confirm": "confirmar",
    "putAway": "Guardar",
    "seeAll": "Ver Todos",
    "pageCannotEmpty": "O número de páginas não pode estar vazio",
    "pleaseRegisterSignin": "Por favor, registre-se / faça login para procurar mais dados do veículo",
    "price": "preço",
    "quantity": "Quantidade",
    "pleaseRL": "Faça login após o registro",
    "sign": "Login",
    "stay": "Manter a sessão iniciada",
    "forget": "Esqueceu- se da senha",
    "password": "senha",
    "RFFVS": "Registe-se para a pesquisa gratuita vin",
    "cName": "Nome da empresa",
    "basic": "Informação de base",
    "updateTime": "Hora da actualização",
    'OfficialStore': 'Loja Oficial',
    'english': 'English',
    'russian': 'русск',
    'indonesian': 'Indonesia',
    'vietnamese': 'Tiếng Việt',
    'thai': 'คนไทย',
    'malaysia': 'Malaysia',
    'arabia': 'بالعربية',
    'spain': 'Espagne',
    'portugal': 'Portugal',
    'france': 'France',
    'Turkey': 'Türkçe',
    'korean': '한국어',
    'japanese': '日本語'
  }
}

export default pt