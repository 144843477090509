import React, { useState, useEffect, useRef, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import MainContext from '@/context/MainContext'
import Tooltip from 'rc-tooltip'
import { Breadcrumb } from '@/page/Main';
import PartList, { PartItem } from '@/components/PartList'
import Pagination from '@/components/Pagination'
import Empty from '@/components/Empty'
import Modal from '@/components/Modal'
import Toast from '@/components/Toast'
import Copy from '@/components/Copy'
import Trend from '@/components/Trend'
import { Images } from '@/components/Ugc'
import Loading from '@/components/Loading'
import Upload from '@/components/Upload'
import Guide from '@/components/Guide'
import Checkbox, { CheckboxGroup } from '@/components/Checkbox'
import Radio, { RadioGroup } from '@/components/Radio'

import { Three } from '@/components/ThreeModel'
import { PartSearch } from '@/page/Vin'
import { getUrlParams, cdnsPath } from '@/utils/utils'
import { DetailFormat, VehicleFormat } from './inerface'
import { PartItemFormat, PagerType } from '@/components/PartList/inerface'

import styles from './partspriceNew.module.scss'
import carApi from '@/api/carApi'
import accountApi from '@/api/account'
import vinApi from '@/api/vinApi'
import ugcApi from '@/api/ugcApi'

import ebay from '@/assets/images/ebay.png'
import RockAuto from '@/assets/images/RockAuto.png'
import defaultPart from '@/assets/images/default_part.png'
import empty from '@/assets/images/empty.png'
import threeGif from '@/assets/images/three_icon2.gif'

interface BrandL {
  name: string
  img: string
  brandCode: string
  price: string
}

export type ItemFormat = {
  num?: number,
  name?: string,
  selected?: number,
  amount?: number,
  [propName: string]: any
}
export type ValueListFormat = {
  channel: ItemFormat[],
  brand: ItemFormat[],
  location: ItemFormat[],
  condition: ItemFormat[]
}
// function usePrevious(value: any) {
//   const ref = useRef();
//   useEffect(() => {
//     ref.current = value;
//   });
//   return ref.current;
// }


let channelsInit = true

const PartsPriceNew: React.FC = () => {
  const intl = useIntl()
  const guidecache = JSON.parse(localStorage.getItem('guidecache') || '{}')
  const history = useHistory()
  const { store, setStore } = useContext(MainContext)
  const { userInfo, watchTotal } = store

  const containerWidth = document.body.clientWidth - 40 - 262 - 2
  const containerInt = Math.floor(containerWidth / 250)
  const pageSize = Math.ceil(50 / containerInt) * containerInt
  const itemWidth = 228 + ((containerWidth - (containerInt * 250) - 20) / containerInt)
  const { pid, brandcode } = getUrlParams()
  const urlParams = JSON.stringify(getUrlParams())
  const [loading, setLoading] = useState<boolean>(false)
  const [detailInfo, setDetailInfo] = useState<DetailFormat>({
    part_image: '',
    part_name: '',
    pid: '',
    price_scope: {
      max_price: '',
      min_price: ''
    },
  })

  const [data, setData] = useState<PartItemFormat[]>([])
  const [replaceData, setReplaceData] = useState<any>({})
  const [listType, setListType] = useState<string>('block')

  
  const [valueList, setValueList] = useState<ValueListFormat>({
    channel: [],
    brand: [],
    location: [],
    condition: []
  })
  const [values, setValues] = useState<any>({
    channel: [],
    brand: [],
    location: [],
    condition: []
  })
  const [visible, setVisible] = useState<boolean>(false)
  const [rpVisible, setRpVisible] = useState<boolean>(false)
  
  const [vehicle, setVehicle] = useState<string>('')
  const [vehicleInfo, setVehicleInfo] = useState<VehicleFormat>({
    brand: [],
    data: []
  })
  const [pager, setPager] = useState<PagerType>({
    page: 1,
    size: pageSize,
    total: 0
  })
  const [sort, setSort] = useState<string>('')
  
  const [pInfo, setPInfo] = useState<any>({})

  const [favorite, setFavorite] = useState<boolean>(false)
  const [like, setLike] = useState<boolean>(false)

  const [trendData, setTrendData] = useState<any>({})
  const [trendVisible, setTrendVisible] = useState<boolean>(false)
  const [trendLoading, setTrendLoading] = useState<boolean>(false)
  const [ugcImgVisible, setUgcImgVisible] = useState<boolean>(false)
  const [ocrImg, setOcrImg] = useState<any>([])

  const [uploadLoading, setUploadLoading] = useState<boolean>(false)
  
  const [channels, setChannels] = useState<any>({})
  const [updateData, setUpdateData] = useState<number>(0)
  
  // const prevValues = usePrevious(values)
  const [ partSearchkey, setPartSearchkey ] = useState<number>(0)
  const [activePart, setActivePart] = useState<any>({})

  const [ threePriceTips, setThreePriceTips ] = useState<boolean>(window.localStorage.getItem('threePriceTips') === '1' ? false : true)
  
  const sortList = [
    {
      value: '',
      label: intl.formatMessage({id: 'bestMatch'})
    },
    {
      value: 'asc',
      label: intl.formatMessage({id: 'priceLowHigh'})
    },
    {
      value: 'desc',
      label: intl.formatMessage({id: 'priceHighLow'})
    }
  ]

  useEffect(() => {
    if(pid){
      getDetail()
      getList()
    }
    
    channelsInit = true
  }, [])

  useEffect(()=>{
    if(!threePriceTips){
      // window.sessionStorage.setItem('threePriceTips', '1')
    }
  },[threePriceTips])
  
  useEffect(() => {
    if(channelsInit && JSON.stringify(channels) !== '{}'){
      channelsInit = false
      polling(urlParams, '', pid)
    }
  }, [channels])
  
  useEffect(() => {
    if(valueList.channel.length){
      let _pager = {...pager}
      if(_pager.page === 1){
        getData()
      }else {
        _pager.page = 1
        setPager(_pager)
      }
    }
  }, [sort, values])

  useEffect(() => {
    if(pager.size && valueList.channel.length) {
      getData()
    }
  }, [pager.page, pager.size])

  useEffect(() => {
    if(updateData) {
      getData(true)
    }
  }, [updateData])

  useEffect(() => {
    if(activePart.mcid) {
      // setPartSearchkey(partSearchkey + 1)
      goPart()
    }
  }, [activePart])
  
  const checkSingle = (condition?: any) => {
    carApi.checkSingle({pid}).then((res: any) => {
      if(res.code === 1) {
        let temp = {...values}
        if(condition.length){
          temp.condition = condition
        }
        if(res.hasus){
          temp.location = ['United States']
        }else {
          // getData()
        }
        setValues({...temp})
        if(res.hasfavorite){
          setFavorite(true)
        }
        if(res.data[0]) {
          let item: BrandL = res.data[0].brand_list[0]
          if(item) {
            let { brandCode } = item
            carApi.partBInfo({pid, brandCode, query_type: "part_search"}).then((result: any) => {
              if(result.code === 1) {
                setPInfo(result.data)
              }
            })
          }else {
            carApi.otherPartInfo({pid}).then((result: any) => {
              if(result.code === 1) {
                setPInfo(result.data)
              }
            })
          }
        }
      }else {
        carApi.otherPartInfo({pid}).then((result: any) => {
          if(result.code === 1) {
            setPInfo(result.data)
          }
        })
      }
    })
  }
  function getDetail() {
    let param: any = {
      pid: pid
    }
    if(brandcode){
      param.brand_code = brandcode
    }
    setLoading(true)
    carApi.getPartDetail(param).then((result: any)=>{
      setLoading(false)
      if(result.code === 1){
        setDetailInfo(result.data)
        setVehicleInfo({
          data: result.data.appliance_brands,
          brand: result.data.brands
        })
        replace({pid, brandCode:result.data.brands[0]})
        getImg({pid, brandCode:result.data.brands[0]})
      }
    })
  }
  function getData(isLoading?: boolean) {
    
    let params: any = {
      pid: pid
    }
    Object.assign(params, {
      ...pager,
      size: pager.size ? pager.size : 10
    })
    if(sort) {
      Object.assign(params, {
        sort: sort
      })
    }
    if(params.total){
      delete params.total
    }
    if(values.channel.length) {
      params.source_code = values.channel.join(',')
    }
    if(values.brand.length) {
      params.brand = values.brand.join(',')
    }
    if(values.location.length) {
      params.location = values.location.join(',')
    }
    if(values.condition.length){
      params.condition = values.condition.join(',')
    }
    // console.info('values===========')
    // console.info(values)
    if(!isLoading){
      setLoading(true)
    }
    carApi.getData(params).then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        const { parts } = res.data
        let _pager = {...pager}
        _pager.total = res.data.total
        setPager(_pager)
        setData(parts)
      }
    }).catch(err => {
      setLoading(false)
    })
  }
  function getList() {
    carApi.getFilterList({
      pid: pid
    }).then((res: any) => {
      if(res.code === 1) {
        let _valueList = {...valueList}
        _valueList.channel = res.data.channels ? [...res.data.channels] : []
        _valueList.brand = res.data.brands ? [...res.data.brands] : []
        _valueList.location = res.data.locations ? [...res.data.locations] : []
        _valueList.condition = res.data.condition || []
        let tempChannels: any = {}
        _valueList.channel.forEach((item: any)=>{
          tempChannels[item.key] = {
            img : item.img,
            progress: 0,
            status: 'reload'
          }
        })
        let condition: any = []
        if(res.data.condition && res.data.condition.length){
          res.data.condition.forEach((item: any)=>{
            if(item.selected){
              condition.push(item.type)
            }
          })
        }
        setChannels(tempChannels)
        setValueList(_valueList)
        checkSingle(condition)
      }
    })
  }
  function onPagerChange(pagination: PagerType) {
    setPager({...pagination})
  }
  const setFavourite = () => {
    if(!userInfo.userid){
      Toast(intl.formatMessage({id: 'pleaseLoginFirst'}), 'warning')
      return
    }
    let param: any = {
      pid: pid,
      brandCode: pInfo.brandCode
    }
    if(favorite){
      setFavorite(false)
      accountApi.favouriteDel(param).then((result: any)=>{
        if(result.code === 1){
          setStore({value: watchTotal - 1, type: 'watchTotal'})
        }
      })
    }else {

      param.img = pInfo.img || pInfo.part_image || ''
      param.name = pInfo.label || pInfo.part_name
      setFavorite(true)
      accountApi.favouriteAdd(param).then((result: any)=>{
        if(result.code === 1){
          setStore({value: watchTotal + 1, type: 'watchTotal'})
        }
      })
    }
  }

  const polling = (urlParamsOld: string, channel?: string, pidOld?: string, data?: any, update?: number) => {
    const urlParamsTemp = JSON.stringify(getUrlParams()) || null
    // console.info(urlParamsOld + '===' + urlParamsTemp)
    if(urlParamsOld === urlParamsTemp){
      let param: any = {
        pid: pidOld
      }
      if(channel){
        param.source_code = channel
      }
      carApi.polling(param).then((result: any) => {
        if(result.code === 1 && (urlParamsOld === urlParamsTemp) && result.data){

          // console.info(urlParamsOld + '===' + urlParamsTemp)
          let temp = JSON.parse(JSON.stringify(data || channels))
          // console.info(channels)
          let channelTemp: any = []
          Object.keys(result.data).forEach((key)=>{
            if(temp[key] && (!channel || key === channel)){
              const val = result.data[key]
              temp[key].status = val === 1 ? 'success' : 'reload'
              if(temp[key] !== 1 && val === 1){
                update = (update || 0) + 1
                // console.info('==============123')
                setUpdateData(update)
              }
              if(val === 1 || val === 3){
                temp[key].progress = 5
              }else {
                channelTemp.push(key)
                if(temp[key].progress < 4){
                  temp[key].progress++
                }else {
                  // temp[key].progress = 1
                }
              }
            }
          })
          setChannels(temp)
          if(channelTemp.length){
            setTimeout(()=>{
              polling(urlParamsOld, channelTemp.length === 1 ? channelTemp.join(',') : channel, pidOld, temp, update)
            }, 5000)
          }else {
            // setUpdateData(updateData + 1)
            // getData()
          }
        }
      })
    }
  }
  const single = (index: number) => {
    let tempData = [...data]
    let item = tempData[index]
    const param = {
      pid: item.pid,
      source_code: item.channel,
      price: item.part_price
    }
    carApi.single(param).then((result: any)=>{
      if(result.code === 1){
        Toast('successfully')
        tempData[index].update_time = result.data
        setData(tempData)
      }
    })
  }
  const replace = (param: {pid: string, brandCode: string}) => {
    vinApi.replace(param).then((result: any)=>{
      if(result.code === 1){
        let directly: any = []
        let indirect: any = []
        result.data.forEach((item: {isre: string}) => {
          if(item.isre === "1" || item.isre === "3"){
            directly.push(item)
          }
          if(item.isre === "0"){
            indirect.push(item)
          }
        })
        
        setReplaceData({directly, indirect})
      }
    })
  }
  const showTrend = (param: any) => {
    setTrendVisible(true)
    setTrendLoading(true)
    setTrendData({})
    vinApi.pricetrend(param).then((result: any)=>{
      setTrendLoading(false)
      if(result.code === 1){
        setTrendData({
          data: result.data,
          max: Math.ceil(result.max_price) + 20,
          min: Math.ceil(result.min_price) - 40 > 0 ? Math.ceil(result.min_price) - 40 : 0
        })
      }
    })
  }
  const getImg = (param: any) => {
    ugcApi.pics(param).then((result: any)=>{
      if(result.code === 1 && result.data && result.data.length){
        setOcrImg(result.data)
        changeActive(result.data)
      }
    })
  }
  const beforeUpload = (file: File, FileList: File[]) => {
    return new Promise((resolve, reject) => {
      resolve(file)
    })
  }
  const start = (result: any) => {
    setUploadLoading(true)
  }
  const success = (result: any, file: File) => {
    if(result.code === 1){
      result.data.has = ''
      result.data.love_count = 0
      result.data.bad_count = 0
      result.data.candel = 1
      result.data.pic = result.data.img_url
      setOcrImg([result.data])
      setUgcImgVisible(true)
    }
    setUploadLoading(false)
  }
  const error = (result: any) => {
    Toast('error', 'warning')
    setUploadLoading(false)
  }
  const operate = (type: string) => {
    let ocrTemp: any = [...ocrImg]
    let api
    if(ocrTemp[0].has && JSON.stringify(ocrTemp[0].has) !== ''){
      return
    }
    if(type === 'love'){
      api = ugcApi.love
      ocrTemp[0].has = 'love'
      ocrTemp[0].love_count += 1
    }else {
      api = ugcApi.bad
      ocrTemp[0].has = 'bad'
      ocrTemp[0].bad_count += 1
    }
    setOcrImg(ocrTemp)
    api({pid, brandCode: detailInfo.brands ? detailInfo.brands[0] : '', ugc_id: ocrTemp[0].ugc_id}).then((result: any)=>{
      if(result.code === 1){
        Toast(intl.formatMessage({id: 'success'}))
      }
    })
  }
  const changeActive = (ocrTemp: any) => {
    if(ocrTemp[0] && ocrTemp[0].has === undefined){
      ugcApi.hasoperate({pid, brandCode: detailInfo.brands ? detailInfo.brands[0] : '', ugc_id: ocrTemp[0].ugc_id}).then((result: any)=>{
        if(result.code === 1){
          ocrTemp[0].has = result.data ? result.data.operate : ''
          setOcrImg(ocrTemp)
        }
      })
    }
  }
  const valuesChange = (type: string, value: any) => {
    let valuesTemp: any = JSON.parse(JSON.stringify(values))
    let valueListTemp: any = JSON.parse(JSON.stringify(valueList))
    console.info(value)
    if(!value.length){
      console.info(valueListTemp)
      console.info(type)
      valueListTemp[type].forEach((item: any)=>{
        value.push(item.type)
      })
    }
    valuesTemp[type] = value
    setValues(valuesTemp)
  }
  
  const goPart = () => {
    let param = {
      mcid: activePart.mcid,
      brandCode: activePart.brandCode,
      search_key: pid,
      search_type: 'pid'
    }
    
    vinApi.partSearch(param).then((result: any)=>{
      if(result.code === 1 && result.data?.length){
        console.info(result.data)
        let tempCrumb: any = []
        let item = result.data[0]

        tempCrumb.push({
          type: 'logo',
          img: `${cdnsPath}${item.img}` || `${cdnsPath}/img/${item.brandCode}.png`
        })
        tempCrumb.push({
          name: item.brandname || item.brandCode
        })
        tempCrumb.push({
          type: 'config',
          show_name: item.car_name,
          name: item.car_name
        })
        tempCrumb.push({
          type: 'group',
          name: item.main_name,
          num: item.num,
        })
        tempCrumb.push({
          type: 'keyword',
          name: pid
        })
        setStore({value: tempCrumb, type: 'crumb'})
        let urlParam = `vin=${''}&brandcode=${item.brandCode}&mcid=${item.mcid}&num=${item.num}&keyword=${pid}&onlyType=partsprice`
        let url = `/search/detail?${urlParam}`
        history.push(url)
      }
    })
  }
  return (
    <>
      <div className={styles.partsprice}>
        <div className={styles.l}>
          <div className={styles.basic}>
            <div className={styles.img}>
              {
                pid ? (
                  <div className={favorite ? styles['tools'] : styles.watch} onClick={()=>setFavourite()}>
                    {/* {
                      ocrImg.length ? (
                        <span onClick={()=>operate('love')}  className={ocrImg[0]?.has === 'love' ? 'iconfont iconyidiandz' : 'iconfont icondianzan1'}></span>
                      ): null
                    } */}
                    {
                      favorite ? (
                        <span>
                          <i className={'iconfont iconyishoucang1'} /> Watched
                        </span>
                      ): (
                        <span>
                          <i className={'iconfont iconshoucangmoren'} /> Add to watch list
                        </span>
                      )
                    }
                    
                    {/* {
                      ocrImg.length ? (
                        <span onClick={()=>setUgcImgVisible(true)} className='iconfont iconshangchuantupian'></span>
                      ): (
                        uploadLoading ? (
                          <span className='iconfont iconshangchuantupian'></span>
                        ): (
                          <Upload
                            setLoading={setUploadLoading}
                            loading={uploadLoading}
                            action={ugcApi.uploadPath}
                            name="img"
                            beforeUpload={beforeUpload}
                            start={start}
                            success={success}
                            error={error}
                            data={{pid, brandCode: detailInfo.brands ? detailInfo.brands[0] : ''}}
                          >
                            <span className='iconfont iconshangchuantupian'></span>
                          </Upload>
                        )
                      )
                    } */}
                    
                  </div>
                ): null
              }
              {
                ocrImg.length ? (
                  ocrImg[0].pic.endsWith('gltf') ? (
                    // <img src={ocrImg[0].pic} onClick={()=>{setUgcImgVisible(true)}}/>
                    <>
                      <Three file={ocrImg[0].pic} />
                      <i style={{position: 'absolute', right: 10, top: 10, cursor: 'pointer', color: '#1890ff'}} className="iconfont iconshangchuantupian" onClick={()=>setUgcImgVisible(true)} />
                      <Tooltip visible={threePriceTips} overlayClassName="blue" mouseLeaveDelay={0} placement="bottomLeft" overlay={(<div className='tips'><p>{intl.formatMessage({id: 'here3DArea'})}</p><span className="iconfont icona-16pt1 btn" onClick={()=>{setThreePriceTips(false)}}></span></div>)} overlayStyle={{width: 150}} >
                        <img src={threeGif} style={{position: 'absolute', left: 30, top: 30, width: 50, cursor: 'default'}} />

                      </Tooltip>
                      
                    </>
                    
                  ): (
                    <img src={ocrImg[0].pic} onClick={()=>{setUgcImgVisible(true)}}/>
                  )
                ): (
                  <img src={ocrImg.length ? ocrImg[0].pic : defaultPart} alt="" onClick={()=>{setUgcImgVisible(pid ? true : false)}} />
                )
              }
            </div>
            <div className={styles.info}>
              <h3><Copy title={pid} /> <i className="iconfont iconlingjianzhishu" onClick={()=>showTrend({pid, part_name: pInfo.label || pInfo.part_name})} /></h3>
              <p>
                {pInfo.label || pInfo.part_name}
              </p>
              <p>
                <button onClick={()=>setVisible(true)}>{intl.formatMessage({id: 'applicableVehicles'})}</button>
                <button onClick={()=>setRpVisible(true)}>{intl.formatMessage({id: 'interchangeableParts'})}</button>
              </p>
            </div>
          </div>
          <div className={styles.channel}>
            <div className={styles.g}>
              <h6>{intl.formatMessage({id: 'condition'})}</h6>
              <div className={styles['menu-item']}>
                <Checkbox checked={values.condition.length === 0 || values.condition.length === valueList.condition.length ? true : false} onChange={()=>valuesChange('condition', [])}>{intl.formatMessage({id: 'all'})}</Checkbox>
              </div>
              <CheckboxGroup value={values.condition} onChange={(val)=>valuesChange('condition', val)}>
                {
                  valueList.condition.map((item, index) => {
                    return (
                      <div key={index} className={styles['menu-item']}>
                        <Checkbox value={item.type}>{item.type}</Checkbox> {item.amount ? `(${item.amount})` : ''}
                      </div>
                    )
                  })
                }
              </CheckboxGroup>
            </div>
            <div className={styles.g}>
              <h6>{intl.formatMessage({id: 'channel'})}</h6>
              <div className={styles['menu-item']}>
                <Checkbox checked={values.channel.length === 0 || values.channel.length === valueList.channel.length ? true : false} onChange={()=>valuesChange('channel', [])}>{intl.formatMessage({id: 'all'})}</Checkbox>
              </div>
              <CheckboxGroup value={values.channel} onChange={(val)=>valuesChange('channel', val)} >
                {
                  valueList.channel.map((item, index) => {
                    return (
                      <div key={index} className={styles['menu-item']} style={{justifyContent: 'space-between'}}>
                        <Checkbox value={item.key}><img src={item.img} /></Checkbox>
                        <div className={styles.polling}>
                          <div><span style={{width: channels[item.key].progress * 20 + '%'}}></span></div>
                          {
                            channels[item.key].progress !== 5 ? (
                              <button className={styles['trans']}><em className='iconfont iconzhongshuaxin'></em></button>
                            ): (
                              <button className={styles['success']} onClick={()=>polling(urlParams, item.key, pid)}><em className='iconfont iconwancheng'></em></button>
                            )
                          }
                          
                        </div>
                      </div>
                      
                    )
                  })
                }
              </CheckboxGroup>
            </div>
            <div className={styles.g}>
              <h6>{intl.formatMessage({id: 'location'})}</h6>
              <div className={styles['menu-item']}>
                <Checkbox checked={values.location.length === 0 || values.location.length === valueList.location.length ? true : false} onChange={()=>valuesChange('location', [])}>{intl.formatMessage({id: 'all'})}</Checkbox>
              </div>
              <CheckboxGroup value={values.location} onChange={(val)=>valuesChange('location', val)}>
                {
                  valueList.location.map((item, index) => {
                    return (
                      <div key={index} className={styles['menu-item']}>
                        <Checkbox value={item.type}>{item.type}</Checkbox>
                      </div>
                    )
                  })
                }
              </CheckboxGroup>
            </div>
          </div>
        </div>
        <div className={styles.r}>
          <div className={styles.sort}>
            <div className={styles.sortL}>
              <RadioGroup value={sort} onChange={value => setSort(value)} >
                {
                  sortList.map((item, index) => {
                    return (
                      <span key={item.value} style={{marginLeft: index > 0 ? 20 : 0}}>
                        <Radio value={item.value}>{item.label}</Radio>
                      </span>
                    )
                  })
                }
              </RadioGroup>
            </div>
            <div className={styles.sortR}>
              <div>{pager ? pager.total : '0'} {intl.formatMessage({id: 'partsFound'})}</div>
              <ul>
                <li className={listType === 'block' ? styles.active : ''} onClick={()=>setListType('block')}><span className='iconfont icontupian2'></span></li>
                <li className={listType === 'list' ? styles.active : ''} onClick={()=>setListType('list')}><span className='iconfont iconliebiao2'></span></li>
              </ul>
            </div>
          </div>
          <div className={styles.list}>
            
            {
              data.map((item: PartItemFormat, index: number) => {
                return (
                  <PartItem style={{width: itemWidth}} item={item} index={index} key={index} from='detail' channels={channels} polling={polling} showTrend={showTrend} type={listType} />
                )
              })
            }
          </div>
          {
            data.length ? (
              <div className={styles.page}>
                <Pagination current={pager.page} pageSize={pager.size} total={pager.total} onChange={(page: number)=>onPagerChange({...pager, page})}/>
              </div>
            ): (
              <div className={styles.empty}>
                <img src={empty} />
                <p>{intl.formatMessage({id: 'noData'})}</p>
              </div>
            )
          }
          
        </div>
      </div>
      <Modal
        visible={visible}
        cancel={() => setVisible(false)}
        width={800}
      >
        <div className={styles['vehicle-brand-wrap']}>
          <div className={styles['vehicle-select']}>
            <span className={styles['vehicle-title']}>{intl.formatMessage({id: 'applicableVehicle'})}: </span>
            <span className={styles['vehicle-brand-item'] + (vehicle === '' ? ` ${styles['active']}` : '')} onClick={() => setVehicle("")}>{intl.formatMessage({id: 'all'})}</span>
            {
              vehicleInfo.brand.map((item: any) => {
                return (
                  <span 
                  key={item} 
                  className={styles['vehicle-brand-item'] + (vehicle.toUpperCase() === item.toUpperCase() ? ` ${styles['active']}` : '')}
                  onClick={() => setVehicle(item)}
                  >{item.toUpperCase()}</span>
                )
              })
            }
          </div>
          <div className={styles['vehicle-table']}>
            <table cellPadding="0" cellSpacing="0">
              <thead>
                <tr>
                  <th style={{width: '20%'}}>{intl.formatMessage({id: 'brand'})}</th>
                  <th style={{width: '30%'}}>{intl.formatMessage({id: 'carsModels'})}</th>
                  <th style={{width: '20%'}}>{intl.formatMessage({id: 'year'})}</th>
                  <th style={{width: '20%'}}>{intl.formatMessage({id: 'market'})}</th>
                  <th style={{width: '10%'}}>{intl.formatMessage({id: 'operation'})}</th>
                </tr>
              </thead>
              {
                vehicleInfo.data.length ? <tbody>
                  {
                    vehicleInfo.data.map((item: any, index: number) => {
                      return (
                        <tr key={index} style={{display: (!vehicle || item.brand.toUpperCase() === vehicle.toUpperCase()) ? "table-row" : "none"}}>
                          <td style={{width: '20%', textAlign: 'center'}}>{item.brand.toUpperCase()}</td>
                          <td style={{width: '30%', textAlign: 'center'}}>{item.model}</td>
                          <td style={{width: '20%', textAlign: 'center'}}>{item.year}</td>
                          <td style={{width: '20%', textAlign: 'center'}}>{item.market}</td>
                          <td style={{width: '10%', textAlign: 'center'}}><button onClick={()=>setActivePart(item)}>{intl.formatMessage({id: 'catalog'})}</button></td>
                          
                        </tr>
                      )
                    })
                  }
                </tbody> : <tbody>
                  <tr>
                    <td colSpan={4} rowSpan={4}>
                      <Empty/>
                    </td>
                  </tr>
                </tbody>
              }
            </table>
          </div>
        </div>
      </Modal>
      <Modal
        visible={rpVisible}
        cancel={() => setRpVisible(false)}
        width={1000}
      >
        <div className={styles['vehicle-brand-wrap']}>
          <div className={styles['vehicle-select']}>
            <span className={styles['vehicle-title']}>{intl.formatMessage({id: 'interchangeableParts'})}</span>
          </div>
          <div className={styles['vehicle-table-h']}>
            <table className={styles['table-header']}>
              <thead>
                <tr>
                  <th style={{width: '15%', textAlign: 'center'}}>{intl.formatMessage({id: 'brands'})}</th>
                  <th style={{width: '25%', textAlign: 'center'}}>{intl.formatMessage({id: 'oeNumber'})}</th>
                  <th style={{width: '20%', textAlign: 'center'}}>{intl.formatMessage({id: 'partName'})}</th>
                  <th style={{width: '10%', textAlign: 'center'}}>{intl.formatMessage({id: 'count'})}</th>
                  <th style={{width: '15%', textAlign: 'center'}}>{intl.formatMessage({id: 'detail'})}</th>
                  <th style={{width: '15%', textAlign: 'center'}}>{intl.formatMessage({id: 'partsPrice'})}</th>
                </tr>
              </thead>
            </table>
            <div className={styles.maxh}>
              {
                replaceData.directly?.length ? (
                  <table>
                    <caption>{intl.formatMessage({id: 'directReplacement'})}</caption>
                    <tbody>
                      {
                        replaceData.directly.map((item: any, index: number)=>{
                          return (
                            <tr key={index}>
                              <td style={{width: '15%'}}>{item.brandcn}</td>
                              <td style={{width: '25%'}}>
                                {
                                  item.pid.length > 1 ? (
                                    <div className={styles.flex}>
                                      <div>
                                        {item.parentnum} <span className="iconfont icondengyu"></span>
                                      </div>
                                      <div>
                                        {
                                          item.pid?.map((pid: any, i: number)=>{
                                            return (
                                              <p key={i}><i className={styles.us}></i><Copy title={pid.pid} /></p>
                                            )
                                          })
                                        }
                                      </div>
                                    </div>
                                  ): (
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}><Copy title={pid.pid} icon /></p>
                                      )
                                    })
                                  )
                                }
                              </td>
                              <td style={{width: '20%'}}>
                                {
                                  item.pid?.map((pid: any, i: number)=>{
                                    return (
                                      <p key={i}>{pid.lable}</p>
                                    )
                                  })
                                }
                              </td>
                              <td style={{width: '10%'}}>
                                {
                                  item.pid?.map((pid: any, i: number)=>{
                                    return (
                                      <p key={i}>{pid.num}</p>
                                    )
                                  })
                                }
                              </td>
                              <td style={{width: '15%'}}>
                                {
                                  item.pid?.map((pid: any, i: number)=>{
                                    return (
                                      <p key={i}>{pid.remark}</p>
                                    )
                                  })
                                }
                              </td>
                              <td style={{width: '15%'}}>
                                {
                                  item.pid?.map((pid: any, i:number)=>{
                                    return (
                                      <p key={i}>{pid.cost_price}</p>
                                    )
                                  })
                                }
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                ): null
              }
              {
                replaceData.indirect?.length ? (
                  <table>
                    <caption>{intl.formatMessage({id: 'indirectReplacement'})}</caption>
                    <tbody>
                      {
                        replaceData.indirect.map((item: any, index: number)=>{
                          return (
                            <tr key={index}>
                              <td style={{width: '15%'}}>{item.brandcn}</td>
                              <td style={{width: '25%'}}>
                                {
                                  item.pid.length > 1 ? (
                                    <div className={styles.flex}>
                                      <div>
                                        {item.parentnum} <span className="iconfont icondengyu"></span>
                                      </div>
                                      <div>
                                        {
                                          item.pid?.map((pid: any, i: number)=>{
                                            return (
                                              <p key={i}>{pid.pid}</p>
                                            )
                                          })
                                        }
                                      </div>
                                    </div>
                                  ): (
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.pid}</p>
                                      )
                                    })
                                  )
                                }
                              </td>
                              <td style={{width: '20%'}}>
                                {
                                  item.pid?.map((pid: any, i: number)=>{
                                    return (
                                      <p key={i}>{pid.lable}</p>
                                    )
                                  })
                                }
                              </td>
                              <td style={{width: '10%'}}>
                                {
                                  item.pid?.map((pid: any, i: number)=>{
                                    return (
                                      <p key={i}>{pid.num}</p>
                                    )
                                  })
                                }
                              </td>
                              <td style={{width: '15%'}}>
                                {
                                  item.pid?.map((pid: any, i: number)=>{
                                    return (
                                      <p key={i}>{pid.remark}</p>
                                    )
                                  })
                                }
                              </td>
                              <td style={{width: '15%'}}>
                                {
                                  item.pid?.map((pid: any, i: number)=>{
                                    return (
                                      <p key={i}>{pid.cost_price}</p>
                                    )
                                  })
                                }
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                ): null
              }
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        visible={trendVisible}
        cancel={() => setTrendVisible(false)}
        width={1000}
      >
        <div className={styles.trend}>
          <h5>{intl.formatMessage({id: 'priceTrend'})}</h5>
          <Loading visible={trendLoading}>
            {
              trendData.data?.length ? <div className={styles.chart}><Trend data={trendData} /></div> : (
                <div className={styles.no}>{intl.formatMessage({id: 'noData'})}</div>
              )
            }
            
          </Loading>
        </div>
      </Modal>
      <Modal
        visible={ugcImgVisible}
        cancel={() => setUgcImgVisible(false)}
        width={1000}
      >
        <div className={styles.trend}>
          <h5>{intl.formatMessage({id: 'partPicture'})}</h5>
          <div>
            <Images ocr={ocrImg} setOcr={setOcrImg} pid={pid} brandCode={detailInfo.brands ? detailInfo.brands[0] : ''} />
          </div>
        </div>
      </Modal>
      {
        !guidecache.price ? <Guide type='price' /> : null
      }
      {
        loading ? <div className={styles.loading}><Loading visible={true}></Loading></div> : null
      }
      
      {
        partSearchkey ? (
          <PartSearch
            key={partSearchkey}
            keyword={pid}
            mcid={activePart.mcid}
            brandcode={activePart.brandCode}
            cancelFn={setActivePart}
            title={activePart.brand + ' ' + (pInfo.label || pInfo.part_name) + ' '}
          />
        ): null
      }
    </>
  )
}
export default PartsPriceNew