const fr = {
  locale: 'fr-FR',
  messages: {
    "inputVinBrand": "Veuillez entrer le numéro de cadre / numéro de pièce / Marque",
    "history": "Historique",
    "home": "Accueil",
    "catalog": "Modèle",
    "search": "Rechercher",
    "dragFile": "Coller ou faire glisser des fichiers dans cette zone pour identification",
    "selectImage": "Sélectionner une image",
    "howWorks": "Découvrez comment cela fonctionne",
    "more": "Plus",
    "helpSection": "Aide",
    "freeTrialDay": "Essayez gratuitement pendant 3 jours",
    "account": "Compte",
    "purchase": "Acheter",
    "message": "Message",
    "favorites": "Collections",
    "feedbackMessage": "Message de feedback",
    "watch": "Liste des collections",
    "coupon": "Coupons",
    "signOut": "Déconnexion",
    "register": "Enregistrement",
    "subscribe": "S'abonner",
    "freeSearch": "Recherche gratuite",
    "inviteTips": "Cliquez sur le bouton pour inviter 10 amis à recevoir une carte annuelle gratuite",
    "invite": "Invitation",
    "freeTrialTips": "Pour continuer à utiliser partsbond, commencez votre essai gratuit",
    "freeTrial": "Essai gratuit",
    "helpVideos": "Aide vidéo",
    "copyright": "Copyright@2020 Autobond Inc. Tous droits réservés",
    "api": "API",
    "app": "L 'app",
    "about": "À propos de nous",
    "contact": "Contactez - nous",
    "and": "Et",
    "privacy": "Confidentialité et conditions",
    "feedback": "Feedback",
    "writeOpinion": "Écrivez votre avis...",
    "sentUsEmail": "Adresse mail: aaronw@autobondinc.com",
    "sentUs": "Contactez - nous",
    "iosDownload": "Télécharger pour iOS",
    "androidDownload": "Télécharger pour Android",
    "inputPNMR": "Veuillez entrer le numéro de pièce / nom / modèle / remarque",
    "view3D": "Cliquez pour voir le modèle 3D",
    "again": "Encore une fois",
    "pictures": "Images",
    "partsNumber": "Numéro de pièce",
    "partsName": "Nom de la pièce",
    "priceRange": "Fourchette de prix",
    "operation": "Opérations",
    "email": "Boîte aux lettres",
    "vCode": "Code de vérification",
    "nPassword": "Nouveau mot de passe",
    "cNPassword": "Déterminer un nouveau mot de passe",
    "submit": "Soumettre",
    "getCode": "Obtenir le Code de vérification",
    "countdown": "{num}s",
    "inputEmail": "Veuillez entrer votre boîte mail",
    "emailCorrectly": "Veuillez remplir votre message correctement",
    "inputPassword": "Veuillez entrer votre mot de passe",
    "passwordsInconsistent": "Les deux mots de passe saisis sont incohérents",
    "sendCodeSuccess": "Envoyé avec succès",
    "agreeUserAgreement": "Veuillez accepter le contrat d'utilisateur",
    "tryFree": "Pièces d'essai gratuites",
    "tryFreeDay": "Une fois connecté, cliquez sur essai gratuit pour un essai de 7 jours",
    "registrationCodeSentSuccess": "Code d'enregistrement envoyé avec succès! Si vous ne recevez pas le code dans 1 - 2 minutes, essayez de rafraîchir votre boîte de réception et de vérifier votre dossier spam.",
    "findUs": "Comment nous avez - vous trouvés",
    "iAgree": "Je suis d'accord",
    "userAgreement": "Accord utilisateur",
    "brand": "La marque",
    "sampleSearch": "Exemple de recherche",
    "passwordChangedSuccess": "Changement de mot de passe réussi",
    "passwordChangedFailed": "La modification du mot de passe a échoué",
    "enterNewName": "Veuillez entrer un nouveau nom d'utilisateur",
    "userChangedSuccess": "Modification réussie par l'utilisateur",
    "userChangedFailed": "La modification des informations utilisateur a échoué",
    "fillName": "Veuillez d'abord remplir le nom d'utilisateur",
    "vinBindingSuccee": "Fixation de numéro de cadre réussie",
    "success": "Succès",
    "brandPackage": "Paquet de demande de marque de voiture",
    "combo": "Combinaison",
    "validTime": "Temps de validité",
    "cancelRenewal": "Annuler la mise à jour",
    "packageContent": "Paquet",
    "bindFrame": "Lier 1 numéro de plus",
    "remainingQueries": "Nombre de requêtes restantes",
    "personalInformation": "Informations personnelles",
    "loginAccount": "Connexion au compte",
    "nickName": "Alias",
    "modify": "Modifier",
    "bindingEmail": "Lier une boîte aux lettres",
    "loginPassword": "Login mot de passe",
    "enterContent": "Entrez le contenu",
    "noCoupons": "Pas de coupons",
    "discountOperation": "Opération discount",
    "preferentialContent": "Contenu de l'offre",
    "serviceLife": "Durée de vie utile",
    "user": "Utilisateurs",
    "remarks": "Remarques",
    "noPartsCollection": "Pas de pièces",
    "selectAll": "Sélectionner tout",
    "delete": "Supprimer",
    "vin": "Numéro de cadre",
    "oe": "Oe",
    "detail": "Détails",
    "noNews": "Pas de message",
    "feedbackDetails": "Feedback information",
    "feedbackStatus": "Statut du feedback",
    "feedbackTime": "Temps de feedback",
    "questionType": "Types de problèmes",
    "problemDescription": "Description du problème",
    "solved": "Résolu",
    "pending": "Attendre",
    "supplementaryQuestion": "Questions complémentaires",
    "endFeedback": "Fin du feedback",
    "cancel": "Annulation",
    "invalidCoupon": "Ce code de réduction n'a pas été trouvé",
    "bindVin": "Fixations {num} numéro de cadre",
    "annualSubscribe": "Abonnement annuel",
    "currencySymbol": "$",
    "enterNumber": "Veuillez saisir un seul numéro à son tour",
    "boundRemaining": "{hasbound} / {remainingbound} fixation du numéro de cadre [{has} / {remaining} reste la fixation du numéro de cadre]",
    "boundDescribe1": "Plusieurs abonnements peuvent être appliqués à un seul compte",
    "boundDescribe2": "Abonnement valable 1 année civile (à partir de la date d'achat)",
    "boundDescribe3": "L'utilisateur enregistré est lié de manière permanente à l'utilisateur enregistré; Si vous souhaitez lier votre vin (s) plus tard, vous pouvez le faire dans les détails de votre compte.",
    "allInOne": "Tout - en - un",
    "asiaPack": "Asie Pack",
    "euroPack": "Euro Pack",
    "usPack": "Le pack américain",
    "daySubscription": "Abonnement par jour",
    "monthSubscription": "Abonnement mensuel",
    "annualSubscription": "Abonnement annuel",
    "use": "Utilisation",
    "howGetCode": "Comment obtenir un code de réduction",
    "payPalWith": "Paypal avec",
    "openingPermission": "Droits ouverts",
    "availablePackages": "Forfaits disponibles",
    "bestMatch": "Le meilleur match",
    "priceLowHigh": "Prix bas à élevé",
    "priceHighLow": "Prix de haut en bas",
    "pleaseLoginFirst": "Veuillez vous connecter en premier",
    "here3DArea": "Voici la Zone 3D",
    "applicableVehicles": "Modèles de véhicules applicables",
    "applicableVehicle": "Convient pour les voitures",
    "interchangeableParts": "Pièces de remplacement",
    "condition": "Conditions",
    "all": "Tous",
    "channel": "Canaux",
    "location": "Localisation",
    "partsFound": "Une pièce",
    "noData": "Aucune donnée",
    "carsModels": "Modèle de voiture (années)",
    "year": "Année",
    "market": "Le marché",
    "oeNumber": "Numéro oe",
    "partName": "Nom de la pièce",
    "count": "Nombre",
    "partsPrice": "Prix de comparaison des pièces",
    "directReplacement": "Remplacement direct",
    "indirectReplacement": "Substitution indirecte",
    "priceTrend": "Tendances des prix",
    "partPicture": "Images de pièces",
    "brands": "La marque",
    "replacement": "Remplacement",
    "applicableVeh": "Appliquer veh",
    "supplier": "Fournisseurs",
    "getIt": "Obtenir",
    "go": "Le go",
    "findResults": "Trouvez des résultats sur {num} pour vous",
    "partOe": "Oe",
    "mainGroup": "Groupe principal",
    "subGroup": "Sous - maître",
    "group": "Groupe",
    "configDetails": "Détails de configuration",
    "selectGroup": "Choisir le maître",
    "conflg": "Configuration",
    "picture": "Images",
    "mg": "Le mg",
    "sg": "Le SG",
    "illNo": "Ill - no",
    "name": "Le nom",
    "remark": "Remarques",
    "model": "Modèle de véhicule",
    "selectConfigurationInformation": "Sélectionnez les informations de configuration",
    "groupOne": "Le premier groupe!",
    "lastGroup": "Le dernier groupe!",
    "list": "Liste",
    "back": "Retour",
    "switchGroup": "Interrupteurs",
    "notFilter": "Ne pas filtrer",
    "filter": "Filtres",
    "config": "Configuration",
    "pos": "Pos",
    "qty": "Qty",
    "addWatch": "Rejoindre la liste des collections",
    "brandPart": "Pièces de marque",
    "component": "Composants",
    "disclaimer": "Déclaration: les résultats de l'enquête sont pour référence seulement.",
    "partDetail": "Détails des pièces",
    "vinNotAvailable": "Le Code d'identification du véhicule n'est pas disponible",
    "partNotAvailable": "Numéro de pièce non disponible",
    "lackModels": "Manque de modèles",
    "dataNotComplete": "Données incomplètes",
    "dataError": "Erreur de données",
    "dataNotNew": "Les données ne sont pas assez nouvelles",
    "slowSpeed": "Vitesse lente",
    "noUniqueness": "Pas le seul",
    "other": "Autres",
    "selectQuestion": "Choisir une question",
    "close": "Fermé",
    "howVin": "Comment voir le numéro de rack?",
    "howVin1": "Trouver le numéro de châssis, enregistrer le Code d'identification du véhicule sur la vitre avant de la carrosserie",
    "howVin2": "Entrez le numéro de boîte ou appuyez sur télécharger une image, identifiez le numéro de boîte",
    "howVin3": "Cliquez sur l'icône de requête pour interroger le numéro de la boîte",
    "howVin4": "Entrez le nom de la marque, cliquez sur l'icône de requête pour effectuer la requête",
    "findParts": "Comment trouver des pièces?",
    "findParts1": "Passez la souris sur le numéro de la cage de stationnement pour voir les informations de configuration de base",
    "findParts2": "Cliquez sur cette icône pour interroger les pièces de carrosserie, les pièces de châssis, les pièces de moteur avec le modèle 3D",
    "findParts3": "Sélectionnez le groupe principal et le Groupe aller à la liste des pièces",
    "findParts4": "Cliquez sur, sélectionnez à nouveau le groupe principal et la requête de numéro de boîte",
    "findParts5": "Cliquez sur le numéro de pièce de la vue éclatée pour sélectionner la pièce",
    "howPart": "Comment utiliser la demande de numéro de pièce?",
    "howPart1": "Entrez ou Téléchargez l'image identifier le numéro de pièce, cliquez sur rechercher pour demander",
    "howPart2": "Cliquez sur l'icône pour collecter des pièces dans ma collection et vous recevrez une notification par courrier lorsque le prix d'une pièce change",
    "howPart3": "Cliquez sur l'icône pour voir les dernières tendances de prix pour cette pièce",
    "noHelp": "Pas besoin d'aide",
    "understood": "Savoir.",
    "nextStep": "Prochaines étapes",
    "clickCheckInvitation": "Cliquez ici pour le statut de l'invitation",
    "inviteFriendsGetPackage": "Invitez 10 amis à recevoir un forfait annuel d’une valeur de 540 $!",
    "emailAddress": "Adresse e - mail",
    "status": "Statut",
    "redeem": "Échange",
    "inviteRewardTips": "< p > Veuillez confirmer l'application < br / > < em > All - in - one annual purchase < / em > Rewards < / p > < p > Si vous avez un forfait actuellement valide, votre récompense sera automatiquement échangée à l'expiration",
    "currentSubscriptionExpires": "La date d'expiration de votre forfait actuel est",
    "confirm": "Confirmation",
    "putAway": "Le put away",
    "seeAll": "Voir tout",
    "pageCannotEmpty": "Le nombre de pages ne peut pas être vide",
    "pleaseRegisterSignin": "Veuillez vous inscrire / vous connecter pour rechercher plus de données sur le véhicule",
    "price": "Prix",
    "quantity": "Quantité",
    "pleaseRL": "Veuillez vous connecter après votre inscription",
    "sign": "Se connecter",
    "stay": "Restez connecté",
    "forget": "Mot de passe oublié",
    "password": "Mot de passe",
    "RFFVS": "Inscrivez - vous pour rechercher gratuitement vin",
    "cName": "Nom de l'entreprise",
    "basic": "Informations de base",
    "updateTime": "Temps de mise à jour",
    'OfficialStore': 'La Boutique officielle',
    "english": "English",
    "russian": "русск",
    'indonesian': 'Indonesia',
    'vietnamese': 'Tiếng Việt',
    'thai': 'คนไทย',
    'malaysia': 'Malaysia',
    'arabia': 'بالعربية',
    'spain': 'Espagne',
    'portugal': 'Portugal',
    'france': 'France',
    'Turkey': 'Türkçe',
    'korean': '한국어',
    'japanese': '日本語'
  }
}

export default fr