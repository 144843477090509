const vi = {
  locale: 'vi-VN',
  messages: {
    "inputVinBrand": "Vui lòng nhập số VIN hoặc số bộ phận hoặc nhãn hiệu",
    "history": "Lịch sử",
    "home": "Trang chủ",
    "catalog": "Thư mục",
    "search": "Tìm kiếm",
    "dragFile": "Dán hoặc kéo tập tin vào khu vực này để nhận dạng",
    "selectImage": "Chọn ảnh",
    "howWorks": "Xem cách nó hoạt động",
    "more": "Thêm",
    "helpSection": "Phần Trợ giúp",
    "freeTrialDay": "Dùng thử miễn phí 3 ngày",
    "account": "Tài khoản",
    "purchase": "Mua hàng",
    "message": "Thông điệp",
    "favorites": "Yêu thích",
    "feedbackMessage": "Thông tin phản hồi",
    "watch": "Danh sách theo dõi",
    "coupon": "Phiếu giảm giá",
    "signIn": "Đăng nhập",
    "signOut": "Thoát",
    "register": "Đăng ký",
    "subscribe": "Đăng ký",
    "freeSearch": "Tìm kiếm miễn phí có sẵn",
    "inviteTips": "Nhấp vào nút để mời 10 người bạn nhận thẻ hàng năm miễn phí",
    "invite": "Thư mời",
    "freeTrialTips": "Ôi không! Có vẻ như bạn không còn tìm kiếm miễn phí nữa! Để tiếp tục sử dụng Partsbond, hãy bắt đầu dùng thử miễn phí",
    "freeTrial": "Dùng thử miễn phí",
    "helpVideos": "Trợ giúp video",
    "copyright": "Copyright@2020 Bản quyền thuộc về Công ty Cổ phần AutoBond",
    "api": "API",
    "app": "Ứng dụng",
    "about": "Về chúng tôi",
    "contact": "Liên hệ",
    "and": "Và",
    "privacy": "Quyền riêng tư&Điều khoản",
    "feedback": "Hãy cho chúng tôi biết bạn nghĩ gì",
    "writeOpinion": "Viết ý kiến của bạn...",
    "sentUsEmail": "Gửi cho chúng tôi: aaronw@autobondinc.com",
    "sentUs": "Gửi cho chúng tôi",
    "iosDownload": "Tải về iOS",
    "androidDownload": "Tải về Android",
    "inputPNMR": "Vui lòng nhập Pid/Tên/Model/Ghi chú",
    "view3D": "Bấm để xem mô hình 3D",
    "again": "Một lần nữa",
    "pictures": "Hình ảnh",
    "partsNumber": "Phần số",
    "partsName": "Tên phần",
    "priceRange": "Phạm vi giá",
    "operation": "Hoạt động",
    "email": "Thư điện tử",
    "vCode": "Mã xác nhận",
    "nPassword": "Mật khẩu mới",
    "cNPassword": "Xác nhận mật khẩu mới",
    "submit": "Giới thiệu",
    "getCode": "Nhận mã",
    "countdown": "{num}s Một lần nữa",
    "inputEmail": "Vui lòng nhập email của bạn",
    "emailCorrectly": "Vui lòng điền email chính xác",
    "inputPassword": "Vui lòng nhập mật khẩu",
    "passwordsInconsistent": "Hai mật khẩu bạn nhập không khớp nhau",
    "sendCodeSuccess": "Gửi mã thành công",
    "agreeUserAgreement": "Vui lòng đồng ý với Thỏa thuận người dùng Partsbond",
    "tryFree": "Dùng thử partsbond miễn phí",
    "tryFreeDay": "Sau khi đăng nhập, nhấp vào bản dùng thử miễn phí để thêm bản dùng thử 7 ngày khác",
    "registrationCodeSentSuccess": "Mã đăng ký đã được gửi thành công! Nếu bạn không nhận được mã trong vòng 1-2 phút, hãy thử làm mới hộp thư đến của bạn và kiểm tra thư mục spam.",
    "findUs": "Làm thế nào bạn tìm thấy chúng tôi",
    "iAgree": "Tôi đồng ý",
    "userAgreement": "Thỏa thuận người dùng Partsbond",
    "brand": "Thương hiệu",
    "sampleSearch": "Tìm kiếm mẫu",
    "passwordChangedSuccess": "Thay đổi mật khẩu thành công",
    "passwordChangedFailed": "Thay đổi mật khẩu không thành công",
    "enterNewName": "Vui lòng nhập tên người dùng mới",
    "userChangedSuccess": "Thay đổi người dùng thành công",
    "userChangedFailed": "Sửa đổi người dùng không thành công",
    "fillName": "Vui lòng điền tên người dùng trước",
    "vinBindingSuccee": "Vin Binding thành công",
    "success": "Thành công",
    "brandPackage": "Gói yêu cầu thương hiệu ô tô",
    "combo": "Liên minh",
    "validTime": "Thời gian hiệu lực",
    "cancelRenewal": "Hủy gia hạn",
    "packageContent": "Nội dung đóng gói",
    "bindFrame": "Gắn thêm 1 frame",
    "remainingQueries": "Số lượng truy vấn còn lại",
    "personalInformation": "Thông tin cá nhân",
    "loginAccount": "Đăng nhập tài khoản",
    "nickName": "Nick Name",
    "modify": "Sửa đổi",
    "bindingEmail": "Email liên kết",
    "loginPassword": "Mật khẩu đăng nhập",
    "enterContent": "Nhập nội dung",
    "noCoupons": "Không Coupon",
    "discountOperation": "Hoạt động giảm giá",
    "preferentialContent": "Nội dung ưu đãi",
    "serviceLife": "Tuổi thọ",
    "user": "Người dùng",
    "remarks": "Bình luận",
    "noPartsCollection": "Không thu thập các bộ phận",
    "selectAll": "Chọn tất cả",
    "delete": "Xóa",
    "vin": "VIN",
    "oe": "OE",
    "detail": "Chi tiết",
    "noNews": "Không có tin nhắn",
    "feedbackDetails": "Thông tin phản hồi",
    "feedbackStatus": "Trạng thái phản hồi",
    "feedbackTime": "Thời gian phản hồi",
    "questionType": "Loại câu hỏi",
    "problemDescription": "Mô tả vấn đề",
    "solved": "Đã giải quyết",
    "pending": "Đang chờ xử lý",
    "supplementaryQuestion": "Chất vấn bổ sung",
    "endFeedback": "Kết thúc Feedback",
    "cancel": "Hủy bỏ",
    "invalidCoupon": "Tôi không tìm thấy mã giảm giá này",
    "bindVin": "Liên kết {num}VIN",
    "annualSubscribe": "Subscribe hàng năm",
    "currencySymbol": "$",
    "enterNumber": "Hãy nhập lần lượt từng khung",
    "boundRemaining": "｛hasBound｝/｛remainingBound｝VIN BOUND〔｛has｝／｛remain｝VIN BINDS remaining〕",
    "boundDescribe1": "Nhiều đăng ký có thể được áp dụng cho một tài khoản",
    "boundDescribe2": "Đăng ký có giá trị trong 1 năm dương lịch (kể từ ngày mua)",
    "boundDescribe3": "Binary hóa số VIN thành đăng ký là vĩnh viễn; Nếu bạn muốn liên kết VIN sau này, bạn có thể liên kết nó trong chi tiết tài khoản của mình",
    "allInOne": "Tích hợp",
    "asiaPack": "Ba lô châu Á",
    "euroPack": "Gói Euro",
    "usPack": "Mỹ đóng gói",
    "daySubscription": "Đăng ký ngày",
    "monthSubscription": "Đăng ký hàng tháng",
    "annualSubscription": "Subscribe hàng năm",
    "use": "Sử dụng",
    "howGetCode": "Làm thế nào để có được mã giảm giá",
    "payPalWith": "PayPal",
    "openingPermission": "Mở quyền",
    "availablePackages": "Các gói có sẵn",
    "bestMatch": "Trận đấu tốt nhất",
    "priceLowHigh": "Giá từ thấp đến cao",
    "priceHighLow": "Giá từ cao xuống thấp",
    "pleaseLoginFirst": "Vui lòng đăng nhập trước",
    "here3DArea": "Đây là khu vực 3D",
    "applicableVehicles": "Xe áp dụng",
    "applicableVehicle": "Xe áp dụng",
    "interchangeableParts": "Các bộ phận hoán đổi cho nhau",
    "condition": "Điều kiện",
    "all": "Tất cả",
    "channel": "Kênh",
    "location": "Địa điểm",
    "partsFound": "Phần tìm thấy",
    "noData": "Không có dữ liệu",
    "carsModels": "Mô hình xe hơi",
    "year": "Năm",
    "market": "Trang chủ",
    "oeNumber": "Số OE",
    "partName": "Tên thành phần",
    "count": "Đếm",
    "partsPrice": "Giá phần",
    "directReplacement": "Thay thế trực tiếp",
    "indirectReplacement": "Thay thế gián tiếp",
    "priceTrend": "Xu hướng giá",
    "partPicture": "Hình ảnh thành phần",
    "brands": "Thương hiệu",
    "replacement": "Thay thế",
    "applicableVeh": "Xe áp dụng",
    "supplier": "Nhà cung cấp",
    "getIt": "Hiểu rồi.",
    "go": "Đi",
    "findResults": "Tìm kết quả cho bạn về {num}",
    "partOe": "Phần OE",
    "mainGroup": "Nhóm chính",
    "subGroup": "Nhóm con",
    "group": "Nhóm",
    "configDetails": "Chi tiết cấu hình",
    "selectGroup": "Chọn nhóm",
    "conflg": "Conflg",
    "picture": "Hình ảnh",
    "mg": "Mg",
    "sg": "SG",
    "illNo": "ILL-NO",
    "name": "Tên",
    "remark": "Bình luận",
    "model": "Mô hình",
    "selectConfigurationInformation": "Chọn thông tin cấu hình",
    "groupOne": "Chúng tôi ở nhóm 1!",
    "lastGroup": "Đây là nhóm cuối cùng!",
    "list": "Danh sách",
    "back": "<Trở về",
    "switchGroup": "Đơn vị trao đổi",
    "notFilter": "Không lọc",
    "filter": "Bộ lọc",
    "config": "Config",
    "pos": "Địa điểm",
    "qty": "Số lượng",
    "addWatch": "Thêm vào danh sách theo dõi",
    "brandPart": "Phần thương hiệu",
    "component": "Thành phần",
    "disclaimer": "Disclaimer: Kết quả truy vấn chỉ dành cho mục đích thông tin.",
    "partDetail": "Chi tiết phần",
    "vinNotAvailable": "Số VIN không có sẵn",
    "partNotAvailable": "Phần số Không có sẵn",
    "lackModels": "Thiếu model",
    "dataNotComplete": "Dữ liệu không đầy đủ",
    "dataError": "Lỗi dữ liệu",
    "dataNotNew": "Dữ liệu không đủ mới",
    "slowSpeed": "chậm",
    "noUniqueness": "Không có duy nhất",
    "other": "Thêm",
    "selectQuestion": "Chọn câu hỏi",
    "close": "Tắt",
    "howVin": "Làm thế nào để kiểm tra số khung hình?",
    "howVin1": "Tìm số khung và ghi lại số VIN trên kính cửa sổ phía trước của khối lượng xe",
    "howVin2": "Nhập số khung hình hoặc nhấp vào Tải lên hình ảnh để xác định số khung hình",
    "howVin3": "Nhấp vào biểu tượng truy vấn để truy vấn số khung",
    "howVin4": "Nhập tên thương hiệu, nhấp vào biểu tượng truy vấn để truy vấn",
    "findParts": "Làm thế nào để tìm các bộ phận?",
    "findParts1": "Di chuột qua số giá đỗ xe để xem thông tin cấu hình cơ bản",
    "findParts2": "Nhấp vào biểu tượng này để truy vấn các bộ phận cơ thể, bộ phận khung gầm và bộ phận động cơ bằng mô hình 3D",
    "findParts3": "Chọn nhóm chính và nhóm để vào danh sách phần",
    "findParts4": "Nhấp để chọn lại gói chính và truy vấn số khung",
    "findParts5": "Nhấp vào số phần của chế độ xem phá vỡ để chọn một phần",
    "howPart": "Làm thế nào để sử dụng một phần số truy vấn?",
    "howPart1": "Nhập hoặc tải lên hình ảnh để xác định số phần, nhấp vào Tìm kiếm để truy vấn",
    "howPart2": "Nhấp vào biểu tượng để thu thập các bộ phận vào bộ sưu tập của tôi và bạn sẽ nhận được thông báo qua email khi giá của các bộ phận thay đổi",
    "howPart3": "Nhấp vào biểu tượng để xem xu hướng giá mới nhất cho các bộ phận",
    "noHelp": "Không cần giúp đỡ",
    "understood": "Hiểu rồi.",
    "nextStep": "Tiếp theo",
    "clickCheckInvitation": "Nhấn vào đây để xem trạng thái thư mời",
    "inviteFriendsGetPackage": "Mời 10 người bạn tham gia đăng ký hàng năm của chúng tôi! Nó đáng giá 540 đô la!",
    "emailAddress": "Địa chỉ email",
    "status": "Địa vị",
    "redeem": "Giải cứu",
    "inviteRewardTips": "<p>Vui lòng xác nhận áp dụng phần thưởng của bạn<br/><em>All-in-One Annual Subscription</em></em></em> <p>Nếu bạn có đăng ký hiện đang hoạt động, phần thưởng của bạn sẽ tự động được đổi khi hết hạn.</p> </p>",
    "currentSubscriptionExpires": "Thời gian hết hạn đăng ký hiện tại của bạn là",
    "confirm": "Xác nhận",
    "putAway": "Thu gọn",
    "seeAll": "Xem tất cả",
    "pageCannotEmpty": "Số trang không được để trống",
    "pleaseRegisterSignin": "Vui lòng đăng ký/đăng nhập để tìm thêm dữ liệu xe",
    "price": "Giá cả",
    "quantity": "Số lượng",
    "pleaseRL": "Vui lòng đăng ký/Đăng nhập",
    "sign": "Đăng nhập",
    "stay": "Đăng nhập",
    "forget": "Quên mật khẩu",
    "password": "Mật ngữ",
    "RFFVS": "Đăng ký tìm kiếm VIN miễn phí",
    "cName": "Tên công ty",
    "basic": "Cơ bản",
    "updateTime": "Thời gian cập nhật",
    'OfficialStore': 'Cửa hàng chính thức',
    "english": "English",
    "russian": "русск",
    'indonesian': 'Indonesia',
    'vietnamese': 'Tiếng Việt',
    'thai': 'คนไทย',
    'malaysia': 'Malaysia',
    'arabia': 'بالعربية',
    'spain': 'Espagne',
    'portugal': 'Portugal',
    'france': 'France',
    'Turkey': 'Türkçe',
    'korean': '한국어',
    'japanese': '日本語'
  }
}

export default vi