import React, { useState, useRef, useEffect, useContext, Fragment } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import Tooltip from 'rc-tooltip'
import MainContext from '@/context/MainContext'
import Upload from '@/components/Upload'
import Toast from '@/components/Toast'
import Loading from '@/components/Loading'
import Modal from '@/components/Modal'
import HelpVideo from '@/components/HelpVideo'
import catalogApi from '@/api/catalogApi'
import searchApi from '@/api/searchApi'
import accountApi from '@/api/account'
import vinApi from '@/api/vinApi'
import commonApi from '@/api/commonApi'


import { getUrlParams } from '@/utils/utils'
import { Header, Footer } from '@/page/Main'
import { cdnsPath } from '@/utils/utils'
import logoImg from '@/assets/images/maxlogo.png'
import video_1 from '@/assets/images/video_1.png'
import video_2 from '@/assets/images/video_2.png'
import video_3 from '@/assets/images/video_3.png'
import icon_new from '@/assets/images/icon_new.png'
import arrow from '@/assets/images/arrow.png'
import css from './home.module.scss'
import { getOffScreenContext } from '@antv/g-base'

interface SelectIn {
  type: string, 
  value: string,
  brand?: string,
  code?: string,
  img?: string,
  name: string,
  show_name?: string
}

const hot = [
  {
    name: 'Example VIN',
    id: 'vin'
  },
  {
    name: 'Example OE',
    id: 'oe'
  }
]

let refused = false

let update = 1

let Home: React.FC = () => {
  // const guidecache = JSON.parse(localStorage.getItem('guidecache') || '{}')
  const intl = useIntl()
  const headerRef: any = useRef()
  const { code, buy, vinback } = getUrlParams()
  const { store, setStore } = useContext(MainContext)
  const { userInfo } = store
  const [activeSearchType, setActiveSearchType] = useState<string>('vin')
  const [searchKey, setSearchKey] = useState<string>('')
  const [searchVin, setSearchVin] = useState<string>('')
  const [searchPart, setSearchPart] = useState<string>('')
  
  const [data, setData] = useState<any>({})
  const [searchData, setSearchData] = useState<any>([])
  const [vinHistory, setVinHistory] = useState<any>([])
  const [partsHistory, setPartsHistory] = useState<any>([])
  
  const [historyVisible, setHistoryVisible] = useState<boolean>(false)
  const [tips, setTips] = useState<string>('')
  const searchRef = useRef<HTMLInputElement>(null)
  const history = useHistory()

  const [ocrVisible, setOcrVisible] = useState<boolean>(false)
  const [uploadLoading, setUploadLoading] = useState<boolean>(false)
  const [ocrImg, setOcrImg] = useState<string>('')
  const [ocrKey, setOcrKey] = useState<string>('')

  const [hotData, setHotData] = useState<any>([])
  const [hotType, setHotType] = useState<string>('vin')
  const [hotLoading, setHotLoading] = useState<boolean>(false)

  

  const [recommend, setRecommend] = useState<any>([])
  
  const [catalogLoading, setCatalogLoading] = useState<boolean>(false)
  const [catalogTitle, setCatalogTitle] = useState<any>({
    path: '',
    title: []
  })
  const [catalogData, setCatalogData] = useState<any>([])

  const buycode = window.sessionStorage.getItem('buycode')
  const [tipsVisible, setTipsVisible] = useState<boolean>(localStorage.getItem('homeTips') ? false : true)

  const [ loading, setLoading ] = useState<boolean>(false)


  const [fast, setFast] = useState<any>([])

  const [visible, setVisible] = useState<boolean>(true)
  const [trialLoading, setTrialLoading] = useState<boolean>(false)

  const [vedioVisible, setVedioVisible] = useState<boolean>(false)
  const [ vedioIndex, setVedioIndex ] = useState<number>(0)


  const [ inviteAdVisible, setInviteAdVisible ] = useState<boolean>(window.localStorage.getItem('inviteAdVisible') === '1' ? false : true)
  
  const [ inviteTips, setInviteTips ] = useState<boolean>(false)

  const [ selectConfigVisible, setSelectConfigVisible ] = useState<boolean>(false)
  const [ selectConfigData, setSelectConfigData ] = useState<any>({})
  
  const searchType = [
    {
      title: 'VIN/Part',
      key: 'vin'
    },
    {
      title: 'Catalog',
      key: 'catalog'
    },
    // {
    //   title: 'PartsPrice',
    //   key: 'part'
    // }
  ]
  useEffect(()=>{
    // cars()
    // getHistory()
    // setStore({value: [], type: 'crumb'})
    getbrand()
    getRecommend()
    
    if(code){
      statistical()
    }
    if(buy || buycode){
      window.sessionStorage.setItem('buycode', buy || buycode)
      setStore({value: true, type: 'purchaseVisible'})
    }
    if(vinback){
      record({
        where: 'home',
        what: {
          type: 'vinback',
          value: vinback
        }
      })
    }
    return () => {
      update = update + 1
    }
  },[])
  useEffect(()=>{
    if(searchData.length && searchData[0].type !== 'logo'){
      cars()
    }
  },[searchData])

  useEffect(()=>{
    getHistory()
    // if(historyVisible && userInfo.userid){
    //   getHistory()
    //   // setPartsHistory([])
    // }
  },[historyVisible])

  useEffect(()=>{
    if(!inviteAdVisible && userInfo.userid){
      window.localStorage.setItem('inviteAdVisible', '1')
      setInviteTips(true)
    }
  }, [inviteAdVisible])

  useEffect(()=>{
    if(!selectConfigVisible && selectConfigData.key){
      search(selectConfigData.key)
    }
  }, [selectConfigVisible])
  

  const statistical = () => {
    accountApi.statistical({code}).then()
  }

  const cars = () => {
    setSearchVin('')
    if(refused){
      return
    }
    let activeItem = searchData[searchData.length - 1]
    let param: any = {
      brandCode: searchData[0].brandcode
    }
    if(activeItem.has_child || searchData.length === 1){
      if(searchData.length === 1){
        param.level = 0
      }else {
        param.level = activeItem.level
        param.name = activeItem.name
        param.full_condition_path = activeItem.full_condition_path
      }
      setCatalogLoading(true)
      catalogApi.models(param).then((result: any)=>{
        setCatalogLoading(false)
        if(result.code === 1){
          let temp = { ...catalogTitle }
          let tempcl = [...catalogData]

          temp.title.push(result.title)
          tempcl.push(result.data)

          setCatalogData(tempcl)
          setCatalogTitle(temp)
        }
      })
    }else {
      let tempCrumb: any = [...searchData]
      tempCrumb.unshift({
        type: 'logo',
        img: catalogTitle.path + tempCrumb[0].img
      })
      delete tempCrumb[1].type
      setStore({value: tempCrumb, type: 'crumb'})
      window.sessionStorage.setItem('crumb', JSON.stringify(tempCrumb))
      history.push(`/group?mcid=${activeItem.mcid.replace(/\=/g, '%3D')}&brandcode=${activeItem.brandCode}&num=0`)
    }
    // if(val || searchData.length){
    //   param.kw = val
    //   searchData.forEach((item: SelectIn)=>{
    //     param[item.type] = item.value
    //   })
    // }
    // searchApi.cars(param).then((result: {code: number, data: object})=>{
    //   if(result.code === 1 && result.data){
    //     setData(result.data)
    //   }else {
    //     setData({})
    //   }
    // })
    
    
  }
  const getbrand = () => {
    catalogApi.brands().then((result: any)=>{
      if(result.code === 1){
        setCatalogTitle({
          path: result.IMG_DNS,
          title: ['Brand']
        })
        setCatalogData([result.data])
      }
    })
  }
  const setSelect = (index?: number | null, item?: SelectIn) => {
    let param = JSON.parse(JSON.stringify(searchData))
    let temp = { ...catalogTitle }
    let tempcl = [...catalogData]
    if(index !== null && typeof index === 'number'){
      const i = index + 1
      refused = true

      param.splice(index, param.length - index)
      temp.title.splice(i, temp.title.length - i)
      tempcl.splice(i, tempcl.length - i)
      setCatalogData(tempcl)
      setCatalogTitle(temp)

    }else if(item) {

      refused = false
      item.type = 'config'
      param.push(item)

    }
    setSearchData(param)
    // setData({})
    
    if(searchRef.current){
      searchRef.current.focus()
    }

    // if(param.length > 4){
    //   let url: string[] = []
    //   param.forEach((item: {type: string, value: string})=>{
    //     url.push(item.type + '=' + item.value)
    //   })
    //   history.push(`/car?${url.join('&')}`)
    // }
  }
  const search = (vin?: string, validation?: boolean) => {
    let key = vin || searchVin.replace(/\s/g, '')
    // console.info(key)
    if(/^[0-9a-zA-Z]*$/g.test(key) && key){
      if(validation){
        if(key.length > 17){
          setLoading(true)
          vinApi.findVin({vin: key}).then((result: any)=>{
            setLoading(false)
            if(result.code === 1){
              setSelectConfigData({
                config: result,
                key
              })
              setSelectConfigVisible(true)
            }else {
              update = update + 1
              process(key, update)
            }
          })
        }else {
          update = update + 1
          process(key, update)
        }
        
      }else {
        let url = key.length === 17 ? `/vin?vin=${key}` : `/partsprice?pid=${key}`
        history.push(url)
      }

      
    }
    // if(/^[0-9a-zA-Z]*$/g.test(key) && key){
      
    //   if(key.length === 17){
    //     url = ''
    //     if(validation){
    //       update = update + 1
    //       process(key, update)
    //     }else {
    //       url = `/vin?vin=${key}`
    //     }
        
    //   }else {
    //     url = `/partsprice?pid=${key}`
    //   }
    //   console.info(url)
    // }
    // if(url){
    //   history.push(url)
    // }


    // if(activeSearchType === 'vin'){
    //   let key = vin || searchVin.replace(/\s/g, '')
    //   if(/^[0-9a-zA-Z]*$/g.test(key)){
    //     if(key.length === 17){
    //       history.push(`/vin?vin=${key}`)
    //     }else {
    //       setTips('Please input vin')
    //     }
    //   }
    // }else {
    //   if(!searchKey){
    //     if(!searchData.length){
    //       setTips('Please input vin/oe/catalog/parts')
    //     }else {
    //       const tip = ['brand', 'model', 'year', 'displacements']
    //       let ti = ''
    //       tip.forEach((t)=>{
    //         let is = true
    //         searchData.forEach((item: {type: string})=>{
    //           if(item.type === t && !ti){
    //             is = false
    //           }
    //         })
    //         if(is && !ti){
    //           ti = t
    //         }
    //       })
    //       setTips(ti)
    //       for(let i in searchData){
    //         let item = searchData[i]
    //         if(item.type !== 'code' && tip.indexOf(item.type) === -1){
    //           setTips('Please input' + item.type)
    //         }
    //       }
    //     }
    //   }else {
    //     let key = searchKey.replace(/\s/g, '')
    //     if(/^[0-9a-zA-Z]*$/g.test(key)){
    //       if(key.length === 17){
    //         history.push(`/vin?vin=${key}`)
    //       }else {
    //         history.push(`/partsprice?pid=${key}`)
    //       }
    //     }
    //   }
    // }
    
  }
  const delSelectData = (e: any) => {
    if(e.keyCode === 13){
      search()
    }
    // if(e.keyCode === 8 && !e.target.value && searchData.length)(
    //   setSelect(searchData.length - 1)
    // )
  }
  const changeSearchVin = (val: string) => {
    setTips('')
    setSearchVin(val)
  }
  const changeSearchPart = (val: string) => {
    setTips('')
    setSearchPart(val)
  }
  const getHistory = () => {
    if(!searchVin){
      accountApi.history({brandCode: 'all'}).then((result: any)=>{
        if(result.code === 1){
          setVinHistory(result.data)
        }
      })
    }
    
    // if(activeSearchType === 'vin'){
    //   accountApi.vinHistory().then((result: any)=>{
    //     if(result.code === 1){
    //       setVinHistory(result.data)
    //     }
    //   })
    // }else {
    //   accountApi.partsHistory().then((result: any)=>{
    //     if(result.code === 1){
    //       setPartsHistory(result.data)
    //     }
    //   })
    // }
  }

  const switchSearch = (val: string) => {
    if(val === 'catalog'){
      history.push(`/catalog`)
    }else {
      setActiveSearchType(val)
    }
  }

  const beforeUpload = (file: File, FileList: File[]) => {
    return new Promise((resolve, reject) => {
      resolve(file)
    })
  }
  const start = (result: any) => {
    setUploadLoading(true)
  }
  const success = (result: any, file: File) => {
    if(result.code === 1){
      const reader: any = new FileReader()
      reader.readAsDataURL(file)
      reader.addEventListener('load', () => {
        setOcrImg(reader.result)
      })
      setOcrKey(result.data)
    }else {
      Toast('Unrecognized chassis number, please upload the image again', 'warning')
    }
    setUploadLoading(false)
  }
  const error = (result: any) => {
    Toast('error', 'warning')
    setUploadLoading(false)
  }
  
  const getHot = () => {
    let api: any = {}
    if(hotType === 'oe'){
      api = vinApi.recentPid
    }else {
      api = vinApi.recentVin
    }
    setHotLoading(true)
    api().then((result: any)=>{
      setHotLoading(false)
      if(result.code === 1){
        setHotData(result.data)
      }
    })
  }

  const hideTipsVisible = () => {
    setTipsVisible(false)
    window.localStorage.setItem('homeTips', 'true')
  }
  const process = (vin: string, updateOld?: number, count?: number) => {
    if(updateOld !== update){
      return
    }
    let param: any = {
      search_key: vin
    }
    setLoading(true)
    
    searchApi.process(param).then((result: {code: number, data: {}, msg?: string})=>{
      const urlParamsTemp = JSON.stringify(getUrlParams()) || null
      if(updateOld !== update){
        return
      }
      if(!result.data && result.code !== 4001){
        if(result.code !== 205){
          setTips(result.msg || '')
        }
        setLoading(false)
        return
      }
      if(result.code === 1 || result.code === 4005) {
        let url = vin.length === 17 ? `/vin?vin=${vin}` : `/partsprice?pid=${vin}`
        history.push(url)
      }else if(result.code !== 4001) {
        if(result.code !== 205){
          setTips(result.msg || '')
        }
        setLoading(false)
      }
      
      if(result.code === 4001) {
        if(count){
          if(count < 3){
            setTimeout(()=>{
              if(updateOld !== update){
                return
              }
              process(vin, updateOld, count + 1)
            }, 3000)
          }else {
            // setProgressVisible(false)
            setLoading(false)
            setTips(result.msg || '')
            // Toast(result.msg, 'warning')
          }
        }else {
          setTimeout(()=>{
            process(vin, update, 1)
          }, 3000)
        }
        
      }
    })
  }
  const getRecommend = () => {
    accountApi.recommend().then((result: any)=>{
      if(result.code === 1){
        // let fastTemp = []
        // result.data.forEach((item: any)=>{
        //   if(item.key === 'catalog'){
            
        //   }else {
        //     fastTemp.push(item)
        //   }
        // })
        setRecommend(result.data)
      }
    })
  }
  const getTrial = () => {
    setTrialLoading(true)
    accountApi.getTrial().then((result: any)=>{
      setTrialLoading(false)
      if(result.code === 1){
        let temp = JSON.parse(JSON.stringify(userInfo))
        temp.data.active = 0
        setStore({value: temp, type: 'userInfo'})
        setVisible(false)
        Toast('succeeded！')
      }
    })
  }
  const record = (param: any) => {
    commonApi.record(param).then()
    if(param.what.to){
      history.push(param.what.to)
    }
  }
  const goInvite = () => {
    if(userInfo.userid){
      if(headerRef.current){
        headerRef.current.setInviteVisible(true)
      }
    }else {
      setStore({value: true, type: 'loginVisible'})
    }
  }
  return (
    <div className={css['home']}>
      <Header tipsVisible={inviteTips} setTipsVisible={setInviteTips} onRef={headerRef} />
      {
        inviteAdVisible ? (
          <div className={css['banner']}>
            <i className='iconfont icona-16pt1' onClick={()=>setInviteAdVisible(false)} />
            <div onClick={()=>goInvite()}></div>
            {/* <img src={banner} onClick={()=>goInvite()} id="invite" style={{cursor: 'pointer'}} /> */}
          </div>
        ): null
      }
      
      
      <div className={css['logo']}><img src={logoImg} alt="logo" /></div>
      <div className={css['search']}>
        <div className={css['box']}>
            <div className={css['input']}>
              <div className={css['in']}>
                {
                  tips ? (
                    <div className={css['tips']}>
                      <span className={css['down']}><img src={arrow} /></span>
                      <span dangerouslySetInnerHTML={{__html: tips}}></span>
                    </div>
                  ): null
                }
                <input onBlur={()=>setTimeout(()=>{setHistoryVisible(false)}, 300)} onFocus={()=>setHistoryVisible(true)} placeholder={intl.formatMessage({id: 'inputVinBrand'})} value={searchVin} onChange={(e)=>changeSearchVin(e.target.value.toUpperCase())} onKeyDown={(e)=>{e.keyCode === 13 && search('', true)}} />
                
              </div>
              {
                loading ? <span style={{width: 60, height: 20}}><Loading visible={true} type='mini' /></span> : null
              }
              <span className="iconfont iconshibie" style={{color: '#1890FF', fontSize: 24, cursor: 'pointer', marginRight: 20}} onClick={()=>setOcrVisible(!ocrVisible)}></span>
            </div>
            <button onClick={()=>search('', true)}>
              <i className="iconfont iconsousuo" />
            </button>
          {
            ((activeSearchType === 'vin' && vinHistory.length) || (activeSearchType === 'part' && partsHistory.length)) && historyVisible && !searchVin && !searchData.length ? (
              <div className={css.vinHistory}>
                {
                  vinHistory.map((item: any, index: number)=>{
                    return (
                      <div key={index} className={css.item} onClick={()=>search(item.vin || item.pid)}>
                        <span>{item.vin || item.pid}</span>
                        <span>{item.brand_name}</span>
                      </div>
                    )
                  })
                }
                <div className={css.item} onClick={()=>history.push(`/my/history`)}>
                  <span style={{color: '#1890FF'}}>{intl.formatMessage({id: 'history'})} &gt;</span>
                </div>
              </div>
            ): null
          }
          {
            (searchVin || searchData.length) && userInfo.userid && (( !searchVin || catalogData[searchData.length]?.filter((item: SelectIn) => item.name.toUpperCase().indexOf(searchVin.toUpperCase()) !== -1).length) || searchData.length) ? (
              <div className={css['search-content']}>
                <div className={css['h']}>
                  {
                    searchData.length && searchData[0].img ? <img src={catalogTitle.path + searchData[0].img} /> : <h6>{intl.formatMessage({id: 'catalog'})}</h6>
                  }
                  <ul>
                    {
                      searchData.map((item: SelectIn, index: number)=>{
                        return (
                          <Fragment key={index}>
                            {
                              item.name.length > 8 ? (
                                <Tooltip mouseLeaveDelay={0} placement="top" overlay={item.name} overlayStyle={{width: 222}} >
                                  <li className={css['selected']} onClick={()=>setSelect(index)}>{item.show_name? item.show_name.substring(0, 8) : item.name.substring(0, 8)}...</li>
                                </Tooltip>
                              ): (
                                <li onClick={()=>setSelect(index)}>{item.show_name || item.name}</li>
                              )
                            }
                            {searchData.length !== index + 1 ? <li>&gt;</li> : null}
                          </Fragment>
                        )
                      })
                    }
                  </ul>
                  <span className={css['cancel']} onClick={()=>setSelect(0)}><span className="iconfont icondanchuangguanbi"></span></span>
                </div>
                <Loading visible={catalogLoading}>
                  <div className={css['b']}>
                    <div className={css['group']}>
                      <h5>{catalogTitle.title[searchData.length]}</h5>
                      <div className={css['item']}>
                        {
                          catalogData[searchData.length]?.map((item: SelectIn, index: number)=>{
                            return (
                              !searchVin || item.name?.toUpperCase().indexOf(searchVin) !== -1  ? (
                                <span key={index} onClick={()=>setSelect(null, item)} className={searchData.length ? css['special'] : ''}>{item.show_name || item.name}</span>
                              ): null
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                </Loading>
              </div>
            ): null
          }
          {
            ocrVisible && !historyVisible ? (
              <div className={css.ocr}>
                {
                  <i onClick={() => {
                    if(ocrImg) {
                      setOcrImg(""); 
                      setOcrKey("");
                    }else {
                      setOcrVisible(false)
                    }
                  }} className={"iconfont iconclose-circle " + css['del-ocrimg']} />
                }
                {
                  ocrImg ? (
                    <Loading visible={uploadLoading}>
                        <Upload
                          parse={vinApi.ocr}
                          setLoading={setUploadLoading}
                          action={vinApi.ocrPath}
                          name="img"
                          beforeUpload={beforeUpload}
                          start={start}
                          success={success}
                          error={error}
                        >
                          <div className={css.ocrImg}>
                            <img src={ocrImg} />
                          </div>
                        </Upload>
                        <div className={css.uploaded}>
                          <input type="text" value={ocrKey} onChange={(e)=>setOcrKey(e.target.value)} />
                          <button onClick={()=>search(ocrKey, true)}>{intl.formatMessage({id: 'search'})}</button>
                          <Upload
                            action={vinApi.ocrPath}
                            name="img"
                            beforeUpload={beforeUpload}
                            start={start}
                            success={success}
                            error={error}
                          >
                            <p>Again</p>
                          </Upload>
                        </div>
                    </Loading>
                  ): (
                    <Upload
                      parse={vinApi.ocr}
                      setLoading={setUploadLoading}
                      loading={uploadLoading}
                      action={vinApi.ocrPath}
                      name="img"
                      beforeUpload={beforeUpload}
                      start={start}
                      success={success}
                      error={error}
                    >
                      <div className={css.upload}>
                        <span className="iconfont icontop"></span>
                        <p>{intl.formatMessage({id: 'dragFile'})}</p>
                        <button>{intl.formatMessage({id: 'selectImage'})}</button>
                      </div>
                    </Upload>
                  )
                }
              </div>
            ): null
          }
          
        </div>
      </div>
      {/* <div className={css['hot']}>
        <Tooltip visible={tipsVisible} overlayClassName="red" mouseLeaveDelay={0} placement="left" overlay={(<div className='tips'><p>Search History</p><button onClick={()=>hideTipsVisible()}>Understood</button></div>)} overlayStyle={{width: 116}} >
          <div className={css['hotHead']}>
            <ul>
              {
                hot.map((item)=>{
                  return (
                    <li key={item.id} onClick={()=>setHotType(item.id)} className={item.id === hotType ? css['active'] : ''}>{item.name}</li>
                  )
                })
              }
            </ul>
            <span>*example {hotType} Sample VIN</span>
          </div>
        </Tooltip>
        <Loading visible={hotLoading}>
          <div className={css['hotBody']}>
            <ul>
              {
                hotData.map((item: any, index: number)=>{
                  return (
                    <li key={index} onClick={()=>search(item.vin || item.pid)}>{item.pid || item.vin}</li>
                  )
                })
              }
            </ul>
          </div>
        </Loading>
      </div> */}
      {
        !userInfo.userid ? (
          <div className={css.freeRegister}>Register or Log in to search VIN for FREE!  </div>
        ): null
      }
      {
        !userInfo.userid ? (
          <div className={css.recommendedVin}>
            <h6>{intl.formatMessage({id: 'howWorks'})}</h6>
            <ul>
              {
                recommend.map((item: any, index: number)=>{
                  return (
                    item.key !== 'catalog' ? (
                      item.content.map((con: any, i: number)=>{
                        return (
                          item.key === 'brand' ? (
                            <Link to={`/catalog?brandcode=${con.brandCode || con.brandcode}`} key={i}>
                              <li key={i}>
                                <span><img src={con.img} /></span>
                              </li>
                            </Link>
                          ): (
                            <li key={i} onClick={()=>search(con.vin || con.pid)}>
                              {con.pid || con.vin || ''}
                            </li>
                          )
                          
                          
                        )
                      })
                    ): null
                  )
                })
                
              }
            </ul>
          </div>
        ): null
      }
      
      
      {
        recommend.map((item: any, index: number)=>{
          return (
            item.key === 'catalog' ? (
              <div className={css.recommendedBrand} key={index}>
                <div className={css.t}>
                  <h6>{item.title}</h6>
                  {
                    item.key === 'catalog' ? <em><Link to="/catalog">{intl.formatMessage({id: 'more'})}</Link></em> : null
                  }
                  
                </div>
                <ul>
                  {
                    item.content.map((con: any, i: number)=>{
                      const param = {
                        where: 'home',
                        what: {
                          type: 'brand',
                          value: con.brandCode || con.brandcode,
                          to: `/catalog?brandcode=${con.brandCode || con.brandcode}`
                        }
                      }
                      return (
                        <li onClick={()=>record(param)} key={i}>
                          <span>
                            <img src={con.img} />
                            {
                              con.is_hot ? <img src={icon_new} className={css.new} /> : null
                              
                            }
                          </span>
                          {/* <p>{con.brand || con.brandname}</p> */}
                        </li>
                      )
                    })
                  }
                  
                </ul>
              </div>
            ): null
          )
        })
      }

      {/* <div className={css.videoImg}>
        <h6>{intl.formatMessage({id: 'helpSection'})}</h6>
        <div className={css.c}>
          <div><img src={video_1} /><span onClick={()=>{setVedioIndex(0); setVedioVisible(true)}}><em><i className="iconfont iconxiayifenzu" /></em></span><p><em></em> PartsBond PRO Setup</p></div>
          <div><img src={video_2} /><span onClick={()=>{setVedioIndex(1); setVedioVisible(true)}}><em><i className="iconfont iconxiayifenzu" /></em></span><p><em></em> PartsBond VIN Setup</p></div>
          <div><img src={video_3} /><span onClick={()=>{setVedioIndex(2); setVedioVisible(true)}}><em><i className="iconfont iconxiayifenzu" /></em></span><p><em></em> Basic Tutorial</p></div>
        </div>
      </div> */}

      <div className={css['footer']}>
        <Footer />
      </div>
      
      {/* {
        !guidecache.home ? <Guide type='home' /> : null
      } */}
      {
        userInfo.data?.active ? (
          <Modal
            title=''
            visible={visible}
            cancel={()=>setVisible(false)}
            width={370}
            className='modalTrial'
          >
            <div className={css.trial} onClick={()=>getTrial()}>{ trialLoading ? <span className={css.trialLoading}><Loading visible={true} type='mini' color='#fff' /></span> : ''}{intl.formatMessage({id: 'freeTrialDay'})}</div>
          </Modal>
        ): null
      }
      <HelpVideo visible={vedioVisible} setVisible={setVedioVisible} activeIndex={vedioIndex} setActiveIndex={setVedioIndex} />
      
      <Modal
        title={selectConfigData.config?.desc || intl.formatMessage({id: 'selectConfigurationInformation'})}
        visible={selectConfigVisible}
        cancel={setSelectConfigVisible}
      >
        <div className={css['select-config']}>
          <div className={css['select-brand']}>
            <span>{selectConfigData.config?.res_desc}</span>
            <img src={cdnsPath + selectConfigData.config?.img} />
          </div>
          <table>
            <thead>
              <tr>
                {
                  selectConfigData.config?.title && selectConfigData.config.title.map((item: string)=>{
                    return (
                      <th>{item}</th>
                    )
                  })
                }
              </tr>
            </thead>
            <tbody>
              {
                selectConfigData.config?.data && selectConfigData.config.data.map((item: any)=>{
                  return (
                    <tr onClick={()=>search(item.contentData[0])}>
                      {
                        item.contentData.map((content: string)=>{
                          return (
                            <td>{content}</td>
                          )
                        })
                      }
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </Modal>

    </div>
  );
}

export default Home;
