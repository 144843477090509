const zh = {
  locale: 'zh-CN',
  messages: {
    "inputVinBrand": "يرجى إدخال رقم الإطار / الجزء رقم / العلامة التجارية",
    "history": "سجل تاريخي",
    "home": "الصفحة الرئيسية",
    "catalog": "نموذج الاستعلام",
    "search": "بحث .",
    "dragFile": "لصق أو سحب الملفات في هذا المجال لتحديد",
    "selectImage": "اختيار الصورة",
    "howWorks": "فهم كيف يعمل",
    "more": "أكثر .",
    "helpSection": "ساعد",
    "freeTrialDay": "محاكمة مجانية لمدة 3 أيام",
    "account": "الحسابات",
    "purchase": "شراء",
    "message": "رسالة",
    "favorites": "جمع",
    "feedbackMessage": "معلومات التغذية المرتدة",
    "watch": "قائمة المفضلة",
    "coupon": "كوبونات",
    "signOut": "تسجيل الخروج",
    "register": "التسجيل",
    "subscribe": "اشترك",
    "freeSearch": "بحث مجاني",
    "inviteTips": "اضغط على زر دعوة 10 أصدقاء للحصول على بطاقة سنوية مجانية",
    "invite": "دعوة",
    "freeTrialTips": "الاستمرار في استخدام partsbond ، تبدأ محاكمة حرة",
    "freeTrial": "محاكمة مجانية",
    "helpVideos": "مساعدة الفيديو",
    "copyright": "Copyright@2020 Autobond Inc . جميع الحقوق محفوظة",
    "api": "المعهد",
    "app": "التطبيق .",
    "about": "عنا",
    "contact": "اتصل بنا",
    "and": "و .",
    "privacy": "شروط الخصوصية",
    "feedback": "تغذية مرتدة",
    "writeOpinion": "اكتب رأيك",
    "sentUsEmail": "عنوان البريد الإلكتروني : aaronw@autobondinc.com",
    "sentUs": "اتصل بنا",
    "iosDownload": "دائرة الرقابة الداخلية تحميل",
    "androidDownload": "اندرويد داونلود",
    "inputPNMR": "الرجاء إدخال رقم الجزء / اسم / نموذج / ملاحظة",
    "view3D": "انقر فوق عرض نموذج ثلاثي الأبعاد",
    "again": "مرة أخرى",
    "pictures": "صور",
    "partsNumber": "رقم الجزء",
    "partsName": "اسم الجزء",
    "priceRange": "نطاق السعر",
    "operation": "العمليات",
    "email": "صندوق البريد",
    "vCode": "رمز التحقق",
    "nPassword": "كلمة السر الجديدة",
    "cNPassword": "تحديد كلمة السر الجديدة",
    "submit": "قدم",
    "getCode": "الحصول على رمز التحقق",
    "countdown": "{num}s",
    "inputEmail": "الرجاء إدخال البريد الإلكتروني الخاص بك",
    "emailCorrectly": "يرجى ملء البريد الإلكتروني بشكل صحيح",
    "inputPassword": "الرجاء إدخال كلمة السر",
    "passwordsInconsistent": "اثنين من كلمات السر التي أدخلتها تتعارض",
    "sendCodeSuccess": "إرسال بنجاح",
    "agreeUserAgreement": "يرجى الموافقة على اتفاق المستخدم",
    "tryFree": "محاكمة حرة أجزاء",
    "tryFreeDay": "بعد تسجيل الدخول ، انقر فوق محاكمة مجانية للحصول على محاكمة 7 أيام",
    "registrationCodeSentSuccess": "تم إرسال رمز التسجيل بنجاح ! إذا كنت لا تحصل على رمز في 1-2 دقيقة ، حاول تحديث صندوق البريد الوارد الخاص بك والتحقق من مجلد البريد المزعج .",
    "findUs": "كيف تجد لنا",
    "iAgree": "أوافق",
    "userAgreement": "بروتوكول المستخدم",
    "brand": "الماركات",
    "sampleSearch": "عينة البحث",
    "passwordChangedSuccess": "تغيير كلمة المرور بنجاح",
    "passwordChangedFailed": "فشل تعديل كلمة المرور",
    "enterNewName": "الرجاء إدخال اسم مستخدم جديد",
    "userChangedSuccess": "تعديل المستخدم بنجاح",
    "userChangedFailed": "فشل تعديل معلومات المستخدم",
    "fillName": "يرجى ملء اسم المستخدم الأول",
    "vinBindingSuccee": "الإطار رقم ربط بنجاح",
    "success": "النجاح .",
    "brandPackage": "السيارات العلامة التجارية حزمة الاستعلام",
    "combo": "كومبو",
    "validTime": "الوقت الفعلي",
    "cancelRenewal": "إلغاء التحديث",
    "packageContent": "حزمة",
    "bindFrame": "ربط عدد آخر",
    "remainingQueries": "عدد الاستفسارات المتبقية",
    "personalInformation": "معلومات شخصية",
    "loginAccount": "حساب تسجيل الدخول",
    "nickName": "الاسم المستعار",
    "modify": "عدل",
    "bindingEmail": "ربط صندوق البريد",
    "loginPassword": "كلمة السر",
    "enterContent": "إدخال المحتوى",
    "noCoupons": "لا كوبونات",
    "discountOperation": "عملية الخصم",
    "preferentialContent": "محتوى متميز",
    "serviceLife": "خدمة الحياة",
    "user": "اشترك",
    "remarks": "ملاحظات",
    "noPartsCollection": "لا أجزاء",
    "selectAll": "اختيار جميع",
    "delete": "حذف .",
    "vin": "رقم الإطار",
    "oe": "عمر الفاروق",
    "detail": "تفاصيل",
    "noNews": "لا رسائل",
    "feedbackDetails": "معلومات التغذية المرتدة",
    "feedbackStatus": "الدولة المرتدة",
    "feedbackTime": "وقت التغذية المرتدة",
    "questionType": "نوع المشكلة",
    "problemDescription": "وصف المشكلة",
    "solved": "حل",
    "pending": "إنتظر",
    "supplementaryQuestion": "أسئلة تكميلية",
    "endFeedback": "نهاية ردود الفعل",
    "cancel": "ألغى",
    "invalidCoupon": "لم يتم العثور على رمز الخصم",
    "bindVin": "ملزمة { رقم } الإطار",
    "annualSubscribe": "الاشتراك السنوي",
    "currencySymbol": "ر",
    "enterNumber": "الرجاء إدخال رقم واحد بدوره",
    "boundRemaining": "{ hasbound } / { remainingbound } الإطار عدد ملزم [ has } / { remaining } الإطار المتبقي عدد ملزم ]",
    "boundDescribe1": "اشتراكات متعددة يمكن تطبيقها على حساب واحد",
    "boundDescribe2": "الاشتراك ساري المفعول لمدة سنة تقويمية واحدة ( من تاريخ الشراء )",
    "boundDescribe3": "ربط المستخدمين المسجلين والمستخدمين المسجلين هو دائم . إذا كنت ترغب في ربط الخاص بك فين ( ق ) في وقت لاحق ، يمكنك القيام بذلك في تفاصيل الحساب الخاص بك",
    "allInOne": "كل - ان - ون",
    "asiaPack": "اسيا باك",
    "euroPack": "يورو باك",
    "usPack": "اس باك",
    "daySubscription": "الاشتراك في اليوم",
    "monthSubscription": "الاشتراك الشهري",
    "annualSubscription": "الاشتراك السنوي",
    "use": "إستعمال",
    "howGetCode": "كيفية الحصول على رمز الخصم",
    "payPalWith": "باي بال مع",
    "openingPermission": "إذن فتح",
    "availablePackages": "مجموعة المتاحة",
    "bestMatch": "أفضل مباراة",
    "priceLowHigh": "الأسعار من منخفضة إلى عالية",
    "priceHighLow": "الأسعار من أعلى إلى أسفل",
    "pleaseLoginFirst": "يرجى تسجيل الدخول أولا",
    "here3DArea": "هذه هي منطقة 3D",
    "applicableVehicles": "نماذج مناسبة",
    "applicableVehicle": "مناسبة للسيارات",
    "interchangeableParts": "استبدال قطعة",
    "condition": "شرط .",
    "all": "كامل",
    "channel": "قناة",
    "location": "موقع",
    "partsFound": "أجزاء",
    "noData": "لا توجد بيانات",
    "carsModels": "نموذج ( العمر )",
    "year": "سنة",
    "market": "السوق .",
    "oeNumber": "عمر الفاروق",
    "partName": "اسم الجزء",
    "count": "عدد",
    "partsPrice": "جزء من السعر",
    "directReplacement": "استبدال مباشر",
    "indirectReplacement": "بديل غير مباشر",
    "priceTrend": "اتجاه الأسعار",
    "partPicture": "أجزاء الصورة",
    "brands": "الماركات",
    "replacement": "استبدال",
    "applicableVeh": "فيح",
    "supplier": "الموردون",
    "getIt": "الحصول على",
    "go": "جو",
    "findResults": "تجد لك نتائج عن { عدد }",
    "partOe": "عمر الفاروق",
    "mainGroup": "المجموعة الرئيسية",
    "subGroup": "تقسيم الرئيسية",
    "group": "مجموعة",
    "configDetails": "تفاصيل التكوين",
    "selectGroup": "اختيار الرب",
    "conflg": "تكوين",
    "picture": "صور",
    "mg": "مغ",
    "sg": "اس جي",
    "illNo": "إيل نو",
    "name": "الإسم",
    "remark": "ملاحظات",
    "model": "نوع السيارة",
    "selectConfigurationInformation": "اختيار معلومات التكوين",
    "groupOne": "المجموعة الأولى !",
    "lastGroup": "المجموعة الأخيرة !",
    "list": "قائمة",
    "back": "رد : .",
    "switchGroup": "التبديل",
    "notFilter": "لا تصفية",
    "filter": "فلاتر",
    "config": "تكوين",
    "pos": "البوس",
    "qty": "كيوتي",
    "addWatch": "إضافة قائمة المفضلة",
    "brandPart": "العلامة التجارية أجزاء",
    "component": "مكون",
    "disclaimer": "البيان : نتائج الاستعلام هي للرجوع اليها فقط .",
    "partDetail": "تفاصيل الجزء",
    "vinNotAvailable": "رقم هوية السيارة غير متوفرة",
    "partNotAvailable": "رقم الجزء غير متوفر",
    "lackModels": "اي نموذج",
    "dataNotComplete": "بيانات ناقصة",
    "dataError": "خطأ في البيانات",
    "dataNotNew": "البيانات ليست جديدة بما فيه الكفاية",
    "slowSpeed": "سرعة بطيئة",
    "noUniqueness": "ليس فقط",
    "other": "أخرى",
    "selectQuestion": "اختيار المشكلة",
    "close": "غلق",
    "howVin": "كيفية عرض الرف رقم ؟",
    "howVin1": "العثور على الإطار رقم تسجيل رقم تعريف السيارة على زجاج النافذة الأمامية من جسم السيارة",
    "howVin2": "أدخل رقم الإطار أو انقر فوق تحميل الصورة ، حدد رقم الإطار",
    "howVin3": "انقر على أيقونة الاستعلام الاستعلام مربع عدد",
    "howVin4": "أدخل اسم العلامة التجارية ، انقر فوق رمز الاستعلام الاستعلام",
    "findParts": "كيفية العثور على أجزاء ؟",
    "findParts1": "تحوم الماوس على موقف للسيارات ، عرض معلومات التكوين الأساسية",
    "findParts2": "انقر على هذا الرمز للاستفسار عن أجزاء الجسم ، أجزاء الهيكل ، أجزاء المحرك باستخدام نموذج ثلاثي الأبعاد",
    "findParts3": "حدد المجموعة الرئيسية والمجموعة في قائمة أجزاء",
    "findParts4": "انقر فوق إعادة اختيار المجموعة الرئيسية و رقم المربع الاستعلام",
    "findParts5": "انقر فوق رقم الجزء من عرض التحلل لتحديد جزء",
    "howPart": "كيفية استخدام الجزء رقم الاستعلام ؟",
    "howPart1": "أدخل أو تحميل صورة الجزء رقم الهوية ، انقر فوق البحث الاستعلام",
    "howPart2": "انقر على أيقونة لجمع القطع في مجموعتي ، وسوف تتلقى إخطارا بالبريد الإلكتروني عند سعر القطعة التغييرات .",
    "howPart3": "انقر على أيقونة لعرض أحدث اتجاهات الأسعار لهذا الجزء",
    "noHelp": "لا تحتاج إلى مساعدة",
    "understood": "فهمت",
    "nextStep": "الخطوة التالية",
    "clickCheckInvitation": "انقر هنا لعرض حالة الدعوة",
    "inviteFriendsGetPackage": "دعوة 10 أصدقاء للحصول على حزمة سنوية بقيمة 540 دولار !",
    "emailAddress": "عنوان البريد الإلكتروني",
    "status": "مركز",
    "redeem": "قابل للتحويل",
    "inviteRewardTips": "< ف > يرجى التأكد من أن التطبيق الخاص بك للحصول على جميع في سنة واحدة مكافأة > < / م > < / ف > إذا كان لديك حزمة صالحة حاليا ، سيتم تحويل المكافأة تلقائيا عند انتهاء الصلاحية",
    "currentSubscriptionExpires": "تاريخ انتهاء الصلاحية الحالية الخاصة بك مجموعة",
    "confirm": "أكّد",
    "putAway": "وضع بعيدا",
    "seeAll": "سي ال",
    "pageCannotEmpty": "عدد الصفحات لا يمكن أن تكون فارغة",
    "pleaseRegisterSignin": "الرجاء تسجيل الدخول / البحث عن مزيد من بيانات السيارة",
    "price": "الأسعار",
    "quantity": "الكمية",
    "pleaseRL": "الرجاء تسجيل الدخول",
    "sign": "سجل",
    "stay": "الحفاظ على تسجيل الدخول",
    "forget": "نسيت كلمة السر",
    "password": "كلمة السر",
    "RFFVS": "تسجيل مجاني البحث فين",
    "cName": "اسم الشركة",
    "basic": "معلومات أساسية",
    "updateTime": "وقت التحديث",
    'OfficialStore': 'المتجر الرسمي',
    'english': 'English',
    'russian': 'русск',
    'indonesian': 'Indonesia',
    'vietnamese': 'Tiếng Việt',
    'thai': 'คนไทย',
    'malaysia': 'Malaysia',
    'arabia': 'بالعربية',
    'spain': 'Espagne',
    'portugal': 'Portugal',
    'france': 'France',
    'Turkey': 'Türkçe',
    'korean': '한국어',
    'japanese': '日本語'
  }
}

export default zh